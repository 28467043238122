export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const ROLE_NAME_REGEX = /^[A-Za-z0-9 ,]+$/;
export const TEAM_NAME_REGEX = /^[A-Za-z0-9 ,]+$/;
export const ROLE_DISC_REGEX = /^[\nA-Za-z0-9 .,_-]+$/;
export const ROLE_SPEC_REGEX = /^[\nA-Za-z0-9 ?'().,_-]+$/;
export const STEPS_DISC_REGEX = /^[A-Za-z0-9 \n.,_(-]+$/;
export const WHITE_SPACE_REGEX = /^\s*\S.*$/;
export const NO_NUMBER_REGEX = /^[^\d]+$/;
export const MAX_SELECT_LABEL_LEGTH = 3;
export const FIRST_LAST_NAME_REGEX = /^[A-Za-z ]+$/;
export const STEP_DAYS_REGEX = /^[0-9]+$/;
export const DOC_CHECKLIST_ITEM_NAME_REGEX = /^[A-Za-z0-9 ,_-]+$/;
export const NUMBER_WITH_7_9_DIGITS_REGEX = /^\d{7,9}$/;
export const DOCUMENT_NAME_REGEX = /^[A-Za-z0-9\-_& ]*$/;
export const NUMBER_WITH_0_9_DIGITS_REGEX = /^[0-9+()-\s]+$/;
export const UNDERSCORE_NOT_ALLOWED_REGEX = /^[^_]*$/;
export const LEADING_TRAILING_SPACES_NOT_ALLOWED_REGEX = /^(?!\s)(.*\S$|$)/;

export const IS_MULTI_SELECT_FILTER = true;

export const IO_IOS_SEARCH_SIZE = 21;

export const USER_MAX_LENGTH = 25;

export const USER_ACCESS_MIN = 1;

export const LIST_CRR_PAGE = 1;

export const REACT_TABLE_ORDER = "DSC";

export const DEF_SORT_ORDER = "DESC";

export const ROLE_DEF_COLUMN = "created_at";

export const STEPS_DEF_COLUMN = "created_at";

export const USER_DEF_COLUMN = "created_at";

export const USER_LIST_ROWS_DEF = 10;

export const LIST_ROWS_100 = 100;

export const ROLE_LIST_ROWS_DEF = 10;

export const TEAMS_LIST_ROWS_DEF = 10;

export const TEAMS_DEFAULT_SORT_COLUMN = "created_at";

export const STEPS_LIST_ROWS_DEF = 10;

export const STEPS_DEFAULT_SORT_COLUMN = "created_at";

export const STAGES_LIST_ROWS_DEF = 10;

export const STAGES_DEFAULT_SORT_COLUMN = "created_at";

export const CUSTOM_PAGINATOR_VALUES = [10, 20, 30];

export const PAGINATOR_ROWS_DEF = 10;

export const PAGINATOR_FIRST = 0;

export const DEF_INC_COUNT = 1;

export const EDIT = "edit";

export const VIEW = "view";

export const ADD = "add";

export const VIEWALL = "view-all";

export const MAPPING = "mapping";

export const FORMS = "forms";

export const QUESTIONNAIRES = "questionnaires";

export const POP_UP_CLOSE_1000 = 2000;

export const POP_UP_CLOSE_2000 = 2000;

export const POP_UP_CLOSE_10000 = 10000;

export const VALID_ORGANIZATION_EMAIL = "The email id must be a valid organization email";

export const DELETE_HEADER_TITLE = "Delete Role";

export const DELETE_ROLE_CONTENT = "Are you sure, you want to delete this role?";
export const DELETE_DESIGNATION_MANAGER_TITLE = "Delete Designation";

export const DELETE_DESIGNATION_MANAGER_CONTENT =
  "Are you sure you want to delete this designation, after deleting this designation it’ll not going to populate in the dropdowns on user selection.";

export const DELETE_STAGE_TITLE = "Delete Stage";

export const DELETE_STAGE_CONTENT =
  "Are you sure, you want to delete the Stage?";

export const DELETE_HEADER_TITLE_STEP = "Delete Step";

export const DELETE_STEP_CONTENT = "Are you sure, you want to delete the Step?";

export const HIDE_TABLE_PAGINATOR = true;

export const REORDERABLE_ROWS = true;

export const IS_SELECT_FILTER = true;

export const DELETE_DOC_CHECKLIST_ITEM_TITLE = "Delete Document Checklist Item";

export const DELETE_DOC_CHECKLIST_ITEM_CONTENT =
  "Are you sure, you want to delete the Document Checklist Item?";

export const DELETE_DOC_TYPE_ITEM_TITLE = "Delete Document Type";

export const DELETE_DOC_TYPE_ITEM_CONTENT =
  "Are you sure, you want to delete the Document Type?";

export const DELETE_RECEIPT_TITLE = "Delete Receipt";

export const DELETE_CHILD_RECEIPT_TITLE = "Delete Receipt";

export const DELETE_RECEIPT_CONTENT =
  "Are you sure, you want to delete All the attached receipts? To remove a single receipt, please click on the eye icon.";

export const DELETE_CHILD_RECEIPT_CONTENT =
  "Are you sure, you want to delete receipt?";

export const DELETE_BENEFICIARY_TITLE = "Delete Beneficiary";

export const DELETE_BENEFICIARY_CONTENT =
  "Are you sure, you want to delete the Beneficiary?";

export const DELETE_DERIVATIVE_TITLE = "Delete Derivative/Co-applicant";

export const DELETE_DERIVATIVE_CONTENT =
  "Are you sure, you want to remove this derivative/co-applicant from this case?";

export const DELETE_PETITIONER_TITLE = "Delete Petitioner/Sponsor";

export const DELETE_PETITIONER_CONTENT =
  "Are you sure, you want to remove this petitioner/sponsor?";

export const MAX_TEN_FILES_ALLOWED = "Maximum ten files are allowed at a time.";
export const MAX_FIFTY_FILES_ALLOWED_COUNT = 50;
export const MAX_FIFTY_FILES_ALLOWED = "Maximum fifty files are allowed at a time.";

export const DOCUMENTS_MAX_FILES_SIZE_ALLOWED = 250;

export const DOCUMENTS_MAX_ALLOWED_FILES_SIZE_250MB_ALLOWED =
`It seems that one of your file exceed maximum size of ${DOCUMENTS_MAX_FILES_SIZE_ALLOWED} MB.`;


export const MAX_ONE_FILE_ALLOWED = "Maximum one file is allowed.";

export const MAX_ALLOWED_FILES_SIZE_ALLOWED =
  "It seems that one of your file exceed maximum size of 20 MB.";

export const MAX_ALLOWED_FILES_SIZE_35MB_ALLOWED =
`It seems that one of your file exceed maximum size of ${process.env.REACT_APP_REPLY_REPLY_ALL_FILE_SIZE} MB.`;

export const DELETE_DOC_CHECKLIST_TITLE = "Delete Document Checklist";

export const DELETE_DOC_CHECKLIST_CONTENT =
  "Are you sure, you want to delete the Document Checklist?";
export const DELETE_QUESTIONS_TITLE = "Delete Question";

export const DELETE_QUESTIONS_CONTENT =
  "Are you sure you want to delete the Question?";

export const DATE_RANGE_VALUE =
  "Please select start date and end date in the date range value.";
export const DATE_RANGE_VALUE_TWO =
  "Please select end date in the date range value.";

export const DELETE_FORM_MANAGER_TITLE = "Delete Form";

export const DELETE_FORM_MANAGER_CONTENT =
  "Are you sure, you want to delete the form?";
export const DELETE_NOTE_TITLE = "Delete Note";

export const DELETE_NOTE_CONTENT = "Are you sure, you want to delete the Note permanently?";

export const DELETE_DOCUMENT_TITLE = "Delete Document";
export const DELETE_DOCUMENT_CONTENT =
  "Are you sure, you want to delete the Document?";

export const DELETE_FORM_TITLE = "Delete Form";
export const DELETE_FORM_CONTENT =
  "Are you sure, you want to delete the Form?";


export const DELETE_EMAIL_TEMPLATE_TITLE = "Delete Email Template"
export const DELETE_EMAIL_TEMPLATE_CONTENT =
  "Are you sure you want to delete this Email Template?"


export const DELETE_DRAFTS_TITLE = "Delete Draft";
export const DELETE_DRAFTS_CONTENT =
  "Are you sure, you want to delete the draft permanently?";

export const DELETE_ALERT_TITLE = "Delete Alert";
export const DELETE_ALERT_CONTENT =
  "Are you sure, you want to permanently delete the alert?";

export const DELETE_QUESTIONNAIRE_TITLE = "Delete Questionnaire";
export const DELETE_QUESTIONNAIRE_CONTENT =
  "Are you sure, you want to delete the Questionnaire?";

export const DELETE_REMINDER_TITLE = "Delete Reminder";
export const DELETE_REMINDER_CONTENT =
  "Are you sure, you want to delete the Reminder?";

export const DELETE_EVENT_TITLE = "Delete Event";
export const DELETE_EVENT_CONTENT =
  "Are you sure, you want to delete the Event?";

export const DELETE_NOTIFICATION_TITLE = "Delete Notification";
export const DELETE_NOTIFICATION_CONTENT =
  "Are you sure, you want to delete the notification from the DB permanently?";

export const DELETE_REPORT_TITLE = "Delete Report";
export const DELETE_REPORT_CONTENT =
  "Are you sure, you want to delete the Report?";

export const DELETE_RELATIVES_TITLE = "Delete Relatives";
export const DELETE_RELATIVES_CONTENT =
  "Are you sure, you want to delete the Relative?";

export const DELETE_ATTACHMENT_TITLE = "Delete Attachment";
export const DELETE_ATTACHMENT_CONTENT =
  "Are you sure, you want to delete the Attachment?";

export const DEactive_ALERT_TITLE = "Deactivate Alert";
export const DEactive_ALERT_CONTENT =
  "Are you sure, you want to deactivate the alert?";
export const REactive_ALERT_TITLE = "Reactivate Alert";
export const REactive_ALERT_CONTENT =
  "Are you sure, you want to reactivate the alert?";

export const DELETE_FILLING_TITLE = "Delete Filing";
export const DELETE_FILLING_CONTENT =
  "Are you sure, you want to delete this filing?";

export const SEND_DOC_CHK_LIST = "Send Document Checklist";
export const SEND_DOC_CHK_LIST_CONTENT =
  "Are you sure, you want to send the document checklist?";

export const ADD_DOC_CHK_LIST = "Add Related Documents";
export const ADD_DOC_CHK_LIST_CONTENT =
  "Are you sure, you want to add the related documents?";

// GRAPH API
export const IMAGE_NOT_UPLOADED =
  "Profile image not uploaded, Sharepoint issue";
export const ALLOWEDIMAGETYPES =
  "Allowed image file extensions are .jpg, .jpeg and .png.";
export const IMAGESIZE = "Image size must not exceed 2.5 MB.";
export const IMAGESIZEINKB = 2500;
export const ALLOWEDFILETYPES = "Allowed file types are";
export const ALLOWEDFILETYPEPDF = "Only PDF files are allowed for forms.";
export const FILE_NOT_UPLOADED =
  "File not uploaded on sharepoint. Please try again.";
export const FileSIZEINKB = 50000;
export const FILESIZE = "File size must not exceed 50 MB.";
export const PRIMARYCONATCT = "Please select atleast one primary contact."
export const LOGIN_OTHER_BROWSER = "It seems you're logged into other browser tab or window.";
export const USER_NOT_EXIST = "Issue in login please ask your admin to check your account is created or not.";
// User Management Add User Designation Options
export const FILL_FORM_TITLE = "Fill Form with Questionnaire";
export const FILL_FORM__QUESTIONNAIRE_CONTENT =
  "Are you sure, you want to fill form with the associated Questionnaire?";
export const DESIGNATIONS_OPTIONS = [
  { label: "Senior Attorney", value: "Senior Attorney" },
  { label: "Attorneys", value: "Attorneys" },
  { label: "Team Leads", value: "Team Leads" },
  {
    label: "Business Immigration Analyst",
    value: "Business Immigration Analyst",
  },
  { label: "Immigrant Visa Specialists", value: "Immigrant Visa Specialists" },
  { label: "RFE Case Manager", value: "RFE Case Manager" },
  { label: "RFE Attorney", value: "RFE Attorney" },
];

// User Management Add User Department Options
export const DEPARTMENT_OPTIONS = [
  { label: "Human Resources", value: "Human Resources" },
  { label: "Billing", value: "Billing" },
  { label: "Onboarding", value: "Onboarding" },
  { label: "Offboarding", value: "Offboarding" },
  { label: "Administrative", value: "Administrative" },
  { label: "Legal", value: "Legal" },
  { label: "Management", value: "Management" },
  { label: "Business and IT", value: "Business and IT" },
  { label: "Client Liason", value: "Client Liason" }
];

export const ALL = "all";

export const SUCCESS_STATUS = 200;
export const SUCCESS_STATUS_ERROR = 201;

// Role Options
export const roleOptions = [
  { label: "Role 1", value: "Role 1" },
  { label: "Role 2", value: "Role 2" },
  { label: "Role 3", value: "Role 3" },
  { label: "Role 4", value: "Role 4" },
];

// Member Name Options
export const memberNameOptions = [
  { label: "Member Name 1", value: "Member Name 1" },
  { label: "Member Name 2", value: "Member Name 2" },
  { label: "Member Name 3", value: "Member Name 3" },
  { label: "Member Name 4", value: "Member Name 4" },
];

export const sharePointImgUrl =
  "https://matellio068.sharepoint.com/Shared%20Documents/formDataName/Screenshot%20from%202023-09-22%2018-36-37.png";

export const ROLE = "role";

export const ATTORNEYS = "Attorneys";
export const BI_ANALYST = "Business Immigration Analyst";
export const TEAM_LEADS = "Team Leads";
export const SENIOR_ATTORNEY = "Senior Attorney";
export const IMM_VISA_SPECIALISTS = "Immigrant Visa Specialists";
export const RFE_CASE_MANAGER = "RFE Case Manager";
export const RFE_ATTORNEY = "RFE Attorney";

export const foldersNameKnowledgeBaseOptions = [
  { label: "Cover Pages For Filing", value: "Cover Pages For Filing" },
  { label: "Contract Templates", value: "Contract Templates" },
  { label: "Custom Fields", value: "Custom Fields" },
  { label: "Document Checklist", value: "Document Checklist" },
  { label: "Document Templates", value: "Document Templates" },
  { label: "Email Templates", value: "Email Templates" },
  { label: "Forms", value: "Forms" },
  { label: "Letter Templates", value: "Letter Templates" },
  { label: "Questionnaires", value: "Questionnaires" },
  { label: "Refer Keyword", value: "Refer Keyword" },
  { label: "Shipping Information", value: "Shipping Information" },
  { label: "Steps and Reminders", value: "Steps and Reminders" },
];

// Salutation Options
export const salutationOptions = [
  { label: "Dr.", value: "Dr." },
  { label: "Hon.", value: "Hon." },
  { label: "Lic.", value: "Lic." },
  { label: "Miss.", value: "Miss." },
  { label: "Mr.", value: "Mr." },
  { label: "Mrs.", value: "Mrs." },
  { label: "Ms.", value: "Ms." },
  { label: "Sir.", value: "Sir." },
];

// Main Contact Options
export const mainContactOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

// Gender Options
export const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Other", value: "Other" },
];

// Gender Options
export const allowedFileTypesOptions = [
  { label: "JPG", value: "JPG" },
  { label: "JPEG", value: "JPEG" },
  { label: "PNG", value: "PNG" },
  { label: "GIF", value: "GIF" },
  { label: "PDF", value: "PDF" },
  { label: "DOC", value: "DOC" },
  { label: "DOCX", value: "DOCX" },
  { label: "PPTX", value: "PPTX" },
  { label: "XLSX", value: "XLSX" },
  { label: "XLS", value: "XLS" },
  { label: "TXT", value: "TXT" },
];

export const DOB_FORMAT = "MM-DD-YYYY";
export const DATE_FORMAT = "MM/DD/YYYY";
export const DATE_FORMAT_EMAIL_REPLY = "MM/DD/YYYY HH:mm:ss"
export const DATE_FORMAT_TIME_ZONE = "MM-DD-YYYY HH:mm:ss.SSSZ"

export const CONTACT_INFO_SCREEN = "Contact Info";

export const COUNTRY_OPTIONS = [
  {
    "value": "Afghanistan",
    "label": "Afghanistan"
  },
  {
    "label": "Albania",
    "value": "Albania"
  },
  {
    "label": "Algeria",
    "value": "Algeria"
  },
  {
    "label": "Andorra",
    "value": "Andorra"
  },
  {
    "label": "Angola",
    "value": "Angola"
  },
  {
    "label": "Antigua and Barbuda",
    "value": "Antigua and Barbuda"
  },
  {
    "label": "Argentina",
    "value": "Argentina"
  },
  {
    "label": "Armenia",
    "value": "Armenia"
  },
  {
    "label": "Australia",
    "value": "Australia"
  },
  {
    "label": "Austria",
    "value": "Austria"
  },
  {
    "label": "Azerbaijan",
    "value": "Azerbaijan"
  },
  {
    "label": "Bahamas",
    "value": "Bahamas"
  },
  {
    "label": "Bahrain",
    "value": "Bahrain"
  },
  {
    "label": "Bangladesh",
    "value": "Bangladesh"
  },
  {
    "label": "Barbados",
    "value": "Barbados"
  },
  {
    "label": "Belarus",
    "value": "Belarus"
  },
  {
    "label": "Belgium",
    "value": "Belgium"
  },
  {
    "label": "Belize",
    "value": "Belize"
  },
  {
    "label": "Benin",
    "value": "Benin"
  },
  {
    "label": "Bhutan",
    "value": "Bhutan"
  },
  {
    "label": "Bolivia",
    "value": "Bolivia"
  },
  {
    "label": "Bosnia and Herzegov",
    "value": "Bosnia and Herzegov"
  },
  {
    "label": "Botswana",
    "value": "Botswana"
  },
  {
    "label": "Brazil",
    "value": "Brazil"
  },
  {
    "label": "Brunei Darussalam",
    "value": "Brunei Darussalam"
  },
  {
    "label": "Bulgaria",
    "value": "Bulgaria"
  },
  {
    "label": "Burkina Faso",
    "value": "Burkina Faso"
  },
  {
    "label": "Burundi",
    "value": "Burundi"
  },
  {
    "label": "Cambodia",
    "value": "Cambodia"
  },
  {
    "label": "Cameroon",
    "value": "Cameroon"
  },
  {
    "label": "Canada",
    "value": "Canada"
  },
  {
    "label": "Cape Verde",
    "value": "Cape Verde"
  },
  {
    "label": "Central African Republic",
    "value": "Central African Republic"
  },
  {
    "label": "Chad",
    "value": "Chad"
  },
  {
    "label": "Chile",
    "value": "Chile"
  },
  {
    "label": "China",
    "value": "China"
  },
  {
    "label": "Colombia",
    "value": "Colombia"
  },
  {
    "label": "Comoros",
    "value": "Comoros"
  },
  {
    "label": "Congo",
    "value": "Congo"
  },
  {
    "label": "Democratic Republic of Congo",
    "value": "Democratic Republic of Congo"
  },
  {
    "label": "Costa Rica",
    "value": "Costa Rica"
  },
  {
    "label": "Croatia",
    "value": "Croatia"
  },
  {
    "label": "Cuba",
    "value": "Cuba"
  },
  {
    "label": "Cyprus",
    "value": "Cyprus"
  },
  {
    "label": "Czech Republic",
    "value": "Czech Republic"
  },
  {
    "label": "Denmark",
    "value": "Denmark"
  },
  {
    "label": "Djibouti",
    "value": "Djibouti"
  },
  {
    "label": "Dominica",
    "value": "Dominica"
  },
  {
    "label": "Dominican Republic",
    "value": "Dominican Republic"
  },
  {
    "label": "Ecuador",
    "value": "Ecuador"
  },
  {
    "label": "Egypt",
    "value": "Egypt"
  },
  {
    "label": "El Salvador",
    "value": "El Salvador"
  },
  {
    "label": "Equatorial Guinea",
    "value": "Equatorial Guinea"
  },
  {
    "label": "Eritrea",
    "value": "Eritrea"
  },
  {
    "label": "Estonia",
    "value": "Estonia"
  },
  {
    "label": "swatini",
    "value": "swatini"
  },
  {
    "label": "Ethiopia",
    "value": "Ethiopia"
  },
  {
    "label": "Fiji",
    "value": "Fiji"
  },
  {
    "label": "Finland",
    "value": "Finland"
  },
  {
    "label": "France",
    "value": "France"
  },
  {
    "label": "Gabon",
    "value": "Gabon"
  },
  {
    "label": "Gambia",
    "value": "Gambia"
  },
  {
    "label": "Georgia",
    "value": "Georgia"
  },
  {
    "label": "Germany",
    "value": "Germany"
  },
  {
    "label": "Ghana",
    "value": "Ghana"
  },
  {
    "label": "Greece",
    "value": "Greece"
  },
  {
    "label": "Grenada",
    "value": "Grenada"
  },
  {
    "label": "Guatemala",
    "value": "Guatemala"
  },
  {
    "label": "Guinea",
    "value": "Guinea"
  },
  {
    "label": "Guinea-Bissau",
    "value": "Guinea-Bissau"
  },
  {
    "label": "Guyana",
    "value": "Guyana"
  },
  {
    "label": "Haiti",
    "value": "Haiti"
  },
  {
    "label": "Honduras",
    "value": "Honduras"
  },
  {
    "label": "Hungary",
    "value": "Hungary"
  },
  {
    "label": "Iceland",
    "value": "Iceland"
  },
  {
    "label": "India",
    "value": "India"
  },
  {
    "label": "Indonesia",
    "value": "Indonesia"
  },
  {
    "label": "Iran",
    "value": "Iran"
  },
  {
    "label": "Iraq",
    "value": "Iraq"
  },
  {
    "label": "Ireland",
    "value": "Ireland"
  },
  {
    "label": "Israel",
    "value": "Israel"
  },
  {
    "label": "Italy",
    "value": "Italy"
  },
  {
    "label": "Ivory Coast",
    "value": "Ivory Coast"
  },
  {
    "label": "Jamaica",
    "value": "Jamaica"
  },
  {
    "label": "Japan",
    "value": "Japan"
  },
  {
    "label": "Jordan",
    "value": "Jordan"
  },
  {
    "label": "Kazakhstan",
    "value": "Kazakhstan"
  },
  {
    "label": "Kenya",
    "value": "Kenya"
  },
  {
    "label": "Kiribati",
    "value": "Kiribati"
  },
  {
    "label": "Kuwait",
    "value": "Kuwait"
  },
  {
    "label": "Kyrgyzstan",
    "value": "Kyrgyzstan"
  },
  {
    "label": "Laos",
    "value": "Laos"
  },
  {
    "label": "Latvia",
    "value": "Latvia"
  },
  {
    "label": "Lebanon",
    "value": "Lebanon"
  },
  {
    "label": "Lesotho",
    "value": "Lesotho"
  },
  {
    "label": "Liberia",
    "value": "Liberia"
  },
  {
    "label": "Libya",
    "value": "Libya"
  },
  {
    "label": "Liechtenstein",
    "value": "Liechtenstein"
  },
  {
    "label": "Lithuania",
    "value": "Lithuania"
  },
  {
    "label": "Luxembourg",
    "value": "Luxembourg"
  },
  {
    "label": "Madagascar",
    "value": "Madagascar"
  },
  {
    "label": "Malawi",
    "value": "Malawi"
  },
  {
    "label": "Malaysia",
    "value": "Malaysia"
  },
  {
    "label": "Maldives",
    "value": "Maldives"
  },
  {
    "label": "Mali",
    "value": "Mali"
  },
  {
    "label": "Malta",
    "value": "Malta"
  },
  {
    "label": "Marshall Islands",
    "value": "Marshall Islands"
  },
  {
    "label": "Mauritania",
    "value": "Mauritania"
  },
  {
    "label": "Mauritius",
    "value": "Mauritius"
  },
  {
    "label": "Mexico",
    "value": "Mexico"
  },
  {
    "label": "Micronesia",
    "value": "Micronesia"
  },
  {
    "label": "Moldova",
    "value": "Moldova"
  },
  {
    "label": "Monaco",
    "value": "Monaco"
  },
  {
    "label": "Mongolia",
    "value": "Mongolia"
  },
  {
    "label": "Montenegro",
    "value": "Montenegro"
  },
  {
    "label": "Morocco",
    "value": "Morocco"
  },
  {
    "label": "Mozambique",
    "value": "Mozambique"
  },
  {
    "label": "Myanmar",
    "value": "Myanmar"
  },
  {
    "label": "Namibia",
    "value": "Namibia"
  },
  {
    "label": "Nauru",
    "value": "Nauru"
  },
  {
    "label": "Nepal",
    "value": "Nepal"
  },
  {
    "label": "Netherlands",
    "value": "Netherlands"
  },
  {
    "label": "New Zealand",
    "value": "New Zealand"
  },
  {
    "label": "Nicaragua",
    "value": "Nicaragua"
  },
  {
    "label": "Niger",
    "value": "Niger"
  },
  {
    "label": "Nigeria",
    "value": "Nigeria"
  },
  {
    "label": "North Korea",
    "value": "North Korea"
  },
  {
    "label": "North Macedonia",
    "value": "North Macedonia"
  },
  {
    "label": "Norway",
    "value": "Norway"
  },
  {
    "label": "Oman",
    "value": "Oman"
  },
  {
    "label": "Pakistan",
    "value": "Pakistan"
  },
  {
    "label": "Palau",
    "value": "Palau"
  },
  {
    "label": "Panama",
    "value": "Panama"
  },
  {
    "label": "Papua New Guinea",
    "value": "Papua New Guinea"
  },
  {
    "label": "Paraguay",
    "value": "Paraguay"
  },
  {
    "label": "Peru",
    "value": "Peru"
  },
  {
    "label": "Philippines",
    "value": "Philippines"
  },
  {
    "label": "Poland",
    "value": "Poland"
  },
  {
    "label": "Portugal",
    "value": "Portugal"
  },
  {
    "label": "Qatar",
    "value": "Qatar"
  },
  {
    "label": "Romania",
    "value": "Romania"
  },
  {
    "label": "Russia",
    "value": "Russia"
  },
  {
    "label": "Rwanda",
    "value": "Rwanda"
  },
  {
    "label": "Saint Kitts and Nevis",
    "value": "Saint Kitts and Nevis"
  },
  {
    "label": "Saint Lucia",
    "value": "Saint Lucia"
  },
  {
    "label": "Saint Vincent and the Grenadines",
    "value": "Saint Vincent and the Grenadines"
  },
  {
    "label": "Samoa",
    "value": "Samoa"
  },
  {
    "label": "San Marino",
    "value": "San Marino"
  },
  {
    "label": "Sao Tome and Principe",
    "value": "Sao Tome and Principe"
  },
  {
    "label": "Saudi Arabia",
    "value": "Saudi Arabia"
  },
  {
    "label": "Senegal",
    "value": "Senegal"
  },
  {
    "label": "Serbia",
    "value": "Serbia"
  },
  {
    "label": "Seychelles",
    "value": "Seychelles"
  },
  {
    "label": "Sierra Leone",
    "value": "Sierra Leone"
  },
  {
    "label": "Singapore",
    "value": "Singapore"
  },
  {
    "label": "Slovakia",
    "value": "Slovakia"
  },
  {
    "label": "Slovenia",
    "value": "Slovenia"
  },
  {
    "label": "Solomon Islands",
    "value": "Solomon Islands"
  },
  {
    "label": "Somalia",
    "value": "Somalia"
  },
  {
    "label": "South Africa",
    "value": "South Africa"
  },
  {
    "label": "South Korea",
    "value": "South Korea"
  },
  {
    "label": "South Sudan",
    "value": "South Sudan"
  },
  {
    "label": "Spain",
    "value": "Spain"
  },
  {
    "label": "Sri Lanka",
    "value": "Sri Lanka"
  },
  {
    "label": "Sudan",
    "value": "Sudan"
  },
  {
    "label": "Suriname",
    "value": "Suriname"
  },
  {
    "label": "Sweden",
    "value": "Sweden"
  },
  {
    "label": "Switzerland",
    "value": "Switzerland"
  },
  {
    "label": "Syria",
    "value": "Syria"
  },
  {
    "label": "Tajikistan",
    "value": "Tajikistan"
  },
  {
    "label": "Tanzania",
    "value": "Tanzania"
  },
  {
    "label": "Thailand",
    "value": "Thailand"
  },
  {
    "label": "Timor-Leste",
    "value": "Timor-Leste"
  },
  {
    "label": "Togo",
    "value": "Togo"
  },
  {
    "label": "Tonga",
    "value": "Tonga"
  },
  {
    "label": "Trinidad and Tobago",
    "value": "Trinidad and Tobago"
  },
  {
    "label": "Tunisia",
    "value": "Tunisia"
  },
  {
    "label": "Turkey",
    "value": "Turkey"
  },
  {
    "label": "Turkmenistan",
    "value": "Turkmenistan"
  },
  {
    "label": "Tuvalu",
    "value": "Tuvalu"
  },
  {
    "label": "Uganda",
    "value": "Uganda"
  },
  {
    "label": "Ukraine",
    "value": "Ukraine"
  },
  {
    "label": "United Arab Emirates",
    "value": "United Arab Emirates"
  },
  {
    "label": "United Kingdom",
    "value": "United Kingdom"
  },
  {
    "label": "United States Of America",
    "value": "United States Of America"
  },
  {
    "label": "Uruguay",
    "value": "Uruguay"
  },
  {
    "label": "Uzbekistan",
    "value": "Uzbekistan"
  },
  {
    "label": "Vanuatu",
    "value": "Vanuatu"
  },
  {
    "label": "Venezuela",
    "value": "Venezuela"
  },
  {
    "label": "Vietnam",
    "value": "Vietnam"
  },
  {
    "label": "Yemen",
    "value": "Yemen"
  },
  {
    "label": "Zambia",
    "value": "Zambia"
  },
  {
    "label": "Zimbabwe",
    "value": "Zimbabwe"
  }
];

export const PHONE_CODE_OPTIONS = [
  { "label": "+93", "value": "+93" },
  { "label": "+358", "value": "+358" },
  { "label": "+355", "value": "+355" },
  { "label": "+213", "value": "+213" },
  { "label": "+1684", "value": "+1684" },
  { "label": "+376", "value": "+376" },
  { "label": "+244", "value": "+244" },
  { "label": "+1264", "value": "+1264" },
  { "label": "+672", "value": "+672" },
  { "label": "+1268", "value": "+1268" },
  { "label": "+54", "value": "+54" },
  { "label": "+374", "value": "+374" },
  { "label": "+297", "value": "+297" },
  { "label": "+61", "value": "+61" },
  { "label": "+43", "value": "+43" },
  { "label": "+994", "value": "+994" },
  { "label": "+1242", "value": "+1242" },
  { "label": "+973", "value": "+973" },
  { "label": "+880", "value": "+880" },
  { "label": "+1246", "value": "+1246" },
  { "label": "+375", "value": "+375" },
  { "label": "+32", "value": "+32" },
  { "label": "+501", "value": "+501" },
  { "label": "+229", "value": "+229" },
  { "label": "+1441", "value": "+1441" },
  { "label": "+975", "value": "+975" },
  { "label": "+591", "value": "+591" },
  { "label": "+387", "value": "+387" },
  { "label": "+267", "value": "+267" },
  { "label": "+55", "value": "+55" },
  { "label": "+246", "value": "+246" },
  { "label": "+673", "value": "+673" },
  { "label": "+359", "value": "+359" },
  { "label": "+226", "value": "+226" },
  { "label": "+257", "value": "+257" },
  { "label": "+855", "value": "+855" },
  { "label": "+237", "value": "+237" },
  { "label": "+1", "value": "+1" },
  { "label": "+238", "value": "+238" },
  { "label": "+ 345", "value": "+ 345" },
  { "label": "+236", "value": "+236" },
  { "label": "+235", "value": "+235" },
  { "label": "+56", "value": "+56" },
  { "label": "+86", "value": "+86" },
  { "label": "+61", "value": "+61" },
  { "label": "+61", "value": "+61" },
  { "label": "+57", "value": "+57" },
  { "label": "+269", "value": "+269" },
  { "label": "+242", "value": "+242" },
  { "label": "+243", "value": "+243" },
  { "label": "+682", "value": "+682" },
  { "label": "+506", "value": "+506" },
  { "label": "+225", "value": "+225" },
  { "label": "+385", "value": "+385" },
  { "label": "+53", "value": "+53" },
  { "label": "+357", "value": "+357" },
  { "label": "+420", "value": "+420" },
  { "label": "+45", "value": "+45" },
  { "label": "+253", "value": "+253" },
  { "label": "+1767", "value": "+1767" },
  { "label": "+1849", "value": "+1849" },
  { "label": "+593", "value": "+593" },
  { "label": "+20", "value": "+20" },
  { "label": "+503", "value": "+503" },
  { "label": "+240", "value": "+240" },
  { "label": "+291", "value": "+291" },
  { "label": "+372", "value": "+372" },
  { "label": "+251", "value": "+251" },
  { "label": "+500", "value": "+500" },
  { "label": "+298", "value": "+298" },
  { "label": "+679", "value": "+679" },
  { "label": "+358", "value": "+358" },
  { "label": "+33", "value": "+33" },
  { "label": "+594", "value": "+594" },
  { "label": "+689", "value": "+689" },
  { "label": "+241", "value": "+241" },
  { "label": "+220", "value": "+220" },
  { "label": "+995", "value": "+995" },
  { "label": "+49", "value": "+49" },
  { "label": "+233", "value": "+233" },
  { "label": "+350", "value": "+350" },
  { "label": "+30", "value": "+30" },
  { "label": "+299", "value": "+299" },
  { "label": "+1473", "value": "+1473" },
  { "label": "+590", "value": "+590" },
  { "label": "+1671", "value": "+1671" },
  { "label": "+502", "value": "+502" },
  { "label": "+44", "value": "+44" },
  { "label": "+224", "value": "+224" },
  { "label": "+245", "value": "+245" },
  { "label": "+595", "value": "+595" },
  { "label": "+509", "value": "+509" },
  { "label": "+379", "value": "+379" },
  { "label": "+504", "value": "+504" },
  { "label": "+852", "value": "+852" },
  { "label": "+36", "value": "+36" },
  { "label": "+354", "value": "+354" },
  { "label": "+91", "value": "+91" },
  { "label": "+62", "value": "+62" },
  { "label": "+98", "value": "+98" },
  { "label": "+964", "value": "+964" },
  { "label": "+353", "value": "+353" },
  { "label": "+44", "value": "+44" },
  { "label": "+972", "value": "+972" },
  { "label": "+39", "value": "+39" },
  { "label": "+1876", "value": "+1876" },
  { "label": "+81", "value": "+81" },
  { "label": "+44", "value": "+44" },
  { "label": "+962", "value": "+962" },
  { "label": "+77", "value": "+77" },
  { "label": "+254", "value": "+254" },
  { "label": "+686", "value": "+686" },
  { "label": "+850", "value": "+850" },
  { "label": "+82", "value": "+82" },
  { "label": "+965", "value": "+965" },
  { "label": "+996", "value": "+996" },
  { "label": "+856", "value": "+856" },
  { "label": "+371", "value": "+371" },
  { "label": "+961", "value": "+961" },
  { "label": "+266", "value": "+266" },
  { "label": "+231", "value": "+231" },
  { "label": "+218", "value": "+218" },
  { "label": "+423", "value": "+423" },
  { "label": "+370", "value": "+370" },
  { "label": "+352", "value": "+352" },
  { "label": "+853", "value": "+853" },
  { "label": "+389", "value": "+389" },
  { "label": "+261", "value": "+261" },
  { "label": "+265", "value": "+265" },
  { "label": "+60", "value": "+60" },
  { "label": "+960", "value": "+960" },
  { "label": "+223", "value": "+223" },
  { "label": "+356", "value": "+356" },
  { "label": "+692", "value": "+692" },
  { "label": "+596", "value": "+596" },
  { "label": "+222", "value": "+222" },
  { "label": "+230", "value": "+230" },
  { "label": "+262", "value": "+262" },
  { "label": "+52", "value": "+52" },
  { "label": "+691", "value": "+691" },
  { "label": "+373", "value": "+373" },
  { "label": "+377", "value": "+377" },
  { "label": "+976", "value": "+976" },
  { "label": "+382", "value": "+382" },
  { "label": "+1664", "value": "+1664" },
  { "label": "+212", "value": "+212" },
  { "label": "+258", "value": "+258" },
  { "label": "+95", "value": "+95" },
  { "label": "+264", "value": "+264" },
  { "label": "+674", "value": "+674" },
  { "label": "+977", "value": "+977" },
  { "label": "+31", "value": "+31" },
  { "label": "+599", "value": "+599" },
  { "label": "+687", "value": "+687" },
  { "label": "+64", "value": "+64" },
  { "label": "+505", "value": "+505" },
  { "label": "+227", "value": "+227" },
  { "label": "+234", "value": "+234" },
  { "label": "+683", "value": "+683" },
  { "label": "+672", "value": "+672" },
  { "label": "+1670", "value": "+1670" },
  { "label": "+47", "value": "+47" },
  { "label": "+968", "value": "+968" },
  { "label": "+92", "value": "+92" },
  { "label": "+680", "value": "+680" },
  { "label": "+970", "value": "+970" },
  { "label": "+507", "value": "+507" },
  { "label": "+675", "value": "+675" },
  { "label": "+595", "value": "+595" },
  { "label": "+51", "value": "+51" },
  { "label": "+63", "value": "+63" },
  { "label": "+872", "value": "+872" },
  { "label": "+48", "value": "+48" },
  { "label": "+351", "value": "+351" },
  { "label": "+1939", "value": "+1939" },
  { "label": "+974", "value": "+974" },
  { "label": "+40", "value": "+40" },
  { "label": "+7", "value": "+7" },
  { "label": "+250", "value": "+250" },
  { "label": "+262", "value": "+262" },
  { "label": "+590", "value": "+590" },
  { "label": "+290", "value": "+290" },
  { "label": "+1869", "value": "+1869" },
  { "label": "+1758", "value": "+1758" },
  { "label": "+590", "value": "+590" },
  { "label": "+508", "value": "+508" },
  { "label": "+1784", "value": "+1784" },
  { "label": "+685", "value": "+685" },
  { "label": "+378", "value": "+378" },
  { "label": "+239", "value": "+239" },
  { "label": "+966", "value": "+966" },
  { "label": "+221", "value": "+221" },
  { "label": "+381", "value": "+381" },
  { "label": "+248", "value": "+248" },
  { "label": "+232", "value": "+232" },
  { "label": "+65", "value": "+65" },
  { "label": "+421", "value": "+421" },
  { "label": "+386", "value": "+386" },
  { "label": "+677", "value": "+677" },
  { "label": "+252", "value": "+252" },
  { "label": "+27", "value": "+27" },
  { "label": "+211", "value": "+211" },
  { "label": "+500", "value": "+500" },
  { "label": "+34", "value": "+34" },
  { "label": "+94", "value": "+94" },
  { "label": "+249", "value": "+249" },
  { "label": "+597", "value": "+597" },
  { "label": "+47", "value": "+47" },
  { "label": "+268", "value": "+268" },
  { "label": "+46", "value": "+46" },
  { "label": "+41", "value": "+41" },
  { "label": "+963", "value": "+963" },
  { "label": "+886", "value": "+886" },
  { "label": "+992", "value": "+992" },
  { "label": "+255", "value": "+255" },
  { "label": "+66", "value": "+66" },
  { "label": "+670", "value": "+670" },
  { "label": "+228", "value": "+228" },
  { "label": "+690", "value": "+690" },
  { "label": "+676", "value": "+676" },
  { "label": "+1868", "value": "+1868" },
  { "label": "+216", "value": "+216" },
  { "label": "+90", "value": "+90" },
  { "label": "+993", "value": "+993" },
  { "label": "+1649", "value": "+1649" },
  { "label": "+688", "value": "+688" },
  { "label": "+256", "value": "+256" },
  { "label": "+380", "value": "+380" },
  { "label": "+971", "value": "+971" },
  { "label": "+44", "value": "+44" },
  { "label": "+1", "value": "+1" },
  { "label": "+598", "value": "+598" },
  { "label": "+998", "value": "+998" },
  { "label": "+678", "value": "+678" },
  { "label": "+58", "value": "+58" },
  { "label": "+84", "value": "+84" },
  { "label": "+1284", "value": "+1284" },
  { "label": "+1340", "value": "+1340" },
  { "label": "+681", "value": "+681" },
  { "label": "+967", "value": "+967" },
  { "label": "+260", "value": "+260" },
  {
    "label": "+263", "value": "+263"
  }
]

export const MAX_DATE = new Date();

export const QUESTION_TYPES = [
  { label: "Text box", value: "TextBox" },
  { label: "Text area", value: "TextArea" },
  { label: "Drop-down", value: "Dropdown" },
  { label: "Checkbox", value: "Checkbox" },
  { label: "Date picker", value: "DatePicker" },
  { label: "Multi-select", value: "MultiSelect" },
  { label: "Radio", value: "Radio" },
];

export const CASE_TYPE_MAPPING = {
  STAGES: "stages",
  QUESTIONNAIRE: "questionnaire",
  DOCUMENTS: "documents",
  FORMS: "forms",
  QUESTIONNAIRE_MAPPING: "questionnaire-mapping",
  FORM_MAPPING: "form-mapping"
};

export const LANGUAGES_OPTIONS = [
  { label: "English", value: "English" },
  { label: "Spanish", value: "Spanish" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Other", value: "Other" },
];

export const PRIORITY_OPTIONS = [
  { label: "Low", value: "Low" },
  { label: "Normal", value: "Normal" },
  { label: "High", value: "High" },
];

export const REMINDER_OPTIONS = [
  { label: "No reminder", value: "0" },
  { label: "2 days", value: "2" },
  { label: "4 days", value: "4" },
  { label: "7 days", value: "7" },
];

export const UNSAVED_CHANGES_INFO = "Are you sure you want to leave, You have unsaved changes?";

export const APARTMENT_TYPES = [
  { label: "Apt.", value: "Apt." },
  { label: "Door", value: "Door" },
  { label: "Floor", value: "Floor" },
  { label: "Suite", value: "Suite" },
  { label: "Unit", value: "Unit" },
];

export const RELATIONSHIP_OPTIONS = [
  { label: "Wife", value: "Wife" },
  { label: "Husband", value: "Husband" },
  { label: "Former spouse", value: "Former spouse" },
  { label: "Child", value: "Child" },
  { label: "Son", value: "Son" },
  { label: "Daughter", value: "Daughter" },
  { label: "Stepson", value: "Stepson" },
  { label: "Stepdaughter", value: "Stepdaughter" },
  { label: "Father", value: "Father" },
  { label: "Mother", value: "Mother" },
  { label: "Brother", value: "Brother" },
  { label: "Sister", value: "Sister" },
  { label: "Fiance/Fiancee", value: "Fiance/Fiancee" },
  { label: "Son-in-Law", value: "Son-in-Law" },
  { label: "Daughter-In-Law", value: "Daughter-In-Law" },
  { label: "Brother-in-Law", value: "Brother-in-Law" },
  { label: "Sister-in-Law", value: "Sister-in-Law" },
  { label: "Common Law Partner", value: "Common Law Partner" },
  { label: "Domestic Partner", value: "Domestic Partner" },
  { label: "Caregiver", value: "Caregiver" },
  { label: "Legal Guardian", value: "Legal Guardian" },
  { label: "Other", value: "Other" },
  { label: "Co-applicant", value: "Co-applicant" },
];

export const APPLICANT_OPTIONS = [
  { label: "Main Applicant", value: "Main Applicant" },
  { label: "Wife", value: "Wife" },
  { label: "Husband", value: "Husband" },
  { label: "Former spouse", value: "Former spouse" },
  { label: "Child", value: "Child" },
  { label: "Son", value: "Son" },
  { label: "Daughter", value: "Daughter" },
  { label: "Stepson", value: "Stepson" },
  { label: "Stepdaughter", value: "Stepdaughter" },
  { label: "Father", value: "Father" },
  { label: "Mother", value: "Mother" },
  { label: "Brother", value: "Brother" },
  { label: "Sister", value: "Sister" },
  { label: "Fiance/Fiancee", value: "Fiance/Fiancee" },
  { label: "Son-in-Law", value: "Son-in-Law" },
  { label: "Daughter-In-Law", value: "Daughter-In-Law" },
  { label: "Brother-in-Law", value: "Brother-in-Law" },
  { label: "Sister-in-Law", value: "Sister-in-Law" },
  { label: "Common Law Partner", value: "Common Law Partner" },
  { label: "Domestic Partner", value: "Domestic Partner" },
  { label: "Caregiver", value: "Caregiver" },
  { label: "Legal Guardian", value: "Legal Guardian" },
  { label: "Other", value: "Other" },
  { label: "Co-applicant", value: "Co-applicant" },
  { label: "Sponsor", value: "Sponsor" },
];

export const SELECT_ONE_NOTE = "Select atleast one record to export"

// Case Tabs ID
export const CASE_TABS_ID = {
  CLIENT_DETAILS: 'clientDetails',
  COMMUNICATION: 'communication',
  EMAILS: 'emails',
  DRAFTS: 'drafts',
  COMPOSE_EMAIL: 'composeEmail',
  PIPE_NOTES: 'pipeNotes',
  ALL_CASES: 'allCases',
  BILLING: 'billing',
  ALERTS: 'alerts',
};

export const ALERTS_TYPES = [
  { label: "Red", value: "Red" },
  { label: "Green", value: "Green" },
  { label: "Orange", value: "Orange" },
];

export const MODE_OF_CREATION_OPTIONS = [
  { label: "Mode of Creation: All", value: "" },
  { label: "PipeDrive", value: "PipeDrive" },
  { label: "CHWizard(or Manual)", value: "Manual" }
];

export const BILLING_STATUS_OPTIONS = [
  { label: "None", value: "None" },
  { label: "Overdue", value: "Overdue" },
  { label: "On time", value: "On time" }
];

export const MAIN_CONTACTS_OPTIONS = [
  { label: "Main Contact: All", value: "" },
  { label: "Yes", value: "True" },
  { label: "No", value: "False" }
];

export const CASE_STATUS_OPTIONS = [
  { label: "Case Status: All", value: "" },
  { label: "Open", value: "Open" },
  { label: "Close", value: "Closed" }
];

export const CURRENTLY_IN_US_OPTIONS = [
  { label: "Currently in US: All", value: "" },
  { label: "Yes", value: "True" },
  { label: "No", value: "False" }
];


// Roles heading
export const TEAMS_PERMISSION_HEADING = "Manage Team";
export const USERS_PERMISSION_HEADING = "Manage User";
export const DERIVATIVE_ACTION = {
  Send: {
    title: "Send Questionnaire",
    body: "Are you sure, you want to Send the Questionnaire?"
  },
  Completed: {
    title: "Complete Questionnaire",
    body: "Are you sure, you want to Complete the Questionnaire?"
  },
  Reviewed: {
    title: "Review Questionnaire",
    body: "Are you sure, you want to Review the Questionnaire?"
  },
  Resend: {
    title: "Resend Questionnaire",
    body: "Are you sure, you want to Resend the Questionnaire?"
  }
}
export const KB_PERMISSION_STAGES = "Knowledge Base Manage Stages";
export const KB_PERMISSION_STEPS = "Knowledge Base Manage Steps";
export const KB_PERMISSION_DOC_CHECK_ITEMS = "Knowledge Base Manage Document Checklist Items";
export const KB_PERMISSION_DOC_CHECKLIST = "Knowledge Base Manage Document Checklist";
export const KB_PERMISSION_DOC_TYPE = "Knowledge Base Manage Document Type";
export const KB_PERMISSION_DOCUMENT = "Knowledge Base Manage Document";
export const KB_PERMISSION_FORMS = "Knowledge Base Manage Forms";
export const KB_PERMISSION_EMAIL_TEMP = "Knowledge Base Manage Email Templates";
export const KB_PERMISSION_QUESTION = "Knowledge Base Manage Questions";
export const KB_PERMISSION_QUESTIONNAIRE = "Knowledge Base Manage Questionnaires";
export const PERMISSION_RBAC = "You don't have permission to perform this action.";
export const CLIENT_MANAGEMENT_PERMISSION_LISTING = "Client Management Listing";
export const CLIENT_MANAGEMENT_PERMISSION_RELATIVES = "Client Management Relatives";
export const CLIENT_MANAGEMENT_PERMISSION_COMMUNICATION = "Client Management Communication";
export const CLIENT_MANAGEMENT_PERMISSION_NOTES = "Client Management Notes";
export const CLIENT_MANAGEMENT_PERMISSION_BILLING = "Client Management Billing";
export const CLIENT_MANAGEMENT_PERMISSION_CASE = "Client Management Case";
export const CLIENT_MANAGEMENT_PERMISSION_ALERTS = "Client Management Alerts";
export const CASE_TYPE_MANAGEMENT_PERMISSION = "Case Type Management Manage Case Type";
export const CASE_TYPE_MANAGEMENT_PERMISSION_FORMS = "Case Type Management Manage Case Type-Forms";
export const CASE_TYPE_MANAGEMENT_PERMISSION_DOCUMENT = "Case Type Management Manage Case Type-Document";
export const CASE_TYPE_MANAGEMENT_PERMISSION_QUESTIONNAIRE = "Case Type Management Manage Case Type-Questionnairs";
export const CASE_TYPE_MANAGEMENT_PERMISSION_STAGES_STEPS = "Case Type Management Manage Case Type-Stages(Steps)";
export const CASE_MANAGEMENT_PERMISSION_LISTING = "Case Management Listing";
export const CASE_MANAGEMENT_PERMISSION_RECEIPT = "Case Management Receipt";
export const CASE_MANAGEMENT_PERMISSION_DERIVATIVE_COAPPLICANT = "Case Management Derivative/Co-Applicant";
export const CASE_MANAGEMENT_PERMISSION_FILING_DETAIL = "Case Management Filing Detail";
export const CASE_MANAGEMENT_PERMISSION_CASE_INFO_DETAIL = "Case Management Case Info Detail";
export const CASE_MANAGEMENT_PERMISSION_STAGE_AND_STEPS = "Case Management Stage and Steps";
export const CASE_MANAGEMENT_PERMISSION_NOTES = "Case Management Notes";
export const CASE_MANAGEMENT_PERMISSION_DOCUMENT_CHECKLIST = "Case Management Document Checklist";
export const CASE_MANAGEMENT_PERMISSION_DOCUMENT_LISTING = "Case Management Document Listing";
export const CASE_MANAGEMENT_PERMISSION_QUESTIONNAIRS_LISTING = "Case Management Questionnairs Listing";
export const CASE_MANAGEMENT_PERMISSION_FORMS_LISTING = "Case Management Forms Listing";
export const CASE_MANAGEMENT_PERMISSION_REMINDER_LISTING = "Case Management Reminder";
export const CASE_MANAGEMENT_PERMISSION_EVENT_LISTING = "Case Management Event";
export const USER_MANAGEMENT_PERMISSION_MANAGE_DESIGNATION = "Manage Designations";
export const SETTINGS_PERMISSION = "Settings";
export const REPORTS_PERMISSION_GENERATE_NEW_REPORT = "Generate New Report";
export const REPORTS_PERMISSION_SAVED_REPORT = "Saved Reports";
export const DASHBOARD_PERMISSION = "Dashboard";




// --------- Folder Structure Starts from here ----------------

export const AOS_FOLDERS = [
  {
    label: "A. CH files and scans",
    code: "AOSACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "AOSBCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. Application package",
    code: "AOSCAP",
    items: [
      {
        label: "C. Application package",
        value: "C. Application package;C. Application package",
      },
    ],
  },
  {
    label: "D. Translations",
    code: "AOSDT",
    items: [
      { label: "D. Translations", value: "D. Translations;D. Translations" },
    ],
  },
  {
    label: "E. Forms",
    code: "AOSEF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Biographic documents",
    code: "AOSFBD",
    items: [
      {
        label: "Birth Certificates",
        value: "Birth Certificates;F. Biographic documents",
      },
      {
        label: "Change or extensions of status granted",
        value: "Change or extensions of status granted;F. Biographic documents",
      },
      { label: "I-94s", value: "I-94s;F. Biographic documents" },
      {
        label: "Marriage Certificate",
        value: "Marriage Certificate;F. Biographic documents",
      },
      { label: "Passports", value: "Passports;F. Biographic documents" },
      {
        label: "Termination of Prior Marriages",
        value: "Termination of Prior Marriages;F. Biographic documents",
      },
      { label: "Visas", value: "Visas;F. Biographic documents" },
    ],
  },
  {
    label: "G. Medical Examination",
    code: "AOSGME",
    items: [
      {
        label: "G. Medical Examination",
        value: "G. Medical Examination;G. Medical Examination",
      },
    ],
  },
  {
    label: "H. Photos",
    code: "AOSGME",
    items: [{ label: "H. Photos", value: "H. Photos;H. Photos" }],
  },
  {
    label: "I. Inquiries to USCIS",
    code: "AOSIITU",
    items: [
      {
        label: "I. Inquiries to USCIS",
        value: "I. Inquiries to USCIS;I. Inquiries to USCIS",
      },
    ],
  },
  {
    label: "J. Inquiries to Ombudsman",
    code: "AOSJITQ",
    items: [
      {
        label: "J. Inquiries to Ombudsman",
        value: "J. Inquiries to Ombudsman;J. Inquiries to Ombudsman",
      },
    ],
  },
  {
    label: "K. Change of Address",
    code: "AOSKCOA",
    items: [
      {
        label: "K. Change of Address",
        value: "K. Change of Address;K. Change of Address",
      },
    ],
  },
  {
    label: "L. Documents received from USCIS",
    code: "AOSLDRFU",
    items: [
      { label: "EAD", value: "EAD;L. Documents received from USCIS" },
      {
        label: "Green card",
        value: "Green card;L. Documents received from USCIS",
      },
      { label: "I-512", value: "I-512;L. Documents received from USCIS" },
    ],
  },
  {
    label: "M. RFE",
    code: "AOSMRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;M. RFE" },
      { label: "RFE Response", value: "RFE Response;M. RFE" },
    ],
  },
  {
    label: "N. NOID",
    code: "AOSNN",
    items: [
      { label: "NOID documents", value: "NOID documents;N. NOID" },
      { label: "NOID Response", value: "NOID Response;N. NOID" },
    ],
  },
  {
    label: "O. Withdrawal",
    code: "AOSOW",
    items: [{ label: "O. Withdrawal", value: "O. Withdrawal;O. Withdrawal" }],
  },
  {
    label: "P. Others",
    code: "AOSPO",
    items: [{ label: "P. Others", value: "P. Others;P. Others" }],
  },
];

export const CP_Folders = [
  {
    label: 'A. CH files and scans',
    code: 'CPACHFAS',
    items: [
      { label: 'A. CH files and scans', value: 'A. CH files and scans;A. CH files and scans' },
    ]
  },
  {
    label: 'B. Client uploaded files',
    code: 'CPBCUF',
    items: [
      { label: 'B. Client uploaded files', value: 'B. Client uploaded files;B. Client uploaded files' },
    ]
  },
  {
    label: 'C. NVC Packages',
    code: 'CPCNVCP',
    items: [
      { label: 'C. NVC Packages', value: 'C. NVC Packages;C. NVC Packages' },
    ]
  },
  {
    label: 'D. Translations',
    code: 'CPDT',
    items: [
      { label: 'D. Translations', value: 'D. Translations;D. Translations' },
    ]
  },
  {
    label: 'E. Forms',
    code: 'CPEF',
    items: [
      { label: 'E. Forms', value: 'E. Forms;E. Forms' },
    ]
  },
  {
    label: 'F. DS-260 Confirmation Page',
    code: 'CPFDSCP',
    items: [
      { label: 'F. DS-260 Confirmation Page', value: 'F. DS-260 Confirmation Page;F. DS-260 Confirmation Page' },
    ]
  },
  {
    label: 'G. Notices from NVC',
    code: 'CPGNfNVC',
    items: [
      { label: 'Case Number and Invoice', value: 'Case Number and Invoice;G. Notices from NVC' },
      { label: 'Documentarily Complete', value: 'Documentarily Complete;G. Notices from NVC' },
      { label: 'Payment receipts', value: 'Payment receipts;G. Notices from NVC' },
    ]
  },
  {
    label: 'H. RFE',
    code: 'CPHRFE',
    items: [
      { label: 'RFE documents', value: 'RFE documents;H. RFE' },
      { label: 'RFE Response', value: 'RFE Response;H. RFE' },
    ]
  },
  {
    label: 'I. Inquiries to NVC',
    code: 'CPIITNVC',
    items: [
      { label: 'I. Inquiries to NVC', value: 'I. Inquiries to NVC;I. Inquiries to NVC' },
    ]
  },
  {
    label: 'J. Consular Interview and documents',
    code: 'CPJCIAD',
    items: [
      { label: 'J. Consular Interview and documents', value: 'J. Consular Interview and documents;J. Consular Interview and documents' },
    ]
  },
  {
    label: 'K. Others',
    code: 'CPKO',
    items: [
      { label: 'K. Others', value: 'K. Others;K. Others' },
    ]
  },
  
];

export const E1 = [
  {
    label: 'A. CH files and scans',
    code: 'E1ACHFAS',
    items: [
      { label: 'Filing Fee', value: 'Filing Fee;A. CH files and scans' },
      { label: 'From Onboarding', value: 'From Onboarding;A. CH files and scans' },
      { label: 'Notices from USCIS (Receipt, Approval, Denial)', value: 'Notices from USCIS (Receipt, Approval, Denial);A. CH files and scans' },
    ]
  },
  {
    label: 'B. Client uploaded files',
    code: 'E1BCUF',
    items: [
      { label: 'B. Client uploaded files', value: 'B. Client uploaded files;B. Client uploaded files' },
    ]
  },
  {
    label: 'C. Application package',
    code: 'E1CAP',
    items: [
      { label: 'C. Application package', value: 'C. Application package;C. Application package' },
    ]
  },
  {
    label: 'D. Biographic documents',
    code: 'E1DBD',
    items: [
      { label: 'Birth Certificates', value: 'Birth Certificates;D. Biographic documents' },
      { label: 'Change or extensions of status granted', value: 'Change or extensions of status granted;D. Biographic documents' },
      { label: 'I-94s', value: 'I-94s;D. Biographic documents' },
      { label: 'Marriage Certificate', value: 'Marriage Certificate;D. Biographic documents' },
      { label: 'Passports', value: 'Passports;D. Biographic documents' },
      { label: 'Termination of Prior Marriages', value: 'Termination of Prior Marriages;D. Biographic documents' },
      { label: 'Visas', value: 'Visas;D. Biographic documents' },
    ]
  },
  {
    label: 'E. Forms',
    code: 'E1EF',
    items: [
      { label: 'E. Forms', value: 'E. Forms;E. Forms' },
    ]
  },
  {
    label: 'F. Evidence of foreign residence',
    code: 'E1FEOFR',
    items: [
      { label: 'F. Evidence of foreign residence', value: 'F. Evidence of foreign residence;F. Evidence of foreign residence' },
    ]
  }, 
  {
    label: 'G. Declaration of Intent to Depart the U.S',
    code: 'E1GDOITDTUS',
    items: [
      { label: 'G. Declaration of Intent to Depart the U.S', value: 'G. Declaration of Intent to Depart the U.S;G. Declaration of Intent to Depart the U.S' },
    ]
  },  
  {
    label: 'H. Ownership documents',
    code: 'E1HOD',
    items: [
      { label: 'Articles of Incorporation or Organization', value: 'Articles of Incorporation or Organization;H. Ownership documents' },
      { label: 'Bylaws or Operating Agreement', value: 'Bylaws or Operating Agreement;H. Ownership documents' },
      { label: 'Corporate Tax or Annual Report', value: 'Corporate Tax or Annual Report;H. Ownership documents' },
      { label: 'EIN-Tax ID', value: 'EIN-Tax ID;H. Ownership documents' },
      { label: 'Marketing material', value: 'Marketing material;H. Ownership documents' },
      { label: 'Membership or Share Certificate', value: 'Membership or Share Certificate;H. Ownership documents' },
      { label: 'Organizational Chart', value: 'Organizational Chart;H. Ownership documents' },
      { label: 'Other documents', value: 'Other documents;H. Ownership documents' },
      { label: 'Photos of the business', value: 'Photos of the business;H. Ownership documents' },
    ]
  },
  {
    label: 'I. Substantial Trade',
    code: 'E1IST',
    items: [
      { label: 'Other documents', value: 'Other documents;I. Substantial Trade' },
      { label: 'Purchase invoices', value: 'Purchase invoices;I. Substantial Trade' },
      { label: 'Shipping invoices', value: 'Shipping invoices;I. Substantial Trade' },
      { label: 'Spreedsheet of international trade transactions', value: 'Spreedsheet of international trade transactions;I. Substantial Trade' },
      { label: 'US Corporate Tax Returns', value: 'US Corporate Tax Returns;I. Substantial Trade' },
      { label: 'Wire transfers-payment evidence', value: 'Wire transfers-payment evidence;I. Substantial Trade' },
    ]
  },
  {
    label: 'J. Financial documents',
    code: 'E1JFD',
    items: [
      { label: 'Balance Sheet', value: 'Balance Sheet;J. Financial documents' },
      { label: 'Bank statements', value: 'Bank statements;J. Financial documents' },
      { label: 'Corporate Taxes', value: 'Corporate Taxes;J. Financial documents' },
      { label: 'Profit and Loss (P and L)', value: 'Profit and Loss (P and L);J. Financial documents' },
    ]
  },
  {
    label: 'K. Employee qualifications (degress, certificates)',
    code: 'E1KEQDC',
    items: [
      { label: 'Awards and recognitions', value: 'Awards and recognitions;K. Employee qualifications (degress, certificates)' },
      { label: 'Certificates', value: 'Certificates;K. Employee qualifications (degress, certificates)' },
      { label: 'Curriculum Vitae (CV)', value: 'Curriculum Vitae (CV);K. Employee qualifications (degress, certificates)' },
      { label: 'Diplomas', value: 'Diplomas;K. Employee qualifications (degress, certificates)' },
      { label: 'Licenses', value: 'Licenses;K. Employee qualifications (degress, certificates)' },
      { label: 'Other documents', value: 'Other documents;K. Employee qualifications (degress, certificates)' },
      { label: 'Publications', value: 'Publications;K. Employee qualifications (degress, certificates)' },
    ]
  },
  {
    label: 'L. Job offer letter for Essential Employee',
    code: 'E1LJOLFEE',
    items: [
      { label: 'L. Job offer letter for Essential Employee', value: 'L. Job offer letter for Essential Employee;L. Job offer letter for Essential Employee' },
    ]
  },
  {
    label: 'M. Photos',
    code: 'E1MP',
    items: [
      { label: 'M. Photos', value: 'M. Photos;M. Photos' },
    ]
  },
  {
    label: 'N. Consular Interview and documents',
    code: 'E1NCIAD',
    items: [
      { label: 'N. Consular Interview and documents', value: 'N. Consular Interview and documents;N. Consular Interview and documents' },
    ]
  },
  {
    label: 'O. RFE',
    code: 'E1ORFE',
    items: [
      { label: 'RFE documents', value: 'RFE documents;O. RFE' },
      { label: 'RFE Response', value: 'RFE Response;O. RFE' },
    ]
  },
  {
    label: 'P. NOID',
    code: 'E1PNOID',
    items: [
      { label: 'NOID documents', value: 'NOID documents;P. NOID' },
      { label: 'NOID Response', value: 'NOID Response;P. NOID' },
    ]
  },
  {
    label: 'Q. Withdrawal',
    code: 'E1QW',
    items: [
      { label: 'Q. Withdrawal', value: 'Q. Withdrawal;Q. Withdrawal' },
    ]
  },
  {
    label: 'R. Others',
    code: 'E1RO',
    items: [
      { label: 'R. Others', value: 'R. Others;R. Others' },
    ]
  },
];

export const MANDAMUS = [
  {
      label: 'A. Filings and Court documents',
      code: 'MAFCD',
      items: [
          { label: 'A. Filings and Court documents', value: 'A. Filings and Court documents;A. Filings and Court documents' }
      ]
  },
  {
      label: 'B. AOS',
      code: 'MAAOS',
      items: [
          { label: 'B. AOS', value: 'B. AOS;B. AOS' }
      ]
  },
  {
      label: 'C. I-526',
      code: 'MAI526',
      items: [
          { label: 'C. I-526', value: 'C. I-526;C. I-526' }
      ]
  },
  {
      label: 'D. I-829',
      code: 'MAI829',
      items: [
          { label: 'D. I-829', value: 'D. I-829;D. I-829' }
      ]
  },
  {
      label: 'E. Others',
      code: 'MAOTH',
      items: [
          { label: 'E. Others', value: 'E. Others;E. Others' }
      ]
  },
  {
      label: 'Client Uploaded Documents',
      code: 'CUD',
      items: [
          { label: 'Client Uploaded Documents', value: 'Client Uploaded Documents;Client Uploaded Documents' }
      ]
  },
];

export const E2 =  [
  {
    label: 'A. CH files and scans',
    code: 'E2ACHFAS',
    items: [
      { label: 'Filing Fee', value: 'Filing Fee;A. CH files and scans' },
      { label: 'From Onboarding', value: 'From Onboarding;A. CH files and scans' },
      { label: 'Notices from USCIS (Receipt, Approval, Denial)', value: 'Notices from USCIS (Receipt, Approval, Denial);A. CH files and scans' },
    ]
  },
  {
    label: 'B. Client uploaded files',
    code: 'E2BCUF',
    items: [
      { label: 'B. Client uploaded files', value: 'B. Client uploaded files;B. Client uploaded files' },
    ]
  },
  {
    label: 'C. Application package',
    code: 'E2CAP',
    items: [
      { label: 'C. Application package', value: 'C. Application package;C. Application package' },
    ]
  },
  {
    label: 'D. Biographic documents',
    code: 'E2DBD',
    items: [
      { label: 'Birth Certificates', value: 'Birth Certificates;D. Biographic documents' },
      { label: 'Change or extensions of status granted', value: 'Change or extensions of status granted;D. Biographic documents' },
      { label: 'I-94s', value: 'I-94s;D. Biographic documents' },
      { label: 'Marriage Certificate', value: 'Marriage Certificate;D. Biographic documents' },
      { label: 'Passports', value: 'Passports;D. Biographic documents' },
      { label: 'Termination of Prior Marriages', value: 'Termination of Prior Marriages;D. Biographic documents' },
      { label: 'Visas', value: 'Visas;D. Biographic documents' },
    ]
  },
  {
    label: 'E. Forms',
    code: 'E2EF',
    items: [
      { label: 'E. Forms', value: 'E. Forms;E. Forms' },
    ]
  },
  {
    label: 'F. Evidence of foreign residence',
    code: 'E2FEOFR',
    items: [
      { label: 'F. Evidence of foreign residence', value: 'F. Evidence of foreign residence;F. Evidence of foreign residence' },
    ]
  }, 
  {
    label: 'G. Declaration of Intent to Depart the U.S',
    code: 'E2GDOITDTUS',
    items: [
      { label: 'G. Declaration of Intent to Depart the U.S', value: 'G. Declaration of Intent to Depart the U.S;G. Declaration of Intent to Depart the U.S' },
    ]
  },  
  {
    label: 'H. Source of Funds',
    code: 'E2HOD',
    items: [
      { label: 'Evidence of SOF', value: 'Evidence of SOF;H. Source of Funds' },
      { label: 'Movement of Funds to the US', value: 'Movement of Funds to the US;H. Source of Funds' },
    ]
  },
  {
    label: 'I. Ownership documents',
    code: 'E2IOD',
    items: [
      { label: 'Articles of Incorporation or Organization', value: 'Articles of Incorporation or Organization;I. Ownership documents' },
      { label: 'Bylaws or Operating Agreement', value: 'Bylaws or Operating Agreement;I. Ownership documents' },
      { label: 'EIN-Tax ID', value: 'EIN-Tax ID;I. Ownership documents' },
      { label: 'Marketing material', value: 'Marketing material;I. Ownership documents' },
      { label: 'Membership or Share Certificate', value: 'Membership or Share Certificate;I. Ownership documents' },
      { label: 'Organizational Chart', value: 'Organizational Chart;I. Ownership documents' },
      { label: 'Other documents', value: 'Other documents;I. Ownership documents' },
      { label: 'Photos of the business', value: 'Photos of the business;I. Ownership documents' },
    ]
  },
  {
    label: 'J. Investment of Funds (Wire transfers, purchase agreement, etc)',
    code: 'E2JIOFWTPA',
    items: [
      { label: 'Escrow agreement', value: 'Escrow agreement;J. Investment of Funds (Wire transfers, purchase agreement, etc)' },
      { label: 'Other documents', value: 'Other documents;J. Investment of Funds (Wire transfers, purchase agreement, etc)' },
      { label: 'Purchase - Franchise agreements', value: 'Purchase - Franchise agreements;J. Investment of Funds (Wire transfers, purchase agreement, etc)' },
      { label: 'Sale of Assets - Bill of Sales', value: 'Sale of Assets - Bill of Sales;J. Investment of Funds (Wire transfers, purchase agreement, etc)' },
      { label: 'Wire transfers-payment evidence', value: 'Wire transfers-payment evidence;J. Investment of Funds (Wire transfers, purchase agreement, etc)' },
    ]
  },
  {
    label: 'K. Real and Operating (Lease, Licenses, Bank Statements, Invoices, etc)',
    code: 'E2KROLLBI',
    items: [
      { label: 'Bank Statements', value: 'Bank Statements;K. Real and Operating (Lease, Licenses, Bank Statements, Invoices, etc)' },
      { label: 'Equipment-inventory purchase', value: 'Equipment-inventory purchase;K. Real and Operating (Lease, Licenses, Bank Statements, Invoices, etc)' },
      { label: 'Invoices', value: 'Invoices;K. Real and Operating (Lease, Licenses, Bank Statements, Invoices, etc)' },
      { label: 'Invoices, checks, bank and credit card statements', value: 'Invoices, checks, bank and credit card statements;K. Real and Operating (Lease, Licenses, Bank Statements, Invoices, etc)' },
      { label: 'Lease', value: 'Lease;K. Real and Operating (Lease, Licenses, Bank Statements, Invoices, etc)' },
      { label: 'Licenses', value: 'Licenses;K. Real and Operating (Lease, Licenses, Bank Statements, Invoices, etc)' },
      { label: 'Marketing Strategies and Social Media', value: 'Marketing Strategies and Social Media;K. Real and Operating (Lease, Licenses, Bank Statements, Invoices, etc)' },
      { label: 'Other documents', value: 'Other documents;K. Real and Operating (Lease, Licenses, Bank Statements, Invoices, etc)' },
    ]
  },
  {
    label: 'L. Marginality (Business Plan, Balance Sheet, P and L, Taxes, W-2, etc)',
    code: 'E2LMBPBSPLTW',
    items: [
      { label: 'Balance Sheet', value: 'Balance Sheet;L. Marginality (Business Plan, Balance Sheet, P and L, Taxes, W-2, etc)' },
      { label: 'Business Plan', value: 'Business Plan;L. Marginality (Business Plan, Balance Sheet, P and L, Taxes, W-2, etc)' },
      { label: 'Other documents', value: 'Other documents;L. Marginality (Business Plan, Balance Sheet, P and L, Taxes, W-2, etc)' },
      { label: 'Profit and Loss (P and L)', value: 'Profit and Loss (P and L);L. Marginality (Business Plan, Balance Sheet, P and L, Taxes, W-2, etc)' },
      { label: 'US Corporate Tax Returns', value: 'US Corporate Tax Returns;L. Marginality (Business Plan, Balance Sheet, P and L, Taxes, W-2, etc)' },
      { label: 'W-2 and 1099, Forms 941, Payroll Records', value: 'W-2 and 1099, Forms 941, Payroll Records;L. Marginality (Business Plan, Balance Sheet, P and L, Taxes, W-2, etc)' },
    ]
  },
  {
    label: 'M. Employee qualifications (degress, certificates)',
    code: 'E2MEQDC',
    items: [
      { label: 'Awards and recognitions', value: 'Awards and recognitions;M. Employee qualifications (degress, certificates)' },
      { label: 'Certificates', value: 'Certificates;M. Employee qualifications (degress, certificates)' },
      { label: 'Curriculum Vitae (CV)', value: 'Curriculum Vitae (CV);M. Employee qualifications (degress, certificates)' },
      { label: 'Diplomas', value: 'Diplomas;M. Employee qualifications (degress, certificates)' },
      { label: 'Licenses', value: 'Licenses;M. Employee qualifications (degress, certificates)' },
      { label: 'Other documents', value: 'Other documents;M. Employee qualifications (degress, certificates)' },
      { label: 'Publications', value: 'Publications;M. Employee qualifications (degress, certificates)' },
      { label: 'Work experience', value: 'Work experience;M. Employee qualifications (degress, certificates)' },
    ]
  },
  {
    label: 'N. Job offer letter for Essential Employee',
    code: 'E2NJOLFEE',
    items: [
      { label: 'N. Job offer letter for Essential Employee', value: 'N. Job offer letter for Essential Employee;N. Job offer letter for Essential Employee' },
    ]
  },
  {
    label: 'O. Photos',
    code: 'E2OP',
    items: [
      { label: 'O. Photos', value: 'O. Photos;O. Photos' },
    ]
  },
  {
    label: 'P. Consular Interview and documents',
    code: 'E2PCIAD',
    items: [
      { label: 'P. Consular Interview and documents', value: 'P. Consular Interview and documents;P. Consular Interview and documents' },
    ]
  },
  {
    label: "Q. RFE",
    code: "E2QRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;Q. RFE" },
      { label: "RFE Response", value: "RFE Response;Q. RFE" },
    ],
  },
  {
    label: "R. NOID",
    code: "E2RN",
    items: [
      { label: "NOID documents", value: "NOID documents;R. NOID" },
      { label: "NOID Response", value: "NOID Response;R. NOID" },
    ],
  },
  {
    label: 'S. Withdrawal',
    code: 'E2SW',
    items: [
      { label: 'S. Withdrawal', value: 'S. Withdrawal;S. Withdrawal' },
    ]
  },
  {
    label: 'T. Others',
    code: 'E2TO',
    items: [
      { label: 'T. Others', value: 'T. Others;T. Others' },
    ]
  },
];

export const E3 =  [
  {
    label: 'A. CH files and scans',
    code: 'E3ACHFAS',
    items: [
      { label: 'Filing Fee', value: 'Filing Fee;A. CH files and scans' },
      { label: 'From Onboarding', value: 'From Onboarding;A. CH files and scans' },
      { label: 'Notices from USCIS (Receipt, Approval, Denial)', value: 'Notices from USCIS (Receipt, Approval, Denial);A. CH files and scans' },
    ]
  },
  {
    label: 'B. Client uploaded files',
    code: 'E3BCUF',
    items: [
      { label: 'B. Client uploaded files', value: 'B. Client uploaded files;B. Client uploaded files' },
    ]
  },
  {
    label: 'C. Application package',
    code: 'E3CAP',
    items: [
      { label: 'C. Application package', value: 'C. Application package;C. Application package' },
    ]
  },
  {
    label: 'D. Biographic documents',
    code: 'E3DBD',
    items: [
      { label: 'Birth Certificates', value: 'Birth Certificates;D. Biographic documents' },
      { label: 'Change or extensions of status granted', value: 'Change or extensions of status granted;D. Biographic documents' },
      { label: 'I-94s', value: 'I-94s;D. Biographic documents' },
      { label: 'Marriage Certificate', value: 'Marriage Certificate;D. Biographic documents' },
      { label: 'Passports', value: 'Passports;D. Biographic documents' },
      { label: 'Termination of Prior Marriages', value: 'Termination of Prior Marriages;D. Biographic documents' },
      { label: 'Visas', value: 'Visas;D. Biographic documents' },
    ]
  },
  {
    label: 'E. Forms',
    code: 'E3EF',
    items: [
      { label: 'E. Forms', value: 'E. Forms;E. Forms' },
    ]
  },
  {
    label: 'F. Employee qualifications (degress, certificates)',
    code: 'E3FQDC',
    items: [
      { label: 'Awards and recognitions', value: 'Awards and recognitions;F. Employee qualifications (degress, certificates)' },
      { label: 'Certificates', value: 'Certificates;F. Employee qualifications (degress, certificates)' },
      { label: 'Curriculum Vitae (CV)', value: 'Curriculum Vitae (CV);F. Employee qualifications (degress, certificates)' },
      { label: 'Diplomas', value: 'Diplomas;F. Employee qualifications (degress, certificates)' },
      { label: 'Licenses', value: 'Licenses;F. Employee qualifications (degress, certificates)' },
      { label: 'Other documents', value: 'Other documents;F. Employee qualifications (degress, certificates)' },
      { label: 'Publications', value: 'Publications;F. Employee qualifications (degress, certificates)' },
      { label: 'Work experience', value: 'Work experience;F. Employee qualifications (degress, certificates)' },
    ]
  }, 
  {
    label: 'G. Job offer letter for Essential Employee',
    code: 'E3GJOLFEE',
    items: [
      { label: 'G. Job offer letter for Essential Employee', value: 'G. Job offer letter for Essential Employee;G. Job offer letter for Essential Employee' },
    ]
  },  
  {
    label: 'H. Photos',
    code: 'E2HOD',
    items: [
      { label: 'H. Photos', value: 'H. Photos;H. Photos' },
    ]
  },
  {
    label: 'I. Consular Interview and documents',
    code: 'E3ICIAD',
    items: [
      { label: 'I. Consular Interview and documents', value: 'I. Consular Interview and documents;I. Consular Interview and documents' },
    ]
  },
  {
    label: "J. RFE",
    code: "E3JRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;J. RFE" },
      { label: "RFE Response", value: "RFE Response;J. RFE" },
    ],
  },
  {
    label: "K. NOID",
    code: "E3KNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;K. NOID" },
      { label: "NOID Response", value: "NOID Response;K. NOID" },
    ],
  },
  {
    label: 'L. Withdrawal',
    code: 'E3LW',
    items: [
      { label: 'L. Withdrawal', value: 'L. Withdrawal;L. Withdrawal' },
    ]
  },
  {
    label: 'M. Others',
    code: 'E3MO',
    items: [
      { label: 'M. Others', value: 'M. Others;M. Others' },
    ]
  },
];

export const EB1 = [
  {
    label: "1. CH files (from Onboarding)",
    code: "EB1CH",
    items: [
      {
        label: "Filing Fee",
        value: "Filing Fee;1. CH files (from Onboarding)",
      },
      {
        label: "From Onboarding",
        value: "From Onboarding1. CH files (from Onboarding)",
      },
      {
        label: "Notices from USCIS (Receipt, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Approval, RFE, Denial);1. CH files (from Onboarding)",
      },
    ],
  },
  {
    label: "2. Translations",
    code: "EB1TR",
    items: [{ label: "1st Batch", value: "2nd Batch;2. Translations" }],
  },
  {
    label: "3. TOC and Binder",
    code: "EB1TOC",
    items: [
      {
        label: "3. TOC and Binder",
        value: "3. TOC and Binder;3. TOC and Binder",
      },
    ],
  },
  {
    label: "A. Forms",
    code: "EB1FORMS",
    items: [{ label: "A. Forms", value: "A. Forms;A. Forms" }],
  },
  {
    label: "B. Civil documents",
    code: "EB1CIVIL",
    items: [
      {
        label: "Passports, visas and I-94s",
        value:
          "Passports, visas and I-94s;B. Civil documents;B. Civil documents",
      },
      {
        label: "Marriage Certificate",
        value: "Marriage Certificate;B. Civil documents",
      },
      {
        label: "Birth Certificates",
        value: "Birth Certificates;B. Civil documents",
      },
      {
        label: "Termination of Prior Marriages",
        value: "Termination of Prior Marriages;B. Civil documents",
      },
    ],
  },
  {
    label: "C. Advanced degree (diplomas, academic evaluation)",
    code: "EB1AD",
    items: [
      {
        label: "Academic evaluation",
        value:
          "Academic evaluation;C. Advanced degree (diplomas, academic evaluation)",
      },
      {
        label: "Masters degree",
        value:
          "Masters degree;C. Advanced degree (diplomas, academic evaluation)",
      },
      {
        label: "Bacherlors degree",
        value:
          "Bacherlors degree;C. Advanced degree (diplomas, academic evaluation)",
      },
    ],
  },
  {
    label: "D. CV",
    code: "EB1CV",
    items: [
      {
        label: "Endeavor Statement (ES)",
        value: "Endeavor Statement (ES);D. CV",
      },
      {
        label: "Model-Plan for future activities",
        value: "Model-Plan for future activities;D. CV",
      },
    ],
  },
  {
    label: "E. Work Experience Letters",
    code: "EB1WORK",
    items: [
      {
        label: "Curriculum Vitae (CV)",
        value: "Curriculum Vitae (CV);E. Work Experience Letters",
      },
      {
        label: "Foreign Companies",
        value: "Foreign Companies;E. Work Experience Letters",
      },
      {
        label: "Sample of Work Product",
        value: "Sample of Work Product;E. Work Experience Letters",
      },
      {
        label: "Work Experience letter (WELs)",
        value: "Work Experience letter (WELs);E. Work Experience Letters",
      },
    ],
  },
  {
    label: "F. Prizes or Awards",
    code: "EB1PRIZE",
    items: [
      { label: "Drafts", value: "Drafts" },
      {
        label: "Final version - approved",
        value: "Final version - approved;F. Prizes or Awards",
      },
      { label: "Questionnaires", value: "Questionnaires;F. Prizes or Awards" },
      {
        label: "Recommenders CV or Linkedin",
        value: "Recommenders CV or Linkedin;F. Prizes or Awards",
      },
    ],
  },
  {
    label: "G. Memberships",
    code: "EB1MEM",
    items: [
      { label: "Drafts", value: "Drafts;G. Memberships" },
      {
        label: "Final version - approved",
        value: "Final version - approved:;G. Memberships",
      },
      { label: "Questionnaires", value: "Questionnaires;G. Memberships" },
    ],
  },
  {
    label: "H. Published Material and Citations",
    code: "EB1PUB",
    items: [
      {
        label: "Certificates",
        value: "Certificates;H. Published Material and Citations",
      },
      {
        label: "Licenses",
        value: "Licenses;H. Published Material and Citations",
      },
      {
        label: "Memberships",
        value: "Memberships;H. Published Material and Citations",
      },
      {
        label: "Publications and Patents",
        value: "Publications and Patents;H. Published Material and Citations",
      },
      {
        label: "Awards and recognitions",
        value: "Awards and recognitions;H. Published Material and Citations",
      },
      {
        label: "Continuing education",
        value: "Continuing education;H. Published Material and Citations",
      },
      {
        label: "Media reports",
        value: "Media reports;H. Published Material and Citations",
      },
      { label: "Other", value: "Other;H. Published Material and Citations" },
    ],
  },
  {
    label: "I. Media Publications about the Applicant",
    code: "EB1MEDIA",
    items: [
      {
        label: "1.Substantial Merit",
        value: "1.Substantial Merit;I. Media Publications about the Applicant",
      },
      {
        label: "2.National Importance",
        value:
          "2.National Importance;I. Media Publications about the Applicant",
      },
      {
        label: "3. On Balance Prong 3",
        value:
          "3. On Balance Prong 3;I. Media Publications about the Applicant",
      },
      {
        label: "4. Brief",
        value: "4. Brief;I. Media Publications about the Applicant",
      },
    ],
  },
  {
    label: "J. Invited talks, review, and judge of others",
    code: "EB1INVITE",
    items: [
      {
        label: "J. Invited talks, review, and judge of others",
        value:
          "J. Invited talks, review, and judge of others;J. Invited talks, review, and judge of others",
      },
    ],
  },
  {
    label: "K. Major contributions to the field",
    code: "EB1MAJOR",
    items: [
      {
        label: "K. Major contributions to the field",
        value:
          "K. Major contributions to the field;K. Major contributions to the field",
      },
    ],
  },
  {
    label: "L. Artistic Exhibitions or Showcases",
    code: "EB1ART",
    items: [
      {
        label: "L. Artistic Exhibitions or Showcases",
        value:
          "L. Artistic Exhibitions or Showcases;L. Artistic Exhibitions or Showcases",
      },
    ],
  },
  {
    label: "M. Leading or Critical Role",
    code: "EB1LEAD",
    items: [
      {
        label: "M. Leading or Critical Role",
        value: "M. Leading or Critical Role;M. Leading or Critical Role",
      },
    ],
  },
  {
    label: "N. High Salary",
    code: "EB1HIGH",
    items: [
      { label: "N. High Salary", value: "N. High Salary;N. High Salary" },
    ],
  },
  {
    label: "O. Commercial Successes in the Performing Arts",
    code: "EB1COMM",
    items: [
      {
        label: "O. Commercial Successes in the Performing Arts",
        value:
          "O. Commercial Successes in the Performing Arts;O. Commercial Successes in the Performing Arts",
      },
    ],
  },
  {
    label: "P. Job Offer in the U.S. (for EB-1B)",
    code: "EB1JOB",
    items: [
      {
        label: "P. Job Offer in the U.S. (for EB-1B)",
        value:
          "P. Job Offer in the U.S. (for EB-1B);P. Job Offer in the U.S. (for EB-1B)",
      },
    ],
  },
  {
    label: "Q. Filing fee",
    code: "EB1FILING",
    items: [{ label: "Q. Filing fee", value: "Q. Filing fee;Q. Filing fee" }],
  },
  {
    label: "R. Checklist for Paralegals",
    code: "EB1CHECK",
    items: [
      {
        label: "R. Checklist for Paralegals",
        value: "R. Checklist for Paralegals;R. Checklist for Paralegals",
      },
    ],
  },
  {
    label: "S. Notices from USCIS (Receipt, Approval, RFE, Denial)",
    code: "EB1NOT",
    items: [
      {
        label: "S. Notices from USCIS (Receipt, Approval, RFE, Denial)",
        value:
          "S. Notices from USCIS (Receipt, Approval, RFE, Denial);S. Notices from USCIS (Receipt, Approval, RFE, Denial)",
      },
    ],
  },
  {
    label: "T. Others",
    code: "EB1OTH",
    items: [{ label: "T. Others", value: "T. Others;T. Others" }],
  },
  {
    label: "Client Uploaded Documents",
    code: "CUD",
    items: [
      {
        label: "Client Uploaded Documents",
        value: "Client Uploaded Documents;Client Uploaded Documents",
      },
    ],
  },
];

export const EB1A = [
  {
    label: "A. CH files and scans",
    code: "EB1AACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "EB1ABCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. TOC, Binder, and Checklist",
    code: "EB1ABCUF",
    items: [
      {
        label: "C. TOC, Binder, and Checklist",
        value: "C. TOC, Binder, and Checklist;C. TOC, Binder, and Checklist",
      },
    ],
  },
  {
    label: "D. Translations",
    code: "EB1ADT",
    items: [
      { label: "1st Batch", value: "1st Batch;D. Translations" },
      { label: "2nd Batch", value: "2nd Batch;D. Translations" },
    ],
  },
  {
    label: "E. Forms",
    code: "EB1AEF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Biographic documents",
    code: "EB1AFBD",
    items: [
      {
        label: "Birth Certificates",
        value: "Birth Certificates;F. Biographic documents",
      },
      {
        label: "Change or extensions of status granted",
        value: "Change or extensions of status granted;F. Biographic documents",
      },
      { label: "I-94s", value: "I-94s;F. Biographic documents" },
      {
        label: "Marriage Certificate",
        value: "Marriage Certificate;F. Biographic documents",
      },
      { label: "Passports", value: "Passports;F. Biographic documents" },
      {
        label: "Termination of Prior Marriages",
        value: "Termination of Prior Marriages;F. Biographic documents",
      },
      { label: "Visas", value: "Visas;F. Biographic documents" },
    ],
  },
  {
    label: "G. Academic credentials and CV",
    code: "EB1AGACCV",
    items: [
      {
        label: "Academic evaluation",
        value: "Academic evaluation;G. Academic credentials and CV",
      },
      {
        label: "Bachelors degree",
        value: "Bachelors degree;G. Academic credentials and CV",
      },
      {
        label: "Certificates",
        value: "Certificates;G. Academic credentials and CV",
      },
      {
        label: "Continuing Education",
        value: "Continuing Education;G. Academic credentials and CV",
      },
      {
        label: "Curriculum Vitae (CV)",
        value: "Curriculum Vitae (CV);G. Academic credentials and CV",
      },
      {
        label: "Masters and doctorate degrees",
        value: "Masters and doctorate degrees;G. Academic credentials and CV",
      },
      {
        label: "Other documents",
        value: "Other documents;G. Academic credentials and CV",
      },
    ],
  },
  {
    label: "H. Extraordinary Ability",
    code: "EB1AEXTRA",
    items: [
      {
        label: "Artistic Exhibitions or Showcases",
        value: "Artistic Exhibitions or Showcases;H. Extraordinary Ability",
      },
      {
        label: "Commercial Successes in the Performing Arts",
        value:
          "Commercial Successes in the Performing Arts;H. Extraordinary Ability",
      },
      {
        label: "High Remuneration",
        value: "High Remuneration;H. Extraordinary Ability",
      },
      {
        label: "Invited Talks, Review, and Judging Record",
        value:
          "Invited Talks, Review, and Judging Record;H. Extraordinary Ability",
      },
      {
        label: "Major Contribution to the Field",
        value: "Major Contribution to the Field;H. Extraordinary Ability",
      },
      {
        label: "Media and Publication Recognition",
        value: "Media and Publication Recognition;H. Extraordinary Ability",
      },
      { label: "Memberships", value: "Memberships;H. Extraordinary Ability" },
      {
        label: "Other documents",
        value: "Other documents;H. Extraordinary Ability",
      },
      {
        label: "Performance in a Leading or Major Role",
        value:
          "Performance in a Leading or Major Role;H. Extraordinary Ability",
      },
      {
        label: "Prizes or Awards",
        value: "Prizes or Awards;H. Extraordinary Ability",
      },
      {
        label: "Published Material and Citations",
        value: "Published Material and Citations;H. Extraordinary Ability",
      },
    ],
  },
  {
    label: "I. Writers",
    code: "EB1AWRIT",
    items: [{ label: "I. Writers", value: "I. Writers;I. Writers" }],
  },
  {
    label: "J. RFE",
    code: "EB1AJRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;J. RFE" },
      { label: "RFE Response", value: "RFE Response;J. RFE" },
    ],
  },
  {
    label: "K. NOID",
    code: "EB1AKNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;K. NOID" },
      { label: "NOID Response", value: "NOID Response;K. NOID" },
    ],
  },
  {
    label: "L. Withdrawal",
    code: "EB1ALW",
    items: [{ label: "L. Withdrawal", value: "L. Withdrawal;L. Withdrawal" }],
  },
  {
    label: "M. Others",
    code: "EB1AMO",
    items: [{ label: "M. Others", value: "M. Others;M. Others" }],
  },
];

export const EB1B = [
  {
    label: "A. CH files and scans",
    code: "EB1BACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "EB1BBCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. TOC, Binder, and Checklist",
    code: "EB1BBCUF",
    items: [
      {
        label: "C. TOC, Binder, and Checklist",
        value: "C. TOC, Binder, and Checklist;C. TOC, Binder, and Checklist",
      },
    ],
  },
  {
    label: "D. Translations",
    code: "EB1BDT",
    items: [
      { label: "1st Batch", value: "1st Batch;D. Translations" },
      { label: "2nd Batch", value: "2nd Batch;D. Translations" },
    ],
  },
  {
    label: "E. Forms",
    code: "EB1BEF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Biographic documents",
    code: "EB1BFBD",
    items: [
      {
        label: "Birth Certificates",
        value: "Birth Certificates;F. Biographic documents",
      },
      {
        label: "Change or extensions of status granted",
        value: "Change or extensions of status granted;F. Biographic documents",
      },
      { label: "I-94s", value: "I-94s;F. Biographic documents" },
      {
        label: "Marriage Certificate",
        value: "Marriage Certificate;F. Biographic documents",
      },
      { label: "Passports", value: "Passports;F. Biographic documents" },
      {
        label: "Termination of Prior Marriages",
        value: "Termination of Prior Marriages;F. Biographic documents",
      },
      { label: "Visas", value: "Visas;F. Biographic documents" },
    ],
  },
  {
    label: "G. Academic credentials and CV",
    code: "EB1BGACCV",
    items: [
      {
        label: "Academic evaluation",
        value: "Academic evaluation;G. Academic credentials and CV",
      },
      {
        label: "Bachelors degree",
        value: "Bachelors degree;G. Academic credentials and CV",
      },
      {
        label: "Certificates",
        value: "Certificates;G. Academic credentials and CV",
      },
      {
        label: "Continuing Education",
        value: "Continuing Education;G. Academic credentials and CV",
      },
      {
        label: "Curriculum Vitae (CV)",
        value: "Curriculum Vitae (CV);G. Academic credentials and CV",
      },
      {
        label: "Masters and doctorate degrees",
        value: "Masters and doctorate degrees;G. Academic credentials and CV",
      },
      {
        label: "Other documents",
        value: "Other documents;G. Academic credentials and CV",
      },
    ],
  },
  {
    label: "H. Job Offer in the U.S",
    code: "EB1BBCUF",
    items: [
      {
        label: "H. Job Offer in the U.S",
        value: "H. Job Offer in the U.S;H. Job Offer in the U.S",
      },
    ],
  },
  {
    label: "I. Outstanding-Academic Research (for EB-1B)",
    code: "EB1BEXTRA",
    items: [
      {
        label: "Invited Talks, Review, and Judging Record",
        value:
          "Invited Talks, Review, and Judging Record;I. Outstanding-Academic Research (for EB-1B)",
      },
      {
        label: "Major Contribution to the Field",
        value:
          "Major Contribution to the Field;I. Outstanding-Academic Research (for EB-1B)",
      },
      {
        label: "Media and Publication Recognition",
        value:
          "Media and Publication Recognition;I. Outstanding-Academic Research (for EB-1B)",
      },
      {
        label: "Memberships",
        value: "Memberships;I. Outstanding-Academic Research (for EB-1B)",
      },
      {
        label: "Other documents",
        value: "Other documents;I. Outstanding-Academic Research (for EB-1B)",
      },
      {
        label: "Prizes or Awards",
        value: "Prizes or Awards;I. Outstanding-Academic Research (for EB-1B)",
      },
      {
        label: "Published Material and Citations",
        value:
          "Published Material and Citations;I. Outstanding-Academic Research (for EB-1B)",
      },
    ],
  },
  {
    label: "J. Writers",
    code: "EB1AWRIT",
    items: [{ label: "J. Writers", value: "J. Writers;J. Writers" }],
  },
  {
    label: "K. RFE",
    code: "EB1BJRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;K. RFE" },
      { label: "RFE Response", value: "RFE Response;K. RFE" },
    ],
  },
  {
    label: "L. NOID",
    code: "EB1BKNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;L. NOID" },
      { label: "NOID Response", value: "NOID Response;L. NOID" },
    ],
  },
  {
    label: "M. Withdrawal",
    code: "EB1BLW",
    items: [{ label: "M. Withdrawal", value: "M. Withdrawal;M. Withdrawal" }],
  },
  {
    label: "N. Others",
    code: "EB1BMO",
    items: [{ label: "N. Others", value: "N. Others;N. Others" }],
  },
];

export const EB1C = [
  {
    label: "A. CH files and scans",
    code: "EB1CACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "EB1CBCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. TOC, Binder, and Checklist",
    code: "EB1CBCUF",
    items: [
      {
        label: "C. TOC, Binder, and Checklist",
        value: "C. TOC, Binder, and Checklist;C. TOC, Binder, and Checklist",
      },
    ],
  },
  {
    label: "D. Translations",
    code: "EB1CDT",
    items: [
      { label: "1st Batch", value: "1st Batch;D. Translations" },
      { label: "2nd Batch", value: "2nd Batch;D. Translations" },
    ],
  },
  {
    label: "E. Forms",
    code: "EB1CEF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Biographic documents",
    code: "EB1CFBD",
    items: [
      {
        label: "Birth Certificates",
        value: "Birth Certificates;F. Biographic documents",
      },
      {
        label: "Change or extensions of status granted",
        value: "Change or extensions of status granted;F. Biographic documents",
      },
      { label: "I-94s", value: "I-94s;F. Biographic documents" },
      {
        label: "Marriage Certificate",
        value: "Marriage Certificate;F. Biographic documents",
      },
      { label: "Passports", value: "Passports;F. Biographic documents" },
      {
        label: "Termination of Prior Marriages",
        value: "Termination of Prior Marriages;F. Biographic documents",
      },
      { label: "Visas", value: "Visas;F. Biographic documents" },
    ],
  },
  {
    label: "G. Academic credentials and CV",
    code: "EB1CGACCV",
    items: [
      {
        label: "Academic evaluation",
        value: "Academic evaluation;G. Academic credentials and CV",
      },
      {
        label: "Bachelors degree",
        value: "Bachelors degree;G. Academic credentials and CV",
      },
      {
        label: "Certificates",
        value: "Certificates;G. Academic credentials and CV",
      },
      {
        label: "Continuing Education",
        value: "Continuing Education;G. Academic credentials and CV",
      },
      {
        label: "Curriculum Vitae (CV)",
        value: "Curriculum Vitae (CV);G. Academic credentials and CV",
      },
      {
        label: "Masters and doctorate degrees",
        value: "Masters and doctorate degrees;G. Academic credentials and CV",
      },
      {
        label: "Other documents",
        value: "Other documents;G. Academic credentials and CV",
      },
    ],
  },
  {
    label: "H. Multinational executive or managerial evidence",
    code: "EB1CHMEOME",
    items: [
      {
        label: "H. Multinational executive or managerial evidence",
        value: "H. Multinational executive or managerial evidence;H. Multinational executive or managerial evidence",
      },
    ],
  },
  {
    label: "I. Job Offer in the U.S",
    code: "EB1CEXTRA",
    items: [
      {
        label: "I. Job Offer in the U.S",
        value:
          "I. Job Offer in the U.S;I. Job Offer in the U.S",
      },
   
    ],
  },
  {
    label: "J. US Company",
    code: "EB1CJUSC",
    items: [
      { label: "Articles of Incorporation or Organization", value: "Articles of Incorporation or Organization;J. US Company" },
      { label: "Balance Sheet", value: "Balance Sheet;J. US Company" },
      { label: "Bank statements", value: "Bank statements;J. US Company" },
      { label: "Business License", value: "Business License;J. US Company" },
      { label: "Business Plan", value: "Business Plan;J. US Company" },
      { label: "Business transactions (contracts, bills, etc)", value: "Business transactions (contracts, bills, etc);J. US Company" },
      { label: "Bylaws or Operating Agreement", value: "Bylaws or Operating Agreement;J. US Company" },
      { label: "Companys decision to transfer", value: "Companys decision to transfer;J. US Company" },
      { label: "EIN-Tax ID", value: "EIN-Tax ID;J. US Company" },
      { label: "Lease for business commercial space", value: "Lease for business commercial space;J. US Company" },
      { label: "Marketing material", value: "Marketing material;J. US Company" },
      { label: "Membership or Share Certificate", value: "Membership or Share Certificate;J. US Company" },
      { label: "Organizational Chart", value: "Organizational Chart;J. US Company" },
      { label: "Other documents", value: "Other documents;J. US Company" },
      { label: "Photos of the business", value: "Photos of the business;J. US Company" },
      { label: "Profit and Loss (P and L)", value: "Profit and Loss (P and L);J. US Company" },
      { label: "Relationship between foreign and US entity", value: "Relationship between foreign and US entity;J. US Company" },
      { label: "US Corporate Tax Returns", value: "US Corporate Tax Returns;J. US Company" },
      { label: "W-2 and 1099, Forms 941, Payroll Records", value: "W-2 and 1099, Forms 941, Payroll Records;J. US Company" },
    ],
  },
  {
    label: "K. Foreign Company",
    code: "EB1CJUSC",
    items: [
      { label: "Articles of Incorporation or Organization", value: "Articles of Incorporation or Organization;K. Foreign Company" },
      { label: "Balance Sheet", value: "Balance Sheet;K. Foreign Company" },
      { label: "Bank statements", value: "Bank statements;K. Foreign Company" },
      { label: "Business License", value: "Business License;K. Foreign Company" },
      { label: "Business transactions (contracts, bills, etc)", value: "Business transactions (contracts, bills, etc);K. Foreign Company" },
      { label: "Bylaws or Operating Agreement", value: "Bylaws or Operating Agreement;K. Foreign Company" },
      { label: "Corporate Tax or Annual Report", value: "Corporate Tax or Annual Report;K. Foreign Company" },
      { label: "Marketing material", value: "Marketing material;K. Foreign Company" },
      { label: "Membership or Share Certificate", value: "Membership or Share Certificate;K. Foreign Company" },
      { label: "Organizational Chart", value: "Organizational Chart;K. Foreign Company" },
      { label: "Other documents", value: "Other documents;K. Foreign Company" },
      { label: "Payroll Records", value: "Payroll Records;K. Foreign Company" },
      { label: "Photos of the business", value: "Photos of the business;K. Foreign Company" },
      { label: "Profit and Loss (P and L)", value: "Profit and Loss (P and L);K. Foreign Company" },
      { label: "Relationship between foreign and US entity", value: "Relationship between foreign and US entity;K. Foreign Company" },
    ],
  },
  {
    label: "L. Writers",
    code: "EB1CLW",
    items: [{ label: "L. Writers", value: "L. Writers;L. Writers" }],
  },
  {
    label: "M. RFE",
    code: "EB1CMRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;M. RFE" },
      { label: "RFE Response", value: "RFE Response;M. RFE" },
    ],
  },
  {
    label: "N. NOID",
    code: "EB1CNNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;N. NOID" },
      { label: "NOID Response", value: "NOID Response;N. NOID" },
    ],
  },
  {
    label: "O. Withdrawal",
    code: "EB1CMW",
    items: [{ label: "O. Withdrawal", value: "O. Withdrawal;O. Withdrawal" }],
  },
  {
    label: "P. Others",
    code: "EB1CNO",
    items: [{ label: "P. Others", value: "P. Others;P. Others" }],
  },
];

export const EB2 =  [
  {
    label: "A. CH files and scans",
    code: "EB2ACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "EB2BCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. TOC, Binder, and Checklist",
    code: "EB2CTBAC",
    items: [
      {
        label: "C. TOC, Binder, and Checklist",
        value: "C. TOC, Binder, and Checklist;C. TOC, Binder, and Checklist",
      },
    ],
  },
  {
    label: "D. Translations",
    code: "EB2DT",
    items: [
      { label: "1st Batch", value: "1st Batch;D. Translations" },
      { label: "2nd Batch", value: "2nd Batch;D. Translations" },
    ],
  },
  {
    label: "E. Forms",
    code: "EB2EF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Biographic documents",
    code: "EB2FBD",
    items: [
      {
        label: "Birth Certificates",
        value: "Birth Certificates;F. Biographic documents",
      },
      {
        label: "Change or extensions of status granted",
        value: "Change or extensions of status granted;F. Biographic documents",
      },
      { label: "I-94s", value: "I-94s;F. Biographic documents" },
      {
        label: "Marriage Certificate",
        value: "Marriage Certificate;F. Biographic documents",
      },
      { label: "Passports", value: "Passports;F. Biographic documents" },
      {
        label: "Termination of Prior Marriages",
        value: "Termination of Prior Marriages;F. Biographic documents",
      },
      { label: "Visas", value: "Visas;F. Biographic documents" },
    ],
  },
  {
    label: "G. Curriculum Vitae (CV)",
    code: "EB2GCV",
    items: [
      {
        label: "G. Curriculum Vitae (CV)",
        value: "G. Curriculum Vitae (CV);G. Curriculum Vitae (CV)",
      },
    ],
  },
  {
    label: "H. Academic credentials",
    code: "EB2HAC",
    items: [
      {
        label: "Academic evaluation",
        value: "Academic evaluation;H. Academic credentials",
      },
      {
        label: "Bachelors degree",
        value: "Bachelors degree;H. Academic credentials",
      },
      {
        label: "Certificates",
        value: "Certificates;H. Academic credentials",
      },
      {
        label: "Continuing Education",
        value: "Continuing Education;H. Academic credentials",
      },
      {
        label: "Masters and doctorate degrees",
        value: "Masters and doctorate degrees;H. Academic credentials",
      },
    
    ],
  },
  {
    label: "I. Exceptional Ability",
    code: "EB2IEA",
    items: [
      {
        label: "Awards and Recognitions",
        value: "Awards and Recognitions;I. Exceptional Ability",
      },
      {
        label: "Licenses",
        value:
          "Licenses;I. Exceptional Ability",
      },
      {
        label: "Memberships",
        value: "Memberships;I. Exceptional Ability",
      },
      {
        label: "Other documents",
        value:
          "Other documents;I. Exceptional Ability",
      },
      {
        label: "Salary or Other Remuneration",
        value: "Salary or Other Remuneration;I. Exceptional Ability",
      },
      {
        label: "Work Experience",
        value: "Work Experience;I. Exceptional Ability",
      },
    ],
  },
  {
    label: "J. Job Offer in the U.S",
    code: "EB2JJOITUS",
    items: [
      {
        label: "J. Job Offer in the U.S",
        value:
          "J. Job Offer in the U.S;J. Job Offer in the U.S",
      },
   
    ],
  },
  {
    label: "K. PERM Labor Certification",
    code: "EB2KPLC",
    items: [
      {
        label: "K. PERM Labor Certification",
        value:
          "K. PERM Labor Certification;K. PERM Labor Certification",
      },
   
    ],
  },
  {
    label: "L. Writers",
    code: "EB1CLW",
    items: [{ label: "L. Writers", value: "L. Writers;L. Writers" }],
  },
  {
    label: "M. RFE",
    code: "EB1CMRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;M. RFE" },
      { label: "RFE Response", value: "RFE Response;M. RFE" },
    ],
  },
  {
    label: "N. NOID",
    code: "EB1CNNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;N. NOID" },
      { label: "NOID Response", value: "NOID Response;N. NOID" },
    ],
  },
  {
    label: "O. Withdrawal",
    code: "EB1CMW",
    items: [{ label: "O. Withdrawal", value: "O. Withdrawal;O. Withdrawal" }],
  },
  {
    label: "P. Others",
    code: "EB1CNO",
    items: [{ label: "P. Others", value: "P. Others;P. Others" }],
  },
];

export const EB2_NIW_FOLDERS =   [
  {
    label: "A. CH files and scans",
    code: "EB2ACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "EB2BCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. TOC, Binder, and Checklist",
    code: "EB2CTBAC",
    items: [
      {
        label: "C. TOC, Binder, and Checklist",
        value: "C. TOC, Binder, and Checklist;C. TOC, Binder, and Checklist",
      },
    ],
  },
  {
    label: "D. Translations",
    code: "EB2DT",
    items: [
      { label: "1st Batch", value: "1st Batch;D. Translations" },
      { label: "2nd Batch", value: "2nd Batch;D. Translations" },
    ],
  },
  {
    label: "E. Forms",
    code: "EB2EF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Biographic documents",
    code: "EB2FBD",
    items: [
      {
        label: "Birth Certificates",
        value: "Birth Certificates;F. Biographic documents",
      },
      {
        label: "Change or extensions of status granted",
        value: "Change or extensions of status granted;F. Biographic documents",
      },
      { label: "I-94s", value: "I-94s;F. Biographic documents" },
      {
        label: "Marriage Certificate",
        value: "Marriage Certificate;F. Biographic documents",
      },
      { label: "Passports", value: "Passports;F. Biographic documents" },
      {
        label: "Termination of Prior Marriages",
        value: "Termination of Prior Marriages;F. Biographic documents",
      },
      { label: "Visas", value: "Visas;F. Biographic documents" },
    ],
  },
  {
    label: "G. Academic credentials",
    code: "EB2HAC",
    items: [
      {
        label: "Academic evaluation",
        value: "Academic evaluation;G. Academic credentials",
      },
      {
        label: "Bacherlors degree",
        value: "Bacherlors degree;G. Academic credentials",
      },
      {
        label: "Masters degree",
        value: "Masters degree;G. Academic credentials",
      },
    
    ],
  },
  {
    label: "H. Endeavor Statement and Model - Plan",
    code: "EB2HAC",
    items: [
      {
        label: "Endeavor Statement (Drafts)",
        value: "Endeavor Statement (Drafts);H. Endeavor Statement and Model - Plan",
      },
      {
        label: "Endeavor Statement (Final version - approved)",
        value: "Endeavor Statement (Final version - approved);H. Endeavor Statement and Model - Plan",
      },
      {
        label: "Model-Plan (Drafts)",
        value: "Model-Plan (Drafts);H. Endeavor Statement and Model - Plan",
      },
      {
        label: "Model-Plan (Final version - approved)",
        value: "Model-Plan (Final version - approved);H. Endeavor Statement and Model - Plan",
      },
    ],
  },
  {
    label: "I. Work Experience",
    code: "EB2IWE",
    items: [
      {
        label: "Curriculum Vitae (CV)",
        value: "Curriculum Vitae (CV);I. Work Experience",
      },
      {
        label: "Foreign Companies",
        value: "Foreign Companies;I. Work Experience",
      },
      {
        label: "Other documents",
        value: "Other documents;I. Work Experience",
      },
      {
        label: "Sample of Work Product",
        value: "Sample of Work Product;I. Work Experience",
      },
      {
        label: "Work Experience letter (WELs)",
        value: "Work Experience letter (WELs);I. Work Experience",
      },
    ],
  },
  {
    label: "J. Letters of Recommendation",
    code: "EB2JLOR",
    items: [
      {
        label: "Drafts",
        value: "Drafts;J. Letters of Recommendation",
      },
      {
        label: "Final version - approved",
        value: "Final version - approved;J. Letters of Recommendation",
      },
      {
        label: "Questionnaires",
        value: "Questionnaires;J. Letters of Recommendation",
      },
      {
        label: "Recommenders CV or Linkedin",
        value: "Recommenders CV or Linkedin;J. Letters of Recommendation",
      },
    ],
  },
  {
    label: "K. Letters of Interest",
    code: "EB2JLOR",
    items: [
      {
        label: "Drafts",
        value: "Drafts;K. Letters of Interest",
      },
      {
        label: "Final version - approved",
        value: "Final version - approved;K. Letters of Interest",
      },
      {
        label: "Questionnaires",
        value: "Questionnaires;K. Letters of Interest",
      },
    ],
  },
  {
    label: "L. Well- Positioned",
    code: "EB2JLWP",
    items: [
      {
        label: "Awards and Recognitions",
        value: "Awards and Recognitions;L. Well- Positioned",
      },
      {
        label: "Certificates",
        value: "Certificates;L. Well- Positioned",
      },
      {
        label: "Continuing Education",
        value: "Continuing Education;L. Well- Positioned",
      },
      {
        label: "Licenses",
        value: "Licenses;L. Well- Positioned",
      },
      {
        label: "Media Reports",
        value: "Media Reports;L. Well- Positioned",
      },
      {
        label: "Memberships",
        value: "Memberships;L. Well- Positioned",
      },
      {
        label: "Other documents",
        value: "Other documents;L. Well- Positioned",
      },
      {
        label: "Patents",
        value: "Patents;L. Well- Positioned",
      },
      {
        label: "Publications",
        value: "Publications;L. Well- Positioned",
      },
    ],
  },
  {
    label: "M. Writers",
    code: "EB1CLW",
    items: [{ label: "M. Writers", value: "M. Writers;M. Writers" }],
  },
  {
    label: "N. RFE",
    code: "EB1CMRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;N. RFE" },
      { label: "RFE Response", value: "RFE Response;N. RFE" },
    ],
  },
  {
    label: "O. NOID",
    code: "EB1CNNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;O. NOID" },
      { label: "NOID Response", value: "NOID Response;O. NOID" },
    ],
  },
  {
    label: "P. Withdrawal",
    code: "EB1CMW",
    items: [{ label: "P. Withdrawal", value: "P. Withdrawal;P. Withdrawal" }],
  },
  {
    label: "Q. Others",
    code: "EB1CNO",
    items: [{ label: "Q. Others", value: "Q. Others;Q. Others" }],
  },
];

export const EB3 =  [
  {
    label: "A. CH files and scans",
    code: "EB3ACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "EB3BCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. TOC, Binder, and Checklist",
    code: "EB3CTBAC",
    items: [
      {
        label: "C. TOC, Binder, and Checklist",
        value: "C. TOC, Binder, and Checklist;C. TOC, Binder, and Checklist",
      },
    ],
  },
  {
    label: "D. Translations",
    code: "EB3DT",
    items: [
      { label: "1st Batch", value: "1st Batch;D. Translations" },
      { label: "2nd Batch", value: "2nd Batch;D. Translations" },
    ],
  },
  {
    label: "E. Forms",
    code: "EB3EF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Biographic documents",
    code: "EB3FBD",
    items: [
      {
        label: "Birth Certificates",
        value: "Birth Certificates;F. Biographic documents",
      },
      {
        label: "Change or extensions of status granted",
        value: "Change or extensions of status granted;F. Biographic documents",
      },
      { label: "I-94s", value: "I-94s;F. Biographic documents" },
      {
        label: "Marriage Certificate",
        value: "Marriage Certificate;F. Biographic documents",
      },
      { label: "Passports", value: "Passports;F. Biographic documents" },
      {
        label: "Termination of Prior Marriages",
        value: "Termination of Prior Marriages;F. Biographic documents",
      },
      { label: "Visas", value: "Visas;F. Biographic documents" },
    ],
  },
  {
    label: "G. Academic credentials",
    code: "EB2HAC",
    items: [
      {
        label: "Academic evaluation",
        value: "Academic evaluation;G. Academic credentials",
      },
      {
        label: "Bachelors degree",
        value: "Bachelors degree;G. Academic credentials",
      },
      {
        label: "Certificates",
        value: "Certificates;G. Academic credentials",
      },
      {
        label: "Continuing Education",
        value: "Continuing Education;G. Academic credentials",
      },
      {
        label: "Masters and doctorate degrees",
        value: "Masters and doctorate degrees;G. Academic credentials",
      },
    
    ],
  },
  {
    label: "H. Beneficiary qualifications",
    code: "EB3IEA",
    items: [
      {
        label: "Curriculum Vitae (CV)",
        value: "Curriculum Vitae (CV);H. Beneficiary qualifications",
      },
      {
        label: "Licenses",
        value:
          "Licenses;H. Beneficiary qualifications",
      },
      {
        label: "Memberships",
        value: "Memberships;H. Beneficiary qualifications",
      },
      {
        label: "Other documents",
        value:
          "Other documents;H. Beneficiary qualifications",
      },
      {
        label: "Work Experience",
        value: "Work Experience;H. Beneficiary qualifications",
      },
    ],
  },
  {
    label: "I. Job Offer in the U.S",
    code: "EB3IJOITUS",
    items: [
      {
        label: "I. Job Offer in the U.S",
        value:
          "I. Job Offer in the U.S;I. Job Offer in the U.S",
      },
   
    ],
  },
  {
    label: "J. PERM Labor Certification",
    code: "EB3jPLC",
    items: [
      {
        label: "J. PERM Labor Certification",
        value:
          "J. PERM Labor Certification;J. PERM Labor Certification",
      },
   
    ],
  },
  {
    label: "K. Writers",
    code: "EB3KW",
    items: [{ label: "K. Writers", value: "K. Writers;K. Writers" }],
  },
  {
    label: "L. RFE",
    code: "EB3LRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;L. RFE" },
      { label: "RFE Response", value: "RFE Response;L. RFE" },
    ],
  },
  {
    label: "M. NOID",
    code: "EB3MNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;M. NOID" },
      { label: "NOID Response", value: "NOID Response;M. NOID" },
    ],
  },
  {
    label: "N. Withdrawal",
    code: "EB3NW",
    items: [{ label: "N. Withdrawal", value: "N. Withdrawal;N. Withdrawal" }],
  },
  {
    label: "O. Others",
    code: "EB3Oo",
    items: [{ label: "O. Others", value: "O. Others;O. Others" }],
  },
];

export const EB5 =  [
  {
    label: "A. CH files and scans",
    code: "EB5ACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "EB5BCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. Application package",
    code: "EB5CAP",
    items: [
      {
        label: "C. Application package",
        value: "C. Application package;C. Application package",
      },
    ],
  },
  {
    label: "D. Translations",
    code: "EB3DT",
    items: [
      { label: "D. Translations", value: "D. Translations;D. Translations" },
    ],
  },
  {
    label: "E. Forms",
    code: "EB3EF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Biographic documents",
    code: "EB3FBD",
    items: [
      {
        label: "Birth Certificates",
        value: "Birth Certificates;F. Biographic documents",
      },
      {
        label: "Change or extensions of status granted",
        value: "Change or extensions of status granted;F. Biographic documents",
      },
      { label: "I-94s", value: "I-94s;F. Biographic documents" },
      {
        label: "Marriage Certificate",
        value: "Marriage Certificate;F. Biographic documents",
      },
      { label: "Passports", value: "Passports;F. Biographic documents" },
      {
        label: "Termination of Prior Marriages",
        value: "Termination of Prior Marriages;F. Biographic documents",
      },
      { label: "Visas", value: "Visas;F. Biographic documents" },
    ],
  },
  {
    label: "G. Academic credentials and CV",
    code: "EB5GACACV",
    items: [
      {
        label: "Certificates",
        value: "Certificates;G. Academic credentials and CV",
      },
      {
        label: "Curriculum Vitae (CV)",
        value: "Curriculum Vitae (CV);G. Academic credentials and CV",
      },
      {
        label: "Diplomas",
        value: "Diplomas;G. Academic credentials and CV",
      },
      
      {
        label: "Other documents",
        value: "Other documents;G. Academic credentials and CV",
      },
    ],
  },
  {
    label: "H. Taxes (most recent 5 years)",
    code: "EB5HTAXES",
    items: [{ label: "H. Taxes (most recent 5 years)", value: "H. Taxes (most recent 5 years);H. Taxes (most recent 5 years)" }],
  },
  {
    label: "I. Statement of the Origin of Funds",
    code: "EB5ISTATEMENT",
    items: [{ label: "I. Statement of the Origin of Funds", value: "I. Statement of the Origin of Funds;I. Statement of the Origin of Funds" }],
  },
  {
    label: "J. Capital earned through ownership of business",
    code: "EB5JCAPITAL",
    items: [
      {
        label: "Business premises and printed material",
        value: "Business premises and printed material;J. Capital earned through ownership of business",
      },
      {
        label: "Business registration",
        value:
          "Business registration;J. Capital earned through ownership of business",
      },
      {
        label: "Business taxes (last 5 years)",
        value: "Business taxes (last 5 years);J. Capital earned through ownership of business",
      },
      {
        label: "Company license",
        value:
          "Company license;J. Capital earned through ownership of business",
      },
      {
        label: "Distribution of dividends",
        value: "Distribution of dividends;J. Capital earned through ownership of business",
      },
      {
        label: "Financial statements",
        value: "Financial statements;J. Capital earned through ownership of business",
      },
      {
        label: "Other documents",
        value: "Other documents;J. Capital earned through ownership of business",
      },
      {
        label: "Partnership agreement, share register",
        value: "Partnership agreement, share register;J. Capital earned through ownership of business",
      },
      {
        label: "SOF to purchase shares",
        value: "SOF to purchase shares;J. Capital earned through ownership of business",
      },
    ],
  },
  {
    label: "K. Capital earned through property sale",
    code: "EB5JCAPITAL",
    items: [
      {
        label: "Loan and mortgage documents",
        value: "Loan and mortgage documents;K. Capital earned through property sale",
      },
      {
        label: "Other documents",
        value:
          "Other documents;K. Capital earned through property sale",
      },
      {
        label: "Payment of purchase price",
        value: "Payment of purchase price;K. Capital earned through property sale",
      },
      {
        label: "Property ownership registration",
        value:
          "Property ownership registration;K. Capital earned through property sale",
      },
      {
        label: "Property purchase contract",
        value: "Property purchase contract;K. Capital earned through property sale",
      },
      {
        label: "Property sales contract",
        value: "Property sales contract;K. Capital earned through property sale",
      },
      {
        label: "Real estate tax certificate",
        value: "Real estate tax certificate;K. Capital earned through property sale",
      },
      {
        label: "Receipt of purchase price",
        value: "Receipt of purchase price;K. Capital earned through property sale",
      },
      {
        label: "SOF to purchase the propery",
        value: "SOF to purchase the propery;K. Capital earned through property sale",
      },
    ],
  },
  {
    label: "L. Capital earned through gift",
    code: "EB5JCAPITAL",
    items: [
      {
        label: "Donor-donee relationship",
        value: "Donor-donee relationship;L. Capital earned through gift",
      },
      {
        label: "Donor education and job background",
        value:
          "Donor education and job background;L. Capital earned through gift",
      },
      {
        label: "Donors Civil Documents",
        value: "Donors Civil Documents;L. Capital earned through gift",
      },
      {
        label: "Donor statement of gift",
        value:
          "Donor statement of gift;L. Capital earned through gift",
      },
      {
        label: "Donor taxes (last 5 years)",
        value: "Donor taxes (last 5 years);L. Capital earned through gift",
      },
      {
        label: "Other documents",
        value: "Other documents;L. Capital earned through gift",
      },
      {
        label: "Payment of gift taxes",
        value: "Payment of gift taxes;L. Capital earned through gift",
      },
      {
        label: "SOF to gift amount",
        value: "SOF to gift amount;L. Capital earned through gift",
      },
      {
        label: "Transfer of funds donor-donee",
        value: "Transfer of funds donor-donee;L. Capital earned through gift",
      },
    ],
  },
  {
    label: "M. Capital earned through loan",
    code: "EB5MCAPITAL",
    items: [
      {
        label: "Loan agreement",
        value: "Loan agreement;M. Capital earned through loan",
      },
      {
        label: "Other documents",
        value:
          "Other documents;M. Capital earned through loan",
      },
      {
        label: "Receipt of loan capital",
        value: "Receipt of loan capital;M. Capital earned through loan",
      },
     
     
    ],
  },
  {
    label: "N. Capital earned through employment earnings",
    code: "EB5NCAPITAL",
    items: [
      {
        label: "Bank statements of savings",
        value: "Bank statements of savings;N. Capital earned through employment earnings",
      },
      {
        label: "Employer evidence",
        value:
          "Employer evidence;N. Capital earned through employment earnings",
      },
      {
        label: "Employment evidence",
        value: "Employment evidence;N. Capital earned through employment earnings",
      },
      {
        label: "Other documents",
        value: "Other documents;N. Capital earned through employment earnings",
      },
      {
        label: "Payroll records",
        value: "Payroll records;N. Capital earned through employment earnings",
      },
    ],
  },
  {
    label: "O. Capital earned through inheritance",
    code: "EB5NCAPITAL",
    items: [
      {
        label: "Benefactor - Beneficiray relationship",
        value: "Benefactor - Beneficiray relationship;O. Capital earned through inheritance",
      },
      {
        label: "Inheritance tax payment",
        value:
          "Inheritance tax payment;O. Capital earned through inheritance",
      },
      {
        label: "Other documents",
        value: "Other documents;O. Capital earned through inheritance",
      },
      {
        label: "Other documents",
        value: "Other documents;O. Capital earned through inheritance",
      },
      {
        label: "Receipt of inherited funds",
        value: "Receipt of inherited funds;O. Capital earned through inheritance",
      },
      {
        label: "SOF to acquired inherited funds",
        value: "SOF to acquired inherited funds;O. Capital earned through inheritance",
      },
      {
        label: "Will and testament",
        value: "Will and testament;O. Capital earned through inheritance",
      },
    ],
  },
  {
    label: "P. Other SOF",
    code: "EB5CAP",
    items: [
      {
        label: "P. Other SOF",
        value: "P. Other SOF;P. Other SOF",
      },
    ],
  },
  {
    label: "Q. Transfer of funds to the U.S. enterprise",
    code: "EB5QTRANSFER",
    items: [
      {
        label: "Bank statements showing capital",
        value: "Bank statements showing capital;Q. Transfer of funds to the U.S. enterprise",
      },
      {
        label: "Bank transfers, wire receipts to the US",
        value:
          "Bank transfers, wire receipts to the US;Q. Transfer of funds to the U.S. enterprise",
      },
      {
        label: "Other documents",
        value: "Other documents;Q. Transfer of funds to the U.S. enterprise",
      },
    ],
  },
  {
    label: "R. RFE",
    code: "EB5RRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;R. RFE" },
      { label: "RFE Response", value: "RFE Response;R. RFE" },
    ],
  },
  {
    label: "S. NOID",
    code: "EB5SNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;S. NOID" },
      { label: "NOID Response", value: "NOID Response;S. NOID" },
    ],
  },
  {
    label: "T. Withdrawal",
    code: "EB5TW",
    items: [{ label: "T. Withdrawal", value: "T. Withdrawal;T. Withdrawal" }],
  },
  {
    label: "U. Others",
    code: "EB5UO",
    items: [{ label: "U. Others", value: "U. Others;U. Others" }],
  },
];

export const H1B =  [
  {
    label: "A. CH files and scans",
    code: "H1BACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "H1BBCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. Application package",
    code: "H1BCTBAC",
    items: [
      {
        label: "C. Application package",
        value: "C. Application package;C. Application package",
      },
    ],
  },
  {
    label: "D. Translations",
    code: "H1BDT",
    items: [
      { label: "1st Batch", value: "1st Batch;D. Translations" },
      { label: "2nd Batch", value: "2nd Batch;D. Translations" },
    ],
  },
  {
    label: "E. Forms",
    code: "H1BEF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Biographic documents",
    code: "H1BFBD",
    items: [
      {
        label: "Birth Certificates",
        value: "Birth Certificates;F. Biographic documents",
      },
      {
        label: "Change or extensions of status granted",
        value: "Change or extensions of status granted;F. Biographic documents",
      },
      { label: "I-94s", value: "I-94s;F. Biographic documents" },
      {
        label: "Marriage Certificate",
        value: "Marriage Certificate;F. Biographic documents",
      },
      { label: "Passports", value: "Passports;F. Biographic documents" },
      {
        label: "Termination of Prior Marriages",
        value: "Termination of Prior Marriages;F. Biographic documents",
      },
      { label: "Visas", value: "Visas;F. Biographic documents" },
    ],
  },
  {
    label: "G. Academic credentials",
    code: "H1BGAC",
    items: [
      {
        label: "Academic evaluation",
        value: "Academic evaluation;G. Academic credentials",
      },
      {
        label: "Bachelors degree",
        value: "Bachelors degree;G. Academic credentials",
      },
      {
        label: "Certificates",
        value: "Certificates;G. Academic credentials",
      },
      {
        label: "Continuing Education",
        value: "Continuing Education;G. Academic credentials",
      },
      {
        label: "Masters and doctorate degrees",
        value: "Masters and doctorate degrees;G. Academic credentials",
      },
      {
        label: "Other documents",
        value: "Other documents;G. Academic credentials",
      },
    ],
  },
  {
    label: "H. Beneficiary qualifications",
    code: "H1BHBQ",
    items: [
      {
        label: "Curriculum Vitae (CV)",
        value: "Curriculum Vitae (CV);H. Beneficiary qualifications",
      },
      {
        label: "Licenses",
        value:
          "Licenses;H. Beneficiary qualifications",
      },
      {
        label: "Memberships",
        value: "Memberships;H. Beneficiary qualifications",
      },
      {
        label: "Other documents",
        value:
          "Other documents;H. Beneficiary qualifications",
      },
      {
        label: "Pay Stubs",
        value: "Pay Stubs;H. Beneficiary qualifications",
      },
      {
        label: "Work Experience Letters (WELs)",
        value: "Work Experience Letters (WELs);H. Beneficiary qualifications",
      },
    ],
  },
  {
    label: "I. Job Offer in the U.S",
    code: "H1BIJOITUS",
    items: [
      {
        label: "I. Job Offer in the U.S",
        value:
          "I. Job Offer in the U.S;I. Job Offer in the U.S",
      },
   
    ],
  },
  {
    label: "J. US Company",
    code: "H1BHBQ",
    items: [
      {
        label: "Articles of Incorporation or Organization",
        value: "Articles of Incorporation or Organization;J. US Company",
      },
      {
        label: "Balance Sheet",
        value:
          "Balance Sheet;J. US Company",
      },
      {
        label: "Bank statements",
        value: "Bank statements;J. US Company",
      },
      {
        label: "Business License",
        value:
          "Business License;J. US Company",
      },
      {
        label: "Business Plan",
        value: "Business Plan;J. US Company",
      },
      {
        label: "Bylaws or Operating Agreement",
        value: "Bylaws or Operating Agreement;J. US Company",
      },
      {
        label: "EIN-Tax ID",
        value: "EIN-Tax ID;J. US Company",
      },
      {
        label: "Marketing material",
        value: "Marketing material;J. US Company",
      },
      {
        label: "Membership or Share Certificate",
        value: "Membership or Share Certificate;J. US Company",
      },

      {
        label: "Organizational Chart",
        value: "Organizational Chart;J. US Company",
      },
      {
        label: "Other documents",
        value: "Other documents;J. US Company",
      },
      {
        label: "Photos of the business",
        value: "Photos of the business;J. US Company",
      },
      {
        label: "Profit and Loss (P and L)",
        value: "Profit and Loss (P and L);J. US Company",
      },
      {
        label: "US Corporate Tax Returns",
        value: "US Corporate Tax Returns;J. US Company",
      },
      {
        label: "W-2 and 1099, Forms 941, Payroll Records",
        value: "W-2 and 1099, Forms 941, Payroll Records;J. US Company",
      },
    ],
  },
  {
    label: "K. Photos",
    code: "H1BKP",
    items: [{ label: "K. Photos", value: "K. Photos;K. Photos" }],
  },
  {
    label: "L. Consular interview and documents",
    code: "H1BLCIAD",
    items: [{ label: "L. Consular interview and documents", value: "L. Consular interview and documents;L. Consular interview and documents" }],
  },
  {
    label: "M. RFE",
    code: "H1BMRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;M. RFE" },
      { label: "RFE Response", value: "RFE Response;M. RFE" },
    ],
  },
  {
    label: "N. NOID",
    code: "H1BMNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;N. NOID" },
      { label: "NOID Response", value: "NOID Response;N. NOID" },
    ],
  },
  {
    label: "O. Withdrawal",
    code: "H1BNW",
    items: [{ label: "O. Withdrawal", value: "O. Withdrawal;O. Withdrawal" }],
  },
  {
    label: "P. Others",
    code: "H1BPO",
    items: [{ label: "P. Others", value: "P. Others;P. Others" }],
  },
];

export const I90 = [
  {
    label: "A. CH files and scans",
    code: "I90ACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "I90BBCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. Application package",
    code: "I90CTBAC",
    items: [
      {
        label: "C. Application package",
        value: "C. Application package;C. Application package",
      },
    ],
  },
  {
    label: "D. Biographic documents",
    code: "I90DBD",
    items: [
      { label: "Birth Certificates", value: "Birth Certificates;D. Biographic documents" },
      { label: "Government-issued ID", value: "Government-issued ID;D. Biographic documents" },
      { label: "Green Card", value: "Green Card;D. Biographic documents" },
      { label: "Marriage Certificate", value: "Marriage Certificate;D. Biographic documents" },
      { label: "Other documents", value: "Other documents;D. Biographic documents" },
      { label: "Passport, I-551 stamp", value: "Passport, I-551 stamp;D. Biographic documents" },
    ],
  },
  {
    label: "E. Forms",
    code: "I90EF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Photos",
    code: "I90FP",
    items: [{ label: "F. Photos", value: "F. Photos;F. Photos" }],
  },
  {
    label: "G. Inquiries to USCIS",
    code: "I90GITU",
    items: [{ label: "G. Inquiries to USCIS", value: "G. Inquiries to USCIS;G. Inquiries to USCIS" }],
  },
  {
    label: "H. Inquiries to Ombudsman",
    code: "I90GITU",
    items: [{ label: "H. Inquiries to Ombudsman", value: "H. Inquiries to Ombudsman;H. Inquiries to Ombudsman" }],
  },
  {
    label: "I. Change of Address",
    code: "I90GITU",
    items: [{ label: "I. Change of Address", value: "I. Change of Address;I. Change of Address" }],
  },
  {
    label: "J. RFE",
    code: "I90RFE",
    items: [
      { label: "RFE documents", value: "RFE documents;J. RFE" },
      { label: "RFE Response", value: "RFE Response;J. RFE" },
    ],
  },
  {
    label: "K. NOID",
    code: "I90KNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;K. NOID" },
      { label: "NOID Response", value: "NOID Response;K. NOID" },
    ],
  },
  {
    label: "L. Withdrawal",
    code: "I90LW",
    items: [{ label: "L. Withdrawal", value: "L. Withdrawal;L. Withdrawal" }],
  },
  {
    label: "M. Others",
    code: "I90MO",
    items: [{ label: "M. Others", value: "M. Others;M. Others" }],
  },
];

export const I131 =  [
  {
    label: "A. CH files and scans",
    code: "I131ACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "I131BCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. Application package",
    code: "I131CAP",
    items: [
      {
        label: "C. Application package",
        value: "C. Application package;C. Application package",
      },
    ],
  },
  {
    label: 'D. Biographic documents',
    code: 'I131DBD',
    items: [
      { label: 'Arrest records', value: 'Arrest records;D. Biographic documents' },
      { label: 'Birth Certificates', value: 'Birth Certificates;D. Biographic documents' },
      { label: 'Green Cards', value: 'Green Cards;D. Biographic documents' },
      { label: 'Marriage Certificate', value: 'Marriage Certificate;D. Biographic documents' },
      { label: 'Other documents', value: 'Other documents;D. Biographic documents' },
      { label: 'Passports, I-551 stamp', value: 'Passports, I-551 stamp;D. Biographic documents' },
      { label: 'Tax Returns', value: 'Tax Returns;D. Biographic documents' },
      { label: 'Termination of Prior Marriages', value: 'Termination of Prior Marriages;D. Biographic documents' },
    ]
  },
  {
    label: "E. Forms",
    code: "I131EF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Biographic documents",
    code: "I131FBD",
    items: [
      {
        label: "Birth Certificates",
        value: "Birth Certificates;F. Biographic documents",
      },
      { label: "I-94s", value: "I-94s;F. Biographic documents" },
      {
        label: "Marriage Certificate",
        value: "Marriage Certificate;F. Biographic documents",
      },
      { label: "Passports", value: "Passports;F. Biographic documents" },
      {
        label: "Termination of Prior Marriages",
        value: "Termination of Prior Marriages;F. Biographic documents",
      },
      { label: "Visas", value: "Visas;F. Biographic documents" },
    ],
  },
  {
    label: "G. Photos",
    code: "I131GP",
    items: [{ label: "G. Photos", value: "G. Photos;G. Photos" }],
  },
  {
    label: "H. Inquiries to USCIS",
    code: "I131HITU",
    items: [{ label: "H. Inquiries to USCIS", value: "H. Inquiries to USCIS;H. Inquiries to USCIS" }],
  },
  {
    label: "I. Inquiries to Ombudsman",
    code: "I131IITQ",
    items: [{ label: "I. Inquiries to Ombudsman", value: "I. Inquiries to Ombudsman;I. Inquiries to Ombudsman" }],
  },
  {
    label: "J. Change of Address",
    code: "I131JCOA",
    items: [{ label: "J. Change of Address", value: "J. Change of Address;J. Change of Address" }],
  },
  {
    label: "K. Documents received from USCIS",
    code: "I131KDRFU",
    items: [{ label: "K. Documents received from USCIS", value: "K. Documents received from USCIS;K. Documents received from USCIS" }],
  },
  {
    label: "L. RFE",
    code: "I131LRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;L. RFE" },
      { label: "RFE Response", value: "RFE Response;L. RFE" },
    ],
  },
  {
    label: "M. NOID",
    code: "I131MNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;M. NOID" },
      { label: "NOID Response", value: "NOID Response;M. NOID" },
    ],
  },
  {
    label: "N. Withdrawal",
    code: "I131NW",
    items: [{ label: "N. Withdrawal", value: "N. Withdrawal;N. Withdrawal" }],
  },
  {
    label: "O. Others",
    code: "I131OO",
    items: [{ label: "O. Others", value: "O. Others;O. Others" }],
  },
];

export const I290B = [
  {
    label: "A. CH files and scans",
    code: "I290ACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "I290BCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. Application package",
    code: "I290CTBAC",
    items: [
      {
        label: "C. Application package",
        value: "C. Application package;C. Application package",
      },
    ],
  },
  {
    label: "D. Brief package",
    code: "I290DBP",
    items: [
      {
        label: "D. Brief package",
        value: "D. Brief package;D. Brief package",
      },
    ],
  },
  {
    label: "E. Forms",
    code: "I290EF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Inquiries to USCIS",
    code: "I290BFITU",
    items: [{ label: "F. Inquiries to USCIS", value: "F. Inquiries to USCIS;F. Inquiries to USCIS" }],
  },
  {
    label: "G. Inquiries to Ombudsman",
    code: "I290GITO",
    items: [{ label: "G. Inquiries to Ombudsman", value: "G. Inquiries to Ombudsman;G. Inquiries to Ombudsman" }],
  },
  {
    label: "H. Change of Address",
    code: "I290HCOA",
    items: [{ label: "H. Change of Address", value: "H. Change of Address;H. Change of Address" }],
  },
  {
    label: "I. RFE",
    code: "I290IRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;I. RFE" },
      { label: "RFE Response", value: "RFE Response;I. RFE" },
    ],
  },
  {
    label: "J. NOID",
    code: "I290JNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;J. NOID" },
      { label: "NOID Response", value: "NOID Response;J. NOID" },
    ],
  },
  {
    label: "K. Withdrawal",
    code: "I290KW",
    items: [{ label: "K. Withdrawal", value: "K. Withdrawal;K. Withdrawal" }],
  },
  {
    label: "L. Others",
    code: "I290LO",
    items: [{ label: "L. Others", value: "L. Others;L. Others" }],
  },
];

export const I765 =  [
  {
    label: "A. CH files and scans",
    code: "I765ACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "I765BCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. Application package",
    code: "I765CAP",
    items: [
      {
        label: "C. Application package",
        value: "C. Application package;C. Application package",
      },
    ],
  },
  {
    label: 'D. Biographic documents',
    code: 'I765DBD',
    items: [
      { label: 'Arrest records', value: 'Arrest records;D. Biographic documents' },
      { label: 'Birth Certificates', value: 'Birth Certificates;D. Biographic documents' },
      { label: 'Green Cards', value: 'Green Cards;D. Biographic documents' },
      { label: 'Marriage Certificate', value: 'Marriage Certificate;D. Biographic documents' },
      { label: 'Other documents', value: 'Other documents;D. Biographic documents' },
      { label: 'Passports, I-551 stamp', value: 'Passports, I-551 stamp;D. Biographic documents' },
      { label: 'Tax Returns', value: 'Tax Returns;D. Biographic documents' },
      { label: 'Termination of Prior Marriages', value: 'Termination of Prior Marriages;D. Biographic documents' },
    ]
  },
  {
    label: "E. Forms",
    code: "I765EF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Biographic documents",
    code: "I765FBD",
    items: [
      {
        label: "Birth Certificates",
        value: "Birth Certificates;F. Biographic documents",
      },
      { label: "I-94s", value: "I-94s;F. Biographic documents" },
      { label: "Passports", value: "Passports;F. Biographic documents" },
      { label: "Visas", value: "Visas;F. Biographic documents" },
    ],
  },
  {
    label: "G. Photos",
    code: "I765GP",
    items: [{ label: "G. Photos", value: "G. Photos;G. Photos" }],
  },
  {
    label: "H. Inquiries to USCIS",
    code: "I765HITU",
    items: [{ label: "H. Inquiries to USCIS", value: "H. Inquiries to USCIS;H. Inquiries to USCIS" }],
  },
  {
    label: "I. Inquiries to Ombudsman",
    code: "I765ITTO",
    items: [{ label: "I. Inquiries to Ombudsman", value: "I. Inquiries to Ombudsman;I. Inquiries to Ombudsman" }],
  },
  {
    label: "J. Change of Address",
    code: "I765JCOA",
    items: [{ label: "J. Change of Address", value: "J. Change of Address;J. Change of Address" }],
  },
  {
    label: "K. Documents received from USCIS",
    code: "I765KDRFU",
    items: [{ label: "K. Documents received from USCIS", value: "K. Documents received from USCIS;K. Documents received from USCIS" }],
  },
  {
    label: "L. RFE",
    code: "I765LRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;L. RFE" },
      { label: "RFE Response", value: "RFE Response;L. RFE" },
    ],
  },
  {
    label: "M. NOID",
    code: "I765MNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;M. NOID" },
      { label: "NOID Response", value: "NOID Response;M. NOID" },
    ],
  },
  {
    label: "N. Withdrawal",
    code: "I765NW",
    items: [{ label: "N. Withdrawal", value: "N. Withdrawal;N. Withdrawal" }],
  },
  {
    label: "O. Others",
    code: "I765OO",
    items: [{ label: "O. Others", value: "O. Others;O. Others" }],
  },
];

export const I824 =   [
  {
    label: "A. CH files and scans",
    code: "I824ACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "I824BCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. Application package",
    code: "I824CAP",
    items: [
      {
        label: "C. Application package",
        value: "C. Application package;C. Application package",
      },
    ],
  },
  {
    label: 'D. Biographic documents',
    code: 'I824DBD',
    items: [
      { label: 'Arrest records', value: 'Arrest records;D. Biographic documents' },
      { label: 'Birth Certificates', value: 'Birth Certificates;D. Biographic documents' },
      { label: 'Green Cards', value: 'Green Cards;D. Biographic documents' },
      { label: 'Marriage Certificate', value: 'Marriage Certificate;D. Biographic documents' },
      { label: 'Other documents', value: 'Other documents;D. Biographic documents' },
      { label: 'Passports, I-551 stamp', value: 'Passports, I-551 stamp;D. Biographic documents' },
      { label: 'Tax Returns', value: 'Tax Returns;D. Biographic documents' },
      { label: 'Termination of Prior Marriages', value: 'Termination of Prior Marriages;D. Biographic documents' },
    ]
  },
  {
    label: "E. Forms",
    code: "I824EF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Biographic documents",
    code: "I824FBD",
    items: [
      {
        label: "Birth Certificates",
        value: "Birth Certificates;F. Biographic documents",
      },
      { label: "Main Applicants Green Card", value: "Main Applicants Green Card;F. Biographic documents" },
      { label: "Marriage Certificate", value: "Marriage Certificate;F. Biographic documents" },
      { label: "Passports, visas, I-94s", value: "Passports, visas, I-94s;F. Biographic documents" },
      { label: "Termination of Prior Marriages", value: "Termination of Prior Marriages;F. Biographic documents" },
    ],
  },
  {
    label: "G. Photos",
    code: "I824GP",
    items: [{ label: "G. Photos", value: "G. Photos;G. Photos" }],
  },
  {
    label: "H. Inquiries to USCIS",
    code: "I824HITU",
    items: [{ label: "H. Inquiries to USCIS", value: "H. Inquiries to USCIS;H. Inquiries to USCIS" }],
  },
  {
    label: "I. Inquiries to Ombudsman",
    code: "I824ITTO",
    items: [{ label: "I. Inquiries to Ombudsman", value: "I. Inquiries to Ombudsman;I. Inquiries to Ombudsman" }],
  },
  {
    label: "J. Change of Address",
    code: "I824JCOA",
    items: [{ label: "J. Change of Address", value: "J. Change of Address;J. Change of Address" }],
  },
  {
    label: "K. RFE",
    code: "I824KRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;K. RFE" },
      { label: "RFE Response", value: "RFE Response;K. RFE" },
    ],
  },
  {
    label: "L. NOID",
    code: "I824LNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;L. NOID" },
      { label: "NOID Response", value: "NOID Response;L. NOID" },
    ],
  },
  {
    label: "M. Withdrawal",
    code: "I824MW",
    items: [{ label: "M. Withdrawal", value: "M. Withdrawal;M. Withdrawal" }],
  },
  {
    label: "N. Others",
    code: "I824NO",
    items: [{ label: "N. Others", value: "N. Others;N. Others" }],
  },
];

export const I829 = [
  {
    label: "A. CH files and scans",
    code: "I829ACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "I829BBCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. Application package",
    code: "I829CTBAC",
    items: [
      {
        label: "C. Application package",
        value: "C. Application package;C. Application package",
      },
    ],
  },
  {
    label: "D. Biographic documents",
    code: "I829DBD",
    items: [
      { label: "Green Cards", value: "Green Cards;D. Biographic documents" },
      { label: "Termination of Prior Marriages", value: "Termination of Prior Marriages;D. Biographic documents" },
    ],
  },
  {
    label: "E. Forms",
    code: "I829EF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Photos",
    code: "I829FP",
    items: [{ label: "F. Photos", value: "F. Photos;F. Photos" }],
  },
  {
    label: "G. Inquiries to USCIS",
    code: "I829GITU",
    items: [{ label: "G. Inquiries to USCIS", value: "G. Inquiries to USCIS;G. Inquiries to USCIS" }],
  },
  {
    label: "H. Inquiries to Ombudsman",
    code: "I829HITO",
    items: [{ label: "H. Inquiries to Ombudsman", value: "H. Inquiries to Ombudsman;H. Inquiries to Ombudsman" }],
  },
  {
    label: "I. Change of Address",
    code: "I829GITU",
    items: [{ label: "I. Change of Address", value: "I. Change of Address;I. Change of Address" }],
  },
  {
    label: "J. RFE",
    code: "I829RFE",
    items: [
      { label: "RFE documents", value: "RFE documents;J. RFE" },
      { label: "RFE Response", value: "RFE Response;J. RFE" },
    ],
  },
  {
    label: "K. NOID",
    code: "I829KNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;K. NOID" },
      { label: "NOID Response", value: "NOID Response;K. NOID" },
    ],
  },
  {
    label: "L. Withdrawal",
    code: "I829LW",
    items: [{ label: "L. Withdrawal", value: "L. Withdrawal;L. Withdrawal" }],
  },
  {
    label: "M. Others",
    code: "I829MO",
    items: [{ label: "M. Others", value: "M. Others;M. Others" }],
  },
];

export const L1 =   [
  {
    label: "A. CH files and scans",
    code: "L1ACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "L1BCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. Application package",
    code: "L1CTBAC",
    items: [
      {
        label: "C. Application package",
        value: "C. Application package;C. Application package",
      },
    ],
  },
  {
    label: "D. Translations",
    code: "L1DT",
    items: [
      { label: "1st Batch", value: "1st Batch;D. Translations" },
      { label: "2nd Batch", value: "2nd Batch;D. Translations" },
    ],
  },
  {
    label: "E. Forms",
    code: "L1EF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Biographic documents",
    code: "L1FBD",
    items: [
      {
        label: "Birth Certificates",
        value: "Birth Certificates;F. Biographic documents",
      },
      {
        label: "Change or extensions of status granted",
        value: "Change or extensions of status granted;F. Biographic documents",
      },
      { label: "I-94s", value: "I-94s;F. Biographic documents" },
      {
        label: "Marriage Certificate",
        value: "Marriage Certificate;F. Biographic documents",
      },
      { label: "Passports", value: "Passports;F. Biographic documents" },
      {
        label: "Termination of Prior Marriages",
        value: "Termination of Prior Marriages;F. Biographic documents",
      },
      { label: "Visas", value: "Visas;F. Biographic documents" },
    ],
  },
  {
    label: "G. Academic credentials",
    code: "L1GAC",
    items: [
      {
        label: "Academic evaluation",
        value: "Academic evaluation;G. Academic credentials",
      },
      {
        label: "Bachelors degree",
        value: "Bachelors degree;G. Academic credentials",
      },
      {
        label: "Certificates",
        value: "Certificates;G. Academic credentials",
      },
      {
        label: "Continuing Education",
        value: "Continuing Education;G. Academic credentials",
      },
      {
        label: "Masters and doctorate degrees",
        value: "Masters and doctorate degrees;G. Academic credentials",
      },
      {
        label: "Other documents",
        value: "Other documents;G. Academic credentials",
      },
    ],
  },
  {
    label: "H. Executive or managerial evidence",
    code: "L1HAC",
    items: [
      {
        label: "Curriculum Vitae (CV)",
        value: "Curriculum Vitae (CV);H. Executive or managerial evidence",
      },
      {
        label: "Job description",
        value: "Job description;H. Executive or managerial evidence",
      },
      {
        label: "Other documents",
        value: "Other documents;H. Executive or managerial evidence",
      },
      {
        label: "Pay Stubs",
        value: "Pay Stubs;H. Executive or managerial evidence",
      },
      {
        label: "Personal Income Tax Return",
        value: "Personal Income Tax Return;H. Executive or managerial evidence",
      },
      {
        label: "Work Experience Letters (WELs)",
        value: "Work Experience Letters (WELs);H. Executive or managerial evidence",
      },
    ],
  },
  {
    label: "I. Job Offer in the U.S",
    code: "L1IJOITUS",
    items: [{ label: "I. Job Offer in the U.S", value: "I. Job Offer in the U.S;I. Job Offer in the U.S" }],
  },

  {
    label: "J. US Company",
    code: "L1JUC",
    items: [
      {
        label: "Articles of Incorporation or Organization",
        value: "Articles of Incorporation or Organization;J. US Company",
      },
      {
        label: "Balance Sheet",
        value: "Balance Sheet;J. US Company",
      },
      {
        label: "Bank statements",
        value: "Bank statements;J. US Company",
      },
      {
        label: "Business License",
        value: "Business License;J. US Company",
      },
      {
        label: "Business Plan",
        value: "Business Plan;J. US Company",
      },
      {
        label: "Business transactions (contracts, bills, etc)",
        value: "Business transactions (contracts, bills, etc);J. US Company",
      },
      {
        label: "Bylaws or Operating Agreement",
        value: "Bylaws or Operating Agreement;J. US Company",
      },
      {
        label: "EIN-Tax ID",
        value: "EIN-Tax ID;J. US Company",
      },
      {
        label: "Lease for business commercial space",
        value: "Lease for business commercial space;J. US Company",
      },
      {
        label: "Marketing material",
        value: "Marketing material;J. US Company",
      },
      {
        label: "Membership or Share Certificate",
        value: "Membership or Share Certificate;J. US Company",
      },
      {
        label: "Organizational Chart",
        value: "Organizational Chart;J. US Company",
      },
      {
        label: "Other documents",
        value: "Other documents;J. US Company",
      },
      {
        label: "Photos of the business",
        value: "Photos of the business;J. US Company",
      },
      {
        label: "Profit and Loss (P and L)",
        value: "Profit and Loss (P and L);J. US Company",
      },
      {
        label: "US Corporate Tax Returns",
        value: "US Corporate Tax Returns;J. US Company",
      },
      {
        label: "W-2 and 1099, Forms 941, Payroll Records",
        value: "W-2 and 1099, Forms 941, Payroll Records;J. US Company",
      },
    ],
  },
  {
    label: "K. Foreign Company",
    code: "L1KFC",
    items: [
      {
        label: "Articles of Incorporation or Organization",
        value: "Articles of Incorporation or Organization;K. Foreign Company",
      },
      {
        label: "Balance Sheet",
        value: "Balance Sheet;K. Foreign Company",
      },
      {
        label: "Bank statements",
        value: "Bank statements;K. Foreign Company",
      },
      {
        label: "Business License",
        value: "Business License;K. Foreign Company",
      },
      {
        label: "Business transactions (contracts, bills, etc)",
        value: "Business transactions (contracts, bills, etc);K. Foreign Company",
      },
      {
        label: "Bylaws or Operating Agreement",
        value: "Bylaws or Operating Agreement;K. Foreign Company",
      },
      {
        label: "Corporate Tax or Annual Report",
        value: "Corporate Tax or Annual Report;K. Foreign Company",
      },
      {
        label: "Marketing material",
        value: "Marketing material;K. Foreign Company",
      },
      {
        label: "Membership or Share Certificate",
        value: "Membership or Share Certificate;K. Foreign Company",
      },
      {
        label: "Organizational chart",
        value: "Organizational chart;K. Foreign Company",
      },
      {
        label: "Other documents",
        value: "Other documents;K. Foreign Company",
      },
      {
        label: "Payroll Records",
        value: "Payroll Records;K. Foreign Company",
      },
      {
        label: "Photos of the business",
        value: "Photos of the business;K. Foreign Company",
      },
      {
        label: "Profit and Loss (P and L)",
        value: "Profit and Loss (P and L);K. Foreign Company",
      },
      {
        label: "Relationship between foreign and US entity",
        value: "Relationship between foreign and US entity;K. Foreign Company",
      },
    ],
  },

  {
    label: "L. Consular interview and documents",
    code: "L1LCIAD",
    items: [{ label: "L. Consular interview and documents", value: "L. Consular interview and documents;L. Consular interview and documents" }],
  },
  {
    label: "L. Photos",
    code: "L1LP",
    items: [{ label: "L. Photos", value: "L. Photos;L. Photos" }],
  },
  {
    label: "M. Consular interview and documents",
    code: "L1MCIAD",
    items: [{ label: "M. Consular interview and documents", value: "M. Consular interview and documents;M. Consular interview and documents" }],
  },
  {
    label: "N. RFE",
    code: "L1NRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;N. RFE" },
      { label: "RFE Response", value: "RFE Response;N. RFE" },
    ],
  },
  {
    label: "O. NOID",
    code: "L1ONOID",
    items: [
      { label: "NOID documents", value: "NOID documents;O. NOID" },
      { label: "NOID Response", value: "NOID Response;O. NOID" },
    ],
  },
  {
    label: "P. Withdrawal",
    code: "L1PW",
    items: [{ label: "P. Withdrawal", value: "P. Withdrawal;P. Withdrawal" }],
  },
  {
    label: "Q. Others",
    code: "L1NO",
    items: [{ label: "Q. Others", value: "Q. Others;Q. Others" }],
  },
];

export const N400 =   [
  {
    label: "A. CH files and scans",
    code: "IN400ACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "N400BCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. Application package",
    code: "N400CAP",
    items: [
      {
        label: "C. Application package",
        value: "C. Application package;C. Application package",
      },
    ],
  },
  {
    label: 'D. Biographic documents',
    code: 'N400DBD',
    items: [
      { label: 'Arrest records', value: 'Arrest records;D. Biographic documents' },
      { label: 'Birth Certificates', value: 'Birth Certificates;D. Biographic documents' },
      { label: 'Green Cards', value: 'Green Cards;D. Biographic documents' },
      { label: 'Marriage Certificate', value: 'Marriage Certificate;D. Biographic documents' },
      { label: 'Other documents', value: 'Other documents;D. Biographic documents' },
      { label: 'Passports, I-551 stamp', value: 'Passports, I-551 stamp;D. Biographic documents' },
      { label: 'Tax Returns', value: 'Tax Returns;D. Biographic documents' },
      { label: 'Termination of Prior Marriages', value: 'Termination of Prior Marriages;D. Biographic documents' },
    ]
  },
  {
    label: "E. Forms",
    code: "N400EF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Photos",
    code: "N400FP",
    items: [{ label: "F. Photos", value: "F. Photos;F. Photos" }],
  },
  {
    label: "G. Inquiries to USCIS",
    code: "N400ITU",
    items: [{ label: "G. Inquiries to USCIS", value: "G. Inquiries to USCIS;G. Inquiries to USCIS" }],
  },
  {
    label: "H. Inquiries to Ombudsman",
    code: "N400HITO",
    items: [{ label: "H. Inquiries to Ombudsman", value: "H. Inquiries to Ombudsman;H. Inquiries to Ombudsman" }],
  },
  {
    label: "I. Change of Address",
    code: "N400ICOA",
    items: [{ label: "I. Change of Address", value: "I. Change of Address;I. Change of Address" }],
  },
  {
    label: "J. Documents received from USCIS",
    code: "N400JDRFU",
    items: [{ label: "J. Documents received from USCIS", value: "J. Documents received from USCIS;J. Documents received from USCIS" }],
  },
  {
    label: "K. RFE",
    code: "N400KRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;K. RFE" },
      { label: "RFE Response", value: "RFE Response;K. RFE" },
    ],
  },
  {
    label: "L. NOID",
    code: "N400LNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;L. NOID" },
      { label: "NOID Response", value: "NOID Response;L. NOID" },
    ],
  },
  {
    label: "M. Withdrawal",
    code: "N400MW",
    items: [{ label: "M. Withdrawal", value: "M. Withdrawal;M. Withdrawal" }],
  },
  {
    label: "N. Others",
    code: "N400NO",
    items: [{ label: "N. Others", value: "N. Others;N. Others" }],
  },
];

export const O1 =  [
  {
    label: "A. CH files and scans",
    code: "O1ACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)",
        value:
          "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "O1BCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. TOC, Binder, and Checklist",
    code: "N1CTBAC",
    items: [
      {
        label: "C. TOC, Binder, and Checklist",
        value: "C. TOC, Binder, and Checklist;C. TOC, Binder, and Checklist",
      },
    ],
  },
  {
    label: "D. Translations",
    code: "O1DT",
    items: [
      { label: "1st Batch", value: "1st Batch;D. Translations" },
      { label: "2nd Batch", value: "2nd Batch;D. Translations" },
    ],
  },
  {
    label: "E. Forms",
    code: "O1EF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Biographic documents",
    code: "O1FBD",
    items: [
      {
        label: "Birth Certificates",
        value: "Birth Certificates;F. Biographic documents",
      },
      {
        label: "Marriage Certificate",
        value: "Marriage Certificate;F. Biographic documents",
      },
      { label: "Passports, visas, I-94s", value: "Passports, visas, I-94s;F. Biographic documents" },
      {
        label: "Termination of Prior Marriages",
        value: "Termination of Prior Marriages;F. Biographic documents",
      },
    ],
  },
  {
    label: "G. Academic credentials",
    code: "O1GAC",
    items: [
      {
        label: "Academic evaluation",
        value: "Academic evaluation;G. Academic credentials",
      },
      {
        label: "Bachelors degree",
        value: "Bachelors degree;G. Academic credentials",
      },
      {
        label: "Certificates",
        value: "Certificates;G. Academic credentials",
      },
      {
        label: "Continuing Education",
        value: "Continuing Education;G. Academic credentials",
      },
      {
        label: "Curriculum Vitae (CV)",
        value: "Curriculum Vitae (CV);G. Academic credentials",
      },
      {
        label: "Masters and doctorate degrees",
        value: "Masters and doctorate degrees;G. Academic credentials",
      },
      {
        label: "Other documents",
        value: "Other documents;G. Academic credentials",
      },
    ],
  },
  {
    label: "H. Work Experience",
    code: "O1HWE",
    items: [
      {
        label: "Curriculum Vitae (CV)",
        value: "Curriculum Vitae (CV);H. Work Experience",
      },
      {
        label: "Other documents",
        value:
          "Other documents;H. Work Experience",
      },
      {
        label: "Work Experience Letters (WELs)",
        value: "Work Experience Letters (WELs);H. Work Experience",
      },
    ],
  },
  {
    label: "I. Extraordinary Ability",
    code: "O1IEA",
    items: [
      {
        label: "Artistic Exhibitions or Showcases",
        value: "Artistic Exhibitions or Showcases;I. Extraordinary Ability",
      },
      {
        label: "High Remuneration",
        value: "High Remuneration;I. Extraordinary Ability",
      },
      {
        label: "Invited Talks, Review, and Judging Record",
        value: "Invited Talks, Review, and Judging Record;I. Extraordinary Ability",
      },
      {
        label: "Major Contribution to the Field",
        value: "Major Contribution to the Field;I. Extraordinary Ability",
      },
      {
        label: "Media and Publication Recognition",
        value: "Media and Publication Recognition;I. Extraordinary Ability",
      },
      {
        label: "Memberships",
        value: "Memberships;I. Extraordinary Ability",
      },
      {
        label: "Other documents",
        value: "Other documents;I. Extraordinary Ability",
      },
      {
        label: "Performance in a Leading or Major Role",
        value: "Performance in a Leading or Major Role;I. Extraordinary Ability",
      },
      {
        label: "Prizes or Awards",
        value: "Prizes or Awards;I. Extraordinary Ability",
      },
      {
        label: "Published Material and Citations",
        value: "Published Material and Citations;I. Extraordinary Ability",
      },
    ],
  },
  {
    label: "J. Job Offer in the U.S",
    code: "O1JOITUS",
    items: [
      {
        label: "J. Job Offer in the U.S",
        value: "J. Job Offer in the U.S;J. Job Offer in the U.S",
      },
   
    ],
  },
  {
    label: "K. US Petitioners documents",
    code: "O1KUSPD",
    items: [
      {
        label: "Articles of Incorporation or Organization",
        value: "Articles of Incorporation or Organization;K. US Petitioners documents",
      },
      {
        label: "Balance Sheet",
        value: "Balance Sheet;K. US Petitioners documents",
      },
      {
        label: "Companys description",
        value: "Companys description;K. US Petitioners documents",
      },
      {
        label: "Marketing material",
        value: "Marketing material;K. US Petitioners documents",
      },
      {
        label: "Membership or Share Certificate",
        value: "Membership or Share Certificate;K. US Petitioners documents",
      },
      {
        label: "Organizational Chart",
        value: "Organizational Chart;K. US Petitioners documents",
      },
      {
        label: "Other documents",
        value: "Other documents;K. US Petitioners documents",
      },
      {
        label: "Photos of the business",
        value: "Photos of the business;K. US Petitioners documents",
      },
      {
        label: "Profit and Loss (P and L)",
        value: "Profit and Loss (P and L);K. US Petitioners documents",
      },

      {
        label: "US Corporate Tax Return",
        value: "US Corporate Tax Return;K. US Petitioners documents",
      },
    ],
  },
  {
    label: "L. Writers",
    code: "O1LW",
    items: [{ label: "L. Writers", value: "L. Writers;L. Writers" }],
  },
  {
    label: "M. RFE",
    code: "O1MRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;M. RFE" },
      { label: "RFE Response", value: "RFE Response;M. RFE" },
    ],
  },
  {
    label: "N. NOID",
    code: "O1NNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;N. NOID" },
      { label: "NOID Response", value: "NOID Response;N. NOID" },
    ],
  },
  {
    label: "O. Withdrawal",
    code: "O1OW",
    items: [{ label: "O. Withdrawal", value: "O. Withdrawal;O. Withdrawal" }],
  },
  {
    label: "P. Others",
    code: "O1PO",
    items: [{ label: "P. Others", value: "P. Others;P. Others" }],
  },
];

export const TN =   [
  {
    label: "A. CH files and scans",
    code: "THACHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      {
        label: "From Onboarding",
        value: "From Onboarding;A. CH files and scans",
      },
      {
        label: "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)",
        value: "Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial);A. CH files and scans",
      },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "TNBCUF",
    items: [
      {
        label: "B. Client uploaded files",
        value: "B. Client uploaded files;B. Client uploaded files",
      },
    ],
  },
  {
    label: "C. TOC, Binder, and Checklist",
    code: "TNCTOCBAC",
    items: [
      {
        label: "C. TOC, Binder, and Checklist",
        value: "C. TOC, Binder, and Checklist;C. TOC, Binder, and Checklist",
      },
    ],
  },
  {
    label: "D. Translations",
    code: "TNDT",
    items: [
      { label: "1st Batch", value: "1st Batch;D. Translations" },
      { label: "2nd Batch", value: "2nd Batch;D. Translations" },
    ],
  },
  {
    label: "E. Forms",
    code: "TNEF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. Biographic documents",
    code: "TNFBD",
    items: [
      {
        label: "Birth Certificates",
        value: "Birth Certificates;F. Biographic documents",
      },
      { label: "Change or extensions of status granted", value: "Change or extensions of status granted;F. Biographic documents" },
      { label: "I-94s", value: "I-94s;F. Biographic documents" },
      { label: "Marriage Certificate", value: "Marriage Certificate;F. Biographic documents" },
      { label: "Passports", value: "Passports;F. Biographic documents" },
      { label: "Termination of Prior Marriages", value: "Termination of Prior Marriages;F. Biographic documents" },
      { label: "Visas", value: "Visas;F. Biographic documents" },
    ],
  },
  {
    label: "G. Beneficiary qualifications (degrees, certificates)",
    code: "TNGBQDC",
    items: [
      { label: "Awards and recognitions", value: "Awards and recognitions;G. Beneficiary qualifications (degrees, certificates)", },
      { label: "Certificates", value: "Certificates;G. Beneficiary qualifications (degrees, certificates)" },
      { label: "Curriculum Vitae (CV)", value: "Curriculum Vitae (CV);G. Beneficiary qualifications (degrees, certificates)" },
      { label: "Diplomas", value: "Diplomas;G. Beneficiary qualifications (degrees, certificates)" },
      { label: "Licenses", value: "Licenses;G. Beneficiary qualifications (degrees, certificates)" },
      { label: "Other documents", value: "Other documents;G. Beneficiary qualifications (degrees, certificates)" },
      { label: "Publications", value: "Publications;G. Beneficiary qualifications (degrees, certificates)" },
      { label: "Work experience", value: "Work experience;G. Beneficiary qualifications (degrees, certificates)" },
    ],
  },
  {
    label: "H. Job Offer in the U.S",
    code: "TNHJOITUS",
    items: [{ label: "H. Job Offer in the U.S", value: "H. Job Offer in the U.S;H. Job Offer in the U.S" }],
  },
  {
    label: "I. Consular Interview and documents",
    code: "TNICIAD",
    items: [{ label: "I. Consular Interview and documents", value: "I. Consular Interview and documents;I. Consular Interview and documents" }],
  },
  {
    label: "J. Writers",
    code: "TNJW",
    items: [{ label: "J. Writers", value: "J. Writers;J. Writers" }],
  },
  {
    label: "K. RFE",
    code: "TNKRFE",
    items: [
      { label: "RFE documents", value: "RFE documents;K. RFE" },
      { label: "RFE Response", value: "RFE Response;K. RFE" },
    ],
  },
  {
    label: "L. NOID",
    code: "TNLNOID",
    items: [
      { label: "NOID documents", value: "NOID documents;L. NOID" },
      { label: "NOID Response", value: "NOID Response;L. NOID" },
    ],
  },
  {
    label: "M. Withdrawal",
    code: "TNMW",
    items: [{ label: "M. Withdrawal", value: "M. Withdrawal;M. Withdrawal" }],
  },
  {
    label: "N. Others",
    code: "TNNO",
    items: [{ label: "N. Others", value: "N. Others;N. Others" }],
  },
];

export const WRITE_OF_MANDAMUS =   [
  {
    label: "A. CH files and scans",
    code: "WOMACAHFAS",
    items: [
      { label: "Filing Fee", value: "Filing Fee;A. CH files and scans" },
      { label: "From Onboarding", value: "From Onboarding;A. CH files and scans" },
      { label: "Notices from USCIS (Receipt, Approval, Denial)", value: "Notices from USCIS (Receipt, Approval, Denial);A. CH files and scans" },
    ],
  },
  {
    label: "B. Client uploaded files",
    code: "WOMBCUF",
    items: [{ label: "B. Client uploaded files", value: "B. Client uploaded files;B. Client uploaded files" }],
  },
  {
    label: "C. Filings",
    code: "WOMCF",
    items: [{ label: "C. Filings", value: "C. Filings;C. Filings" }],
  },
  {
    label: "D. Court documents",
    code: "WOMDCD",
    items: [{ label: "D. Court documents", value: "D. Court documents;D. Court documents" }],
  },
  {
    label: "E. Forms",
    code: "WOMEF",
    items: [{ label: "E. Forms", value: "E. Forms;E. Forms" }],
  },
  {
    label: "F. AOS",
    code: "WOMFAOS",
    items: [{ label: "F. AOS", value: "F. AOS;F. AOS" }],
  },
  {
    label: "G. I-526",
    code: "WOMGI",
    items: [{ label: "G. I-526", value: "G. I-526;G. I-526" }],
  },
  {
    label: "H. I-829",
    code: "WOMHI",
    items: [{ label: "H. I-829", value: "H. I-829;H. I-829" }],
  },
  {
    label: "I. Others",
    code: "WOMIO",
    items: [{ label: "I. Others", value: "I. Others;I. Others" }],
  },
];
// ------------ Folders End Here ----------------

export const manipulateFolderSelectionCommon = (selectedCaseFolder: any) => {
  switch (selectedCaseFolder) {
    case "AOS": 
      return AOS_FOLDERS
    case "CP":
      return CP_Folders
    case "E-1":
      return E1
    case "E-2":
      return E2     
    case "E-3":
      return E3
    case "EB-1A":
      return EB1A
    case "EB-1B":
      return EB1B
    case "EB-1C":
      return EB1C
    case "EB-2":
      return EB2
    case "EB-2 NIW":
      return EB2_NIW_FOLDERS
    case "EB-3":
      return EB3
    case "EB-5":
      return EB5
    case "H1-B":
      return H1B
    case "I-90":
      return I90
    case "I-131":
      return I131
    case "I-290B":
      return I290B
    case "I-765":
      return I765  
    case "I-824":
      return I824  
    case "I-829":
      return I829  
    case "L-1":
      return L1
    case "N-400":
      return N400
    case "O-1":
      return O1
    case "TN":
      return TN  
    case "Writ of Mandamus":
      return WRITE_OF_MANDAMUS
    default:
      return EB2_NIW_FOLDERS
  }
}

export const APPLICANT_NOT_SELECTED = "Please select atleast one applicant.";
export const DOC_CHK_LIST_NOT_SELECTED = "Please select atleast one document checklist item.";
export const UNSAVED_CHANGES_DOC_CHKLIST = "There are unsaved changes in selected checklist items. Please ensure you made required changes before clicking on submit button.";

// export const CASE_FOLDER_STRUCTURE = [
//   { label: "AOS", value: "AOS" },
//   { label: "CP", value: "CP" },
//   { label: "EB-2 NIW", value: "EB-2 NIW" },
//   { label: "EB-5", value: "EB-5" },
//   { label: "Writ of Mandamus", value: "Writ of Mandamus" },
//   { label: "E-2", value: "E-2" },
//   { label: "EB-1", value: "EB-1" },
//   { label: "EB-1A", value: "EB-1A" },
//   { label: "EB-1B", value: "EB-1B" }
// ];

export const CASE_FOLDER_STRUCTURE = [
  { label: "AOS", value: "AOS" },
  { label: "CP", value: "CP" },
  { label: "E-1", value: "E-1" }, //New
  { label: "E-2", value: "E-2" },
  { label: "E-3", value: "E-3" }, //New
  { label: "EB-1A", value: "EB-1A" },
  { label: "EB-1B", value: "EB-1B" },
  { label: "EB-1C", value: "EB-1C" }, // New
  { label: "EB-2", value: "EB-2" }, // New
  { label: "EB-2 NIW", value: "EB-2 NIW" },
  { label: "EB-3", value: "EB-3" }, // New
  { label: "EB-5", value: "EB-5" },
  { label: "H1-B", value: "H1-B" }, // New
  { label: "I-90", value: "I-90" }, // New
  { label: "I-131", value: "I-131" }, // New
  { label: "I-290B", value: "I-290B" }, // New
  { label: "I-765", value: "I-765" }, // New
  { label: "I-824", value: "I-824" }, // New
  { label: "I-829", value: "I-829" }, // New
  { label: "L-1", value: "L-1" }, // New
  { label: "N-400", value: "N-400" }, // New
  { label: "O-1", value: "O-1" }, // New
  { label: "TN", value: "TN" }, // New
  { label: "Writ of Mandamus", value: "Writ of Mandamus" },
  // { label: "EB-1", value: "EB-1" }, // Deleted
];

export const CASE_DOCUMENT_ACTION = {
  Requested: {
    title: "Request Document ",
    body: "Are you sure, you want to Request the Document?"
  },
  ReRequested: {
    title: "Re-Request Document ",
    body: "Are you sure, you want to Re-Request the Document?"
  },
  Received: {
    title: "Mark document as Received",
    body: "Are you sure, you want to mark the document as Received?"
  },
  Approve: {
    title: "Mark document as Approve",
    body: "Are you sure, you want to mark the document as Approved?"
  },
  Sign: {
    title: "Mark document as Signed",
    body: "Are you sure, you want to mark the document as Signed?"
  },
  ReRequestAll: {
    title: "Request Documents",
    body: "Are you sure, you want to Mark Requested, all the selected documents?"
  }
}

export const CASE_FORM_ACTION = {
  Sent: {
    title: "Send form ",
    body: "Are you sure, you want to Send the form?"
  },
  Approve: {
    title: "Mark form as Approve",
    body: "Are you sure, you want to mark the form as Approved?"
  },
  Sign: {
    title: "Mark form as Signed",
    body: "Are you sure, you want to mark the form as Signed?"
  }
}

export const VISA_CATEGORY_OPTIONS = [
  { label: "Employment - 1st", value: "Employment - 1st" },
  { label: "Employment - 2nd", value: "Employment - 2nd" },
  { label: "Employment - 3rd", value: "Employment - 3rd" },
  { label: "Employment - 4th", value: "Employment - 4th" },
  { label: "Employment - Other Workers", value: "Employment - Other Workers" },
  { label: "5th Non-Regional Center (C5 and T5)", value: "5th Non-Regional Center (C5 and T5)" },
  { label: "5th Regional Center (I5 and R5)", value: "5th Regional Center (I5 and R5)" },
  { label: "Family Based", value: "Family Based" },
];


export const RECEIVED_FROM_OPTIONS = [
  { label: "USCIS – California Service Center", value: "USCIS – California Service Center" },
  { label: "USCIS – Nebraska Service Center", value: "USCIS – Nebraska Service Center" },
  { label: "USCIS – Potomac Service Center", value: "USCIS – Potomac Service Center" },
  { label: "USCIS – Texas Service Center", value: "USCIS – Texas Service Center" },
  { label: "USCIS – Vermont Service Center", value: "USCIS – Vermont Service Center" },
  { label: "USCIS Field Office", value: "USCIS Field Office" },
  { label: "USCIS Asylum Office", value: "USCIS Asylum Office" },
  { label: "HART Service Center", value: "HART Service Center" },
  { label: "US Department of State", value: "US Department of State" },
  { label: "DOL - Department of Labor", value: "DOL - Department of Labor" },
  { label: "NBC - National Benefits Center", value: "NBC - National Benefits Center" },
  { label: "NVC - National Visa Center", value: "NVC - National Visa Center" },
  { label: "SWA – State Workforce Agency", value: "SWA – State Workforce Agency" },
  { label: "Other", value: "Other" },
];

export const NOTICE_TYPE_OPTIONS = [
  { label: "Receipt Notice", value: "Receipt Notice" },
  { label: "RFE Notice", value: "RFE Notice" },
  { label: "NOID Notice", value: "NOID Notice" },
  { label: "Approval Notice", value: "Approval Notice" },
  { label: "Withdrawal Decision", value: "Withdrawal Decision" },
  { label: "Denial Decision", value: "Denial Decision" },
  { label: "Transfer Notice", value: "Transfer Notice" },
  { label: "Biometrics Notice", value: "Biometrics Notice" },
  { label: "Interview Notice", value: "Interview Notice" },
  { label: "NVC Notice", value: "NVC Notice" },
  { label: "NVC Invoice", value: "NVC Invoice" },
  { label: "IV Fee Payment Receipt", value: "IV Fee Payment Receipt" },
  { label: "AOS Fee Payment Receipt", value: "AOS Fee Payment Receipt" },
  { label: "NVC RFE", value: "NVC RFE" },
  { label: "NVC Documentarily Complete", value: "NVC Documentarily Complete" },
  { label: "Termination Letter", value: "Termination Letter" },
  { label: "Revocation Notice", value: "Revocation Notice" },
  { label: "AA Confirmation Number", value: "AA Confirmation Number" },
  { label: "Perm Prevailing Wage", value: "Perm Prevailing Wage" },
  { label: "Other", value: "Other" },
];

export const RECEIPT_STATUS_OPTIONS = [
  { label: "Pending", value: "Pending" },
  { label: "RFE", value: "RFE" },
  { label: "NOID", value: "NOID" },
  { label: "Withdrawn", value: "Withdrawn" },
  { label: "Denied", value: "Denied" },
  { label: "Approved", value: "Approved" },
  { label: "Refile", value: "Refile" },
  { label: "Appeal", value: "Appeal" },
];

export const NOTIFY_ON_OPTIONS = [
  { label: "Both", value: "both" },
  { label: "Email", value: "email" },
  { label: "Bell", value: "bell" },
];

export const REMINDER_STATUS_OPTIONS = [
  { label: "Status: All", value: "" },
  { label: "Completed", value: "Completed" },
  { label: "Upcoming", value: "Upcoming" },
  { label: "Overdue", value: "Overdue" },
];

export const REMINDER_TIME_OPTIONS = [
  { label: "08:00:00", value: "08:00:00" },
  { label: "09:00:00", value: "09:00:00" },
  { label: "10:00:00", value: "10:00:00" },
  { label: "11:00:00", value: "11:00:00" },
  { label: "12:00:00", value: "12:00:00" },
  { label: "13:00:00", value: "13:00:00" },
  { label: "14:00:00", value: "14:00:00" },
  { label: "15:00:00", value: "15:00:00" },
  { label: "16:00:00", value: "16:00:00" },
  { label: "17:00:00", value: "17:00:00" },
  { label: "18:00:00", value: "18:00:00" },
  { label: "19:00:00", value: "19:00:00" },
  { label: "20:00:00", value: "20:00:00" },
];

export const YES_NO_OPTIONS = [
  { label: "Select", value: "" },
  { label: "Yes", value: true },
  { label: "No", value: false }
];
export const EVENT_TYPES_OPTIONS = [
  { label: "Appointment", value: "Appointment" },
  { label: "Court Date", value: "Court Date" },
  { label: "Interview", value: "Interview" },
  { label: "Meeting", value: "Meeting" },
  { label: "Other", value: "Other" }
];

export const EVENTS_TIME_OPTIONS = [
  { "label": "06:00:00", "value": "06:00:00" },
  { "label": "06:30:00", "value": "06:30:00" },
  { "label": "07:00:00", "value": "07:00:00" },
  { "label": "07:30:00", "value": "07:30:00" },
  { "label": "08:00:00", "value": "08:00:00" },
  { "label": "08:30:00", "value": "08:30:00" },
  { "label": "09:00:00", "value": "09:00:00" },
  { "label": "09:30:00", "value": "09:30:00" },
  { "label": "10:00:00", "value": "10:00:00" },
  { "label": "10:30:00", "value": "10:30:00" },
  { "label": "11:00:00", "value": "11:00:00" },
  { "label": "11:30:00", "value": "11:30:00" },
  { "label": "12:00:00", "value": "12:00:00" },
  { "label": "12:30:00", "value": "12:30:00" },
  { "label": "13:00:00", "value": "13:00:00" },
  { "label": "13:30:00", "value": "13:30:00" },
  { "label": "14:00:00", "value": "14:00:00" },
  { "label": "14:30:00", "value": "14:30:00" },
  { "label": "15:00:00", "value": "15:00:00" },
  { "label": "15:30:00", "value": "15:30:00" },
  { "label": "16:00:00", "value": "16:00:00" },
  { "label": "16:30:00", "value": "16:30:00" },
  { "label": "17:00:00", "value": "17:00:00" },
  { "label": "17:30:00", "value": "17:30:00" },
  { "label": "18:00:00", "value": "18:00:00" },
  { "label": "18:30:00", "value": "18:30:00" },
  { "label": "19:00:00", "value": "19:00:00" },
  { "label": "19:30:00", "value": "19:30:00" },
  { "label": "20:00:00", "value": "20:00:00" },
  { "label": "20:30:00", "value": "20:30:00" },
  { "label": "21:00:00", "value": "21:00:00" },
  { "label": "21:30:00", "value": "21:30:00" },
  { "label": "22:00:00", "value": "22:00:00" }
]

// Reason for Closure Options
export const REASON_FOR_CLOUSURE_OPTIONS = [
  { label: "Approved", value: "Approved" },
  { label: "Denied", value: "Denied" },
  { label: "MTR", value: "MTR" },
  { label: "Refile", value: "Refile" },
  { label: "Withdrawn", value: "Withdrawn" },
  { label: "Termination of Representation", value: "Termination of Representation" }
]

export const STATE_OPTIONS = [
  {
    label: "AA",
    value: "AA",
  },
  {
    label: "AE",
    value: "AE",
  },
  {
    label: "AK",
    value: "AK",
  },
  {
    label: "AL",
    value: "AL",
  },
  {
    label: "AP",
    value: "AP",
  },
  {
    label: "AR",
    value: "AR",
  },
  {
    label: "AS",
    value: "AS",
  },
  {
    label: "AZ",
    value: "AZ",
  },
  {
    label: "CA",
    value: "CA",
  },
  {
    label: "CO",
    value: "CO",
  },
  {
    label: "CT",
    value: "CT",
  },
  {
    label: "DC",
    value: "DC",
  },
  {
    label: "DE",
    value: "DE",
  },
  {
    label: "FL",
    value: "FL",
  },
  {
    label: "FM",
    value: "FM",
  },
  {
    label: "GA",
    value: "GA",
  },
  {
    label: "GU",
    value: "GU",
  },
  {
    label: "HI",
    value: "HI",
  },
  {
    label: "IA",
    value: "IA",
  },
  {
    label: "ID",
    value: "ID",
  },
  {
    label: "IL",
    value: "IL",
  },
  {
    label: "IN",
    value: "IN",
  },
  {
    label: "KS",
    value: "KS",
  },
  {
    label: "KY",
    value: "KY",
  },
  {
    label: "LA",
    value: "LA",
  },
  {
    label: "MA",
    value: "MA",
  },
  {
    label: "MD",
    value: "MD",
  },
  {
    label: "ME",
    value: "ME",
  },
  {
    label: "MH",
    value: "MH",
  },
  {
    label: "MI",
    value: "MI",
  },
  {
    label: "MN",
    value: "MN",
  },
  {
    label: "MO",
    value: "MO",
  },
  {
    label: "MP",
    value: "MP",
  },
  {
    label: "MS",
    value: "MS",
  },
  {
    label: "MT",
    value: "MT",
  },
  {
    label: "NC",
    value: "NC",
  },
  {
    label: "ND",
    value: "ND",
  },
  {
    label: "NE",
    value: "NE",
  },
  {
    label: "NH",
    value: "NH",
  },
  {
    label: "NJ",
    value: "NJ",
  },
  {
    label: "NM",
    value: "NM",
  },
  {
    label: "NV",
    value: "NV",
  },
  {
    label: "NY",
    value: "NY",
  },
  {
    label: "OH",
    value: "OH",
  },
  {
    label: "OK",
    value: "OK",
  },
  {
    label: "OR",
    value: "OR",
  },
  {
    label: "PA",
    value: "PA",
  },
  {
    label: "PR",
    value: "PR",
  },
  {
    label: "PW",
    value: "PW",
  },
  {
    label: "RI",
    value: "RI",
  },
  {
    label: "SC",
    value: "SC",
  },
  {
    label: "SD",
    value: "SD",
  },
  {
    label: "TN",
    value: "TN",
  },
  {
    label: "TX",
    value: "TX",
  },
  {
    label: "UT",
    value: "UT",
  },
  {
    label: "VA",
    value: "VA",
  },
  {
    label: "VI",
    value: "VI",
  },
  {
    label: "VT",
    value: "VT",
  },
  {
    label: "WA",
    value: "WA",
  },
  {
    label: "WI",
    value: "WI",
  },
  {
    label: "WV",
    value: "WV",
  },
  {
    label: "WY",
    value: "WY",
  },
];

// Settings fields mapped with Mapping of Organistions
export const QUESTIONNAIRE_QUESTIONS_OPTIONS = [
  { label: "ID", value: "ID" },
  { label: "Name", value: "Name" },
  { label: "Tax ID", value: "Tax ID" },
  { label: "Website", value: "Website" },
  { label: "Contact Name", value: "Contact Name" },
  { label: "Firm Name on Form", value: "Firm Name and Form" },

  { label: "Enabled Address - Full Address", value: "Enabled Address - Full Address" },
  { label: "Enabled Address - Phone Number", value: "Enabled Address - Phone Number" },
  { label: "Enabled Address - Fax Number", value: "Enabled Address - Fax Number" },
  { label: "Enabled Address - Mobile Number", value: "Enabled Address - Mobile Number" },
  { label: "Enabled Address - Email", value: "Enabled Address - Email" },
  { label: "Enabled Address - Street Number and Name", value: "Enabled Address - Street Number and Name" }, // New
  { label: "Enabled Address - Apt. Ste. Flr. Selection",value: "Enabled Address - Apt. Ste. Flr. Selection", valueObj: {option_values: [{label:"Apt." , value:"Apt." },{label:"Ste." , value:"Ste." },{label:"Flr." , value:"Flr." }], question_type:"Checkbox"}  }, // New
  { label: "Enabled Address - Apt. Ste. Flr. Number", value: "Enabled Address - Apt. Ste. Flr. Number" }, // New
  { label: "Enabled Address - State", value: "Enabled Address - State" }, // New
  { label: "Enabled Address - Zip Code/Post Code", value: "Enabled Address - Zip Code/Post Code" }, // New
  { label: "Enabled Address - Province", value: "Enabled Address - Province" }, // New
  { label: "Enabled Address - City", value: "Enabled Address - City" }, // New
  { label: "Enabled Address - Country", value: "Enabled Address - Country" }, // New

  { label: "Main Address on Forms - Full Address", value: "Main Address on Forms - Full Address" },
  { label: "Main Address on Forms - Phone Number", value: "Main Address on Forms - Phone Number" },
  { label: "Main Address on Forms - Fax Number", value: "Main Address on Forms - Fax Number" },
  { label: "Main Address on Forms - Mobile Number", value: "Main Address on Forms - Mobile Number" },
  { label: "Main Address on Forms - Email", value: "Main Address on Forms - Email" },
  { label: "Main Address on Forms - Street Number and Name", value: "Main Address on Forms - Street Number and Name" }, // New
  { label: "Main Address on Forms - Apt. Ste. Flr. Selection", value: "Main Address on Forms - Apt. Ste. Flr. Selection",  valueObj: {option_values: [{label:"Apt." , value:"Apt." },{label:"Ste." , value:"Ste." },{label:"Flr." , value:"Flr." }], question_type:"Checkbox"} }, // New
  { label: "Main Address on Forms - Apt. Ste. Flr. Number", value: "Main Address on Forms - Apt. Ste. Flr. Number" }, // New
  { label: "Main Address on Forms - State", value: "Main Address on Forms - State" }, // New
  { label: "Main Address on Forms - Zip Code/Post Code", value: "Main Address on Forms - Zip Code/Post Code" }, // New
  { label: "Main Address on Forms - Province", value: "Main Address on Forms - Province" }, // New
  { label: "Main Address on Forms - City", value: "Main Address on Forms - City" }, // New
  { label: "Main Address on Forms - Country", value: "Main Address on Forms - Country" }, // New

  { label: "Office Address on Forms - Full Address", value: "Office Address on Forms - Full Address" },
  { label: "Office Address on Forms - Phone Number", value: "Office Address on Forms - Phone Number" },
  { label: "Office Address on Forms - Fax Number", value: "Office Address on Forms - Fax Number" },
  { label: "Office Address on Forms - Mobile Number", value: "Office Address on Forms - Mobile Number" },
  { label: "Office Address on Forms - Email", value: "Office Address on Forms - Email" },
  { label: "Office Address on Forms - Street Number and Name", value: "Office Address on Forms - Street Number and Name" }, // New
  { label: "Office Address on Forms - Apt. Ste. Flr. Selection", value: "Office Address on Forms - Apt. Ste. Flr. Selection",  valueObj: {option_values: [{label:"Apt." , value:"Apt." },{label:"Ste." , value:"Ste." },{label:"Flr." , value:"Flr." }], question_type:"Checkbox"} }, // New
  { label: "Office Address on Forms - Apt. Ste. Flr. Number", value: "Office Address on Forms - Apt. Ste. Flr. Number" }, // New
  { label: "Office Address on Forms - State", value: "Office Address on Forms - State" }, // New
  { label: "Office Address on Forms - Zip Code/Post Code", value: "Office Address on Forms - Zip Code/Post Code" }, // New
  { label: "Office Address on Forms - Province", value: "Office Address on Forms - Province" }, // New
  { label: "Office Address on Forms - City", value: "Office Address on Forms - City" }, // New
  { label: "Office Address on Forms - Country", value: "Office Address on Forms - Country" }, // New
  
  { label: "Alternative Address on Forms - Full Address", value: "Alternative Address on Forms - Full Address" },
  { label: "Alternative Address on Forms - Phone Number", value: "Alternative Address on Forms - Phone Number" },
  { label: "Alternative Address on Forms - Fax Number", value: "Alternative Address on Forms - Fax Number" },
  { label: "Alternative Address on Forms - Mobile Number", value: "Alternative Address on Forms - Mobile Number" },
  { label: "Alternative Address on Forms - Email", value: "Alternative Address on Forms - Email" },
  { label: "Alternative Address on Forms - Street Number and Name", value: "Alternative Address on Forms - Street Number and Name" }, // New
  { label: "Alternative Address on Forms - Apt. Ste. Flr. Selection", value: "Alternative Address on Forms - Apt. Ste. Flr. Selection",  valueObj: {option_values: [{label:"Apt." , value:"Apt." },{label:"Ste." , value:"Ste." },{label:"Flr." , value:"Flr." }], question_type:"Checkbox"} }, // New
  { label: "Alternative Address on Forms - Apt. Ste. Flr. Number", value: "Alternative Address on Forms - Apt. Ste. Flr. Number" }, // New
  { label: "Alternative Address on Forms - State", value: "Alternative Address on Forms - State" }, // New
  { label: "Alternative Address on Forms - Zip Code/Post Code", value: "Alternative Address on Forms - Zip Code/Post Code" }, // New
  { label: "Alternative Address on Forms - Province", value: "Alternative Address on Forms - Province" }, // New
  { label: "Alternative Address on Forms - City", value: "Alternative Address on Forms - City" }, // New
  { label: "Alternative Address on Forms - Country", value: "Alternative Address on Forms - Country" }, // New

  { label: "Terms of use", value: "Terms of use" }
];

// DASHBOARD RECENTLY VISITED OPTIONS
export const RECENTLY_VISITED_OPTIONS = [
  { label: "All", value: "" },
  { label: "Case", value: "Case" },
  { label: "Client", value: "Client" },
];

// DASHBOARD TODAY WEEKLY MONTHLY OPTIONS
export const TODAY_WEEK_MONTH_OPTIONS = [
  { label: "Today", value: "" },
  { label: "This Week", value: "Weekly" },
  { label: "This Month", value: "Monthly" },
];

// DASHBOARD MONTH QUATER YEAR OPTIONS
export const MONTH_QUATER_YEAR_OPTIONS = [
  { label: "Monthly", value: "month" },
  { label: "Quarterly", value: "quarter" },
  { label: "Yearly", value: "year" },
];


// DASHBOARD QUATER HALLYYEARL YEAR OPTIONS
export const QUATER_HALFYEARLY_YEAR_OPTIONS = [
  { label: "Quarterly", value: "quarter" },
  { label: "Half Yearly", value: "halfYear" },
  { label: "Yearly", value: "year" },
];

// DASHBOARD STAGE CASE PROGRESS COLOUR
// export const STAGE_CASE_PROGRESS_COLOURS = ["green", "red", "yellow", "pink", "purple", "orange", "blue", "Plum", "Wheat", "Turquoise", "Peru", "Teal", "Maroon","black", "Magenta", "Indigo"];
export const STAGE_CASE_PROGRESS_COLOURS = ["#052865", "#B5222E", "#E1C233", "#80BD67", "#E66C37", "#AFA46E", "#C1D3F6", "#021B44", "#E74C3C", "#F9E79F", "#276747", "#FFA07A", "#BDC3C7", "#16A085", "#8E44AD", "#FFC0CB", "#87CEEB", "#7D3C98" ];

// STATUS ACTIVE INACTIVE
export const STATUS_ACTIVE_INACTIVE_OPTIONS = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];