import { FC } from "react";
import { Button, Modal, Stack } from "react-bootstrap";

interface ILogoutHandleModal {
  show?: boolean;
  handleClose?: any;
}

/**
 * Rename Step Name Modal Component
 * @date 12/18/2023 - 12:11:19 PM
 *
 * @param {{ show: any; handleClose: any; }} {
  show,
  handleClose,
}
 * @returns {*}
 */

const LogoutHandleModal: FC<ILogoutHandleModal> = ({
  show,
  handleClose,
}) => {

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered={true}
      scrollable={true}
      size="sm"
      className="theme-modal"
    >
        <Modal.Header>
            <Modal.Title as="h6" className="fw-semibold">
                Unauthorised Access
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3 pb-2 min-h-100">
            Either your account has not been created yet on CHWizard, or you have logged in from another browser.
        </Modal.Body>
        <Modal.Footer className="py-2">
            <Stack direction="horizontal" gap={3} className="flex-wrap py-1">
            <Button
                type="submit"
                variant="danger"
                className="min-w-80"
                onClick={handleClose}
            >
                Logout
            </Button>
            </Stack>
        </Modal.Footer>
    </Modal>
  );
};

export default LogoutHandleModal;
