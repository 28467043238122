import appConfig from "../helpers/config";
const baseUrlApi = appConfig.apiEndPoint;

const EndPoint = {
  //Auth Api's
  USER_VERIFICATION: `${baseUrlApi}/users/verify-email`,
  GET_USER_PROFILE: `/auth/profile`,
  LOGOUT_API: `/auth/logout`,

  //Roles & Permission Api's
  ROLES_LIST_API: `/roles/role-list`,
  ROLES_ADD_API: `/roles/role`,
  ROLES_MASTER_API: `/roles/master-role`,
  ROLES_DELETE_API: `/roles/role`,
  ROLES_DETAIL_API: `/roles/role`,

  //User Management API's

  LIST_USER_MANAGEMENT_API: `/users/list`,
  ADD_USER_ROLES: `/roles/list`,
  ADD_USER_MANAGEMENT: `/users/add`,
  STATUS_USER: `/users/status-update`,
  USER_DELETE: `/users/delete`,
  USER_DETAILS_BY_ID: `/users/detail`,
  UPDATE_USER_DETAILS: `/users/update`,
  DESIGNATION_LIST: `/designation-manager/records`,
  COMMON_USER_LIST: `/users/common-list`,
  RESTORE_USER: '/users/restore',

  //Knowledge Base Stage's API's
  KB_STAGE_LIST_API: `/knowledge-base-stages/stage-list`,
  KB_STAGE_ADD_API: `/knowledge-base-stages/add`,
  KB_STAGE_DELETE_API: `/knowledge-base-stages/delete`,
  KB_STAGE_EDIT_API: `/knowledge-base-stages/edit`,
  KB_STAGE_GET_API: `/knowledge-base-stages/get`,
  KB_STAGE_GET_API_COMMON: `/knowledge-base-stages/common-list`,
  STAGES_EXPORT: `/knowledge-base-stages/export`,
  KB_STAGE_RESTORE_API: '/knowledge-base-stages/restore',

  //Designation Manager API's
  DESIGNATION_MANAGER_LIST_API: `/designation-manager/filter-list`,
  DESIGNATION_MANAGER_ADD_API: `/designation-manager/add`,
  DESIGNATION_MANAGER_DELETE_API: `/designation-manager/delete`,
  DESIGNATION_MANAGER_EDIT_API: `/designation-manager/edit`,
  DESIGNATION_MANAGER_GET_API: `/designation-manager/get`,
  DESIGNATION_MANAGER_EXPORT: `/designation-manager/export`,

  //Teams Module API's
  TEAMS_LIST_API: `/teams/list?`,
  TEAMS_DROPDOWNS_DATA_API: `/teams/users-list`,
  TEAMS_ADD_API: `/teams/add`,
  TEAMS_UPDATE_API: `/teams/update`,
  TEAMS_EXPORT_API: `/teams/export`,
  TEAMS_DELETE_API: `/teams/delete`,

  //Knowledge Base doc check list's API's
  KB_DOC_CHK_ITEMS_LIST_API: "checklist-item/list",
  KB_DOC_CHK_ITEMS_ADD_API: "/checklist-item/add",
  KB_DOC_CHK_ITEMS_GET_API: "checklist-item/item",
  KB_DOC_CHK_ITEMS_EDIT_API: "checklist-item/edit",
  KB_DOC_CHK_ITEMS_DELETE_API: "checklist-item/delete",
  KB_DOC_CHK_ITEMS_ALL_RECORD_API: "/checklist-item/records",

  //Knowledge Base doc check list's API's
  KB_DOCUMENT_CHK_ADD_API: "/document-checklist/add",
  KB_DOCUMENT_CHK_LIST_API: "/document-checklist/filter-list",
  KB_DOCUMENT_CHK_GET_API: "document-checklist/get",
  KB_DOCUMENT_CHK_EDIT_API: "document-checklist/edit",
  KB_DOCUMENT_CHK_DELETE_API: "document-checklist/delete",
  KB_DOCUMENT_CHK_GETALL_API: "document-checklist/records",
  KB_DOCUMENT_CHK_RESTORE_API: "document-checklist/restore",

  KB_DOCUMENT_TYPE_LIST_API: "/document-type/filter-list",
  KB_DOCUMENT_TYPE_ADD_API: "/document-type/add",
  KB_DOCUMENT_TYPE_DELETE_API: "/document-type/delete",
  KB_DOCUMENT_TYPE_GET_API: "/document-type/get",
  KB_DOCUMENT_TYPE_EDIT_API: "document-type/edit",
  KB_DOCUMENT_TYPE_RESTORE_API: "/document-type/restore",
  //Knowledge Base Step's API's

  STEPS_LIST_API: `/knowledge-base-steps/steps-list`,
  STEPS_ADD_API: `/knowledge-base-steps/add`,
  STEPS_DELETE_API: `/knowledge-base-steps/delete`,
  STEPS_UPDATE_API: `/knowledge-base-steps/update`,
  STEPS_GET_API_COMMON: `/knowledge-base-steps/common-list`,
  STEPS_EXPORT: `/knowledge-base-steps/export`,
  STEPS_RESTORE_API: '/knowledge-base-steps/restore',  

  //Knowledge Base doc type's API's
  KB_DOC_TYPE_LIST_API: "/document-type/list",

  //Client Module API's
  CLIENT_LIST_API: `/clients/list`,
  CLIENT_ADD_API: `/clients/add`,
  CLIENT_CONTACT_INFO_ADD: `/clients/add-contact-info`,
  CLIENT_GET_DETAIL_API: `/clients/detail`,
  CLIENT_UPDATE_API: `/clients/update`,
  CLIENT_CONTACT_INFO_UPDATE_API: `/clients/update-contact-info`,
  CLIENT_VIEW_API: `clients/view`,
  CLIENT_ADD_RELATIVES_API: `/clients/add-relatives`,
  CLIENT_EDIT_RELATIVES_API: `/clients/edit-relatives`,
  CLIENT_RELATIVES_LIST_API: `/clients/relatives-list`,
  CLIENT_RELATIVES_LIST_DELETE_API: `/clients/relatives`,
  CLIENT_RELATIVES_BY_ID_API: `/clients/relatives`,
  CLIENT_EXPORT: `/clients/export`,
  CLIENT_MULTIPLE_DELETE: `/clients/delete-a-client-and-all-data`,
  CLIENT_SINGLE_DELETE: `/clients/delete-a-single-client-and-all-data`,


  //questionnaires API

  QUESTION_LIST: `/questionnaires/question-list`,
  QUESTIONAIRRE_ADD: `/questionnaires/add`,
  QUESTIONAIRRE_UPDATE: `/questionnaires/update`,
  QUESTIONAIRRE_LIST: `/questionnaires/list`,
  QUESTIONAIRRE_BY_ID: `/questionnaires/detail`,
  QUESTIONAIRRE_DELETE: `/questionnaires/delete`,
  QUESTIONAIRRE_EXPORT: `/questionnaires/export`,
  QUESTIONAIRRE_COMMON_LIST: `/questionnaires/common-list`,

  KB_FORMS_ADD_API: "knowledge-base-forms/add",
  KB_FORMS_LIST_API: "knowledge-base-forms/list",
  KB_FORMS_GET_OBJ_API: "knowledge-base-forms/get",
  KB_FORMS_EDIT_API: "knowledge-base-forms/edit",
  KB_FORMS_DELETE_API: "knowledge-base-forms/delete",
  KB_FORMS_GET_ALL_COMMON: "knowledge-base-forms/form-list",
  GET_ALL_FORM_MAPPING: "knowledge-base-forms/form-questionnaire-mapping-list",
  ADD_QUESTIONNAIRE_MAPPING : "/knowledge-base-forms/form-questionnaire-mapping-create",
  ADD_SIGN_AUTH_MAPPING : "/knowledge-base-forms/form-signingauthority-mapping-create",
  GET_SIGN_AUTH_MAPPING : "/knowledge-base-forms/form-signingauthority-mapping-detail",
  ADD_ORGANIZATION_MAPPING : "/knowledge-base-forms/form-organization-mapping-create",
  GET_ORGANIZATION_MAPPING : "/knowledge-base-forms/form-organization-mapping-detail",
  KB_FORMS_RESTORE_API: '/knowledge-base-forms/restore',
  

  //Questions
  QUESTION_ADD: `/questions/add`,
  QUESTION_BY_ID: `/questions/get`,
  QUESTION_UPDATE: `/questions/edit`,
  QUESTIONS_LIST_API: `/questions/filter-list`,
  QUESTIONS_DELETE_API: `/questions/delete`,
  QUESTIONS_EXPORT: `/questions/export`,
  QUESTIONS_RESTORE_API: "/questions/restore",

  //Knowledge Base Documents API's
  KB_DOCUMENTS_LIST_API: "/documents/filter-list",
  KB_DOCUMENTS_ADD_API: "/documents/add",
  KB_DOCUMENTS_DELETE_API: "/documents/delete",
  KB_DOCUMENTS_GET_API: "/documents/get",
  KB_DOCUMENTS_EDIT_API: "/documents/edit",
  KB_DOCUMENTS_RESTORE_API: "/documents/restore",

  //Case Types
  CASE_TYPE_ADD: `/case-type/add`,
  CASE_TYPE_BY_ID: `/case-type/detail`,
  CASE_TYPE_UPDATE: `/case-type/update`,
  CASE_TYPE_UPDATE_STATUS: `/case-type/status-update`,
  CASE_TYPE_LIST_API: `/case-type/list`,
  CASE_TYPE_DELETE_API: `/case-type/delete`,
  CASE_TYPE_RESTORE_API: `/case-type/restore`,

  //Knowledge Base Email Templates API's
  KB_EMAIL_TEMPLATES_LIST_API: "/knowledge-base-email-template/email-templates-list",
  KB_EMAIL_TEMPLATES_ADD_API: "/knowledge-base-email-template/add",
  KB_EMAIL_TEMPLATES_DELETE_API: "/knowledge-base-email-template/delete",
  KB_EMAIL_TEMPLATES_GET_API: "/knowledge-base-email-template/detail",
  KB_EMAIL_TEMPLATES_EDIT_API: "/knowledge-base-email-template/update",
  KB_EMAIL_TEMPLATES_STAGES_LIST_API: "/knowledge-base-email-template/stages-list",
  KB_EMAIL_TEMPLATES_CASE_TYPE_LIST_API: "/knowledge-base-email-template/case-type-list",
  KB_EMAIL_TEMPLATES_DOCUMENTS_LIST_API: "/knowledge-base-email-template/documents-list",
  KB_EMAIL_TEMPLATES_STATUS_UPDATE_API: "/knowledge-base-email-template/status-update",
  KB_EMAIL_TEMPLATES_KEYWORDS_LIST_API: "/knowledge-base-email-template/keywords-list",
  
  // Case Type Mapping API's
  CASE_TYPE_STAGE_STEPS_UPDATE_API: `/case-type/stages-steps-update`,
  CASE_TYPE_FORMS_UPDATE_API: `/case-type/add-form`,
  CASE_TYPE_DOCUMENT_CHECKLIST_UPDATE_API: `/case-type/add-document-checklist`,
  CASE_TYPE_QUESTIONNAIRE_UPDATE_API: `/case-type/questionnaire-update`,
  FORM_SCRAPPING_LIST : "/knowledge-base-forms/form-scrapping-list",
  ADD_FORM_MAPPING : "/case-type/add-form-mapping",
  ADD_FORM_MAPPING_SIGN_AUTH : "knowledge-base-forms/form-signingauthority-mapping-detail",
  GET_FORM_MAPPING_BY_ID : "/case-type/form-mapping-detail", 
  GET_ALL_FORM_MAPPING_ALL : "/case-type/questionnaire-form-mapping-view", // on view all mapping
  GET_ALL_FORM_MAPPING_DELETE : "/case-type/form-mapping-delete",
  GET_ALL_KB_FORM_MAPPING_DELETE : "/knowledge-base-forms/form-questionnaire-mapping-delete",
  GET_FORM_MAPPING_BY_ID_PREVIEW : "/knowledge-base-forms/form-questionnaire-mapping-preview", // On Preview Screen
  GET_FORM_MAPPING_BY_ID_DETAILS : "/knowledge-base-forms/form-questionnaire-mapping-detail", //On Edit screen
  GET_ALL_FORM_MAPPING_ALL_KB : "/knowledge-base-forms/form-questionnaire-mapping-view", // on view all mapping

  COMPOSE_EMAIL_EMAIL_TEMPLATE_LIST_API: `/knowledge-base-email-template/common-list`,
  COMPOSE_EMAIL_EMAIL_ADD_API: `/client-communication/send-email`,
  COMPOSE_EMAIL_CHOOSE_RECEIPIENTS_API: `/client-communication/choose-receipients`,
  CLIENT_DRAFT_EMAIL_LIST_API: `/client-communication/draft-email-list`,
  CLIENT_DRAFT_EMAIL_LIST_DELETE_API: `/client-communication/delete-email-draft`,
  CLIENT_DRAFT_EMAIL_COMPOSE_API: `/client-communication/get-single-draft-email`,
  CLIENT_DRAFT_EMAIL_SEND_API: `/client-communication/send-drafted-email`,
  CLIENT_DRAFT_EMAIL_UPDATE_API: `client-communication/update-drafted-email`,
  CLIENT_DRAFT_EMAIL_ATTACHMENT_DELETE_API: `client-communication/delete-email-attachment`,
  CLIENT_DRAFT_EMAIL_ATTACHMENT_UPDATE_API: `client-communication/draft-email-attachment`,
  COMPOSE_EMAIL_SAVE_DRAFT_API: `/client-communication/draft-email`,
  COMPOSE_EMAIL_SUBJECT_MESSAGE_PREVIEW_API: `/client-communication/preview-email`,
  COMPOSE_EMAIL_TEAM_MEMBER_ASSIGNED_TO_CASE_API: `/client-communication/list-emails-of-team-members-assigned-to-case`,

  ADD_CLIENT_NOTES : "/clients/notes",
  LIST_CLIENT_NOTES : "/clients/notes-list",
  EXPORT_LIST_CLIENT_NOTES : "/clients/notes-export",

  GET_ALL_USERS_EXCLUDING_PRIMARY_CONTACT:`/client-communication/get-all-users-excluding-primary-contact`,
  UPDATE_PRIMARY_CONTACT:`client-communication/update-primary-contact`,
  
  EMAIL_INBOX_API:`/clients/fetch-inbox`,
  EMAIL_SEND_API:`/clients/fetch-send-mail`,
  EMAIL_STATUS_API:`/clients/read-mail-status`,
  EMAIL_ATTACHMENTS_API:`/client-communication/get-all-client-email-attachments`,
  EMAIL_MIME_API:`/client-communication/get-client-email-mime`,
  EMAIL_REPLY_MIME_API:`/client-communication/get-client-email-mime-reply`,
  SEND_EMAIL_REPLY_MIME_API:`/client-communication/send-email-reply-reply-all-forward`,
  EMAIL_REPLY_REPLY_ALL_API:`/client-communication/send-reply-or-reply-all-email`,
  CLIENT_ALERT_ADD_API:`/clients/alerts`,
  CLIENT_ALERT_LIST_API:`/clients/alerts-list`,
  CLIENT_ALERT_STATUS_UPDATE_API:`/clients/alerts-status-update`,
  CLIENT_ALERT_LIST_EDIT_API:`/clients/alerts`,
  CLIENT_ALERT_LIST_DELETE_API:`/clients/alerts`,
  GET_ALL_CASE_TYPES: '/case-type/common-list',
  GET_ALL_RELATIVES: '/clients/relatives-list',
  GET_FILE_NO_CLIENT_CASE: '/clients/relatives-list',
  GET_COMMON_TEAMS: '/teams/all-record',
  ADD_CLIENT_CASES :'cases/add',
  EDIT_CLIENT_CASES :'cases/edit-case-detail',
  CLIENT_CASES_LIST_API:`/clients/cases`,
  CASES_COMMON_LIST_API:`case-type/common-list`,
  CASE_LIST_API: `/cases/list`,
  DESIGNATION_LIST_FILTERED: `/users/designation-filtered-list`,
  REASSIGN_DESIGNATION: `/cases/reassign-designation`,
  CASES_EXPORT: `/cases/export`,
  CASE_DATA_BY_ID: "/cases/detail",
  ADD_CASE_NOTES : "/cases/notes",
  LIST_CASES_NOTES : "/cases/notes-list",
  EXPORT_LIST_CASES_NOTES : "/cases/notes-export",
  CASE_ADD_FILLING : "/cases/filing/add",
  CASE_EDIT_FILLING : "/cases/filing/update",
  CASE_FILLING_LIST : "/cases/filing-list",
  CASE_FILLING_DELETE : "/cases/filing",
  EMAIL_FORWARD_API:`/client-communication/forward-an-email`,
  CASE_STAGES_STEPS : "/cases/stages-steps",
  DELETE_CASE_STEPS : "/cases/stages-steps/delete-step",
  RENAME_CASE_STEPS :"/cases/stages-steps/rename-step",
  STEP_MARK_COMPLETED : "cases/stages-steps/mark-step-complete",
  MARK_STAGE_NOTAPPLICABLE : "cases/stages-steps/mark-stage-notapplicable",
  REARRANGE_STAGES : "/cases/stages-steps/rearrange-steps",
  MARK_STEP_NOTAPPLICABLE : "/cases/stages-steps/mark-step-notapplicable",
  ADD_NEW_STAGE_STEP:"/cases/stages-steps/add-new-stage-step",
  ADD_NEW_STEPS_CASE_TYPES : "/cases/stages-steps/add-step-from-other-case-type",
  GET_APPLICANT_LIST : "cases/applicant-list",
  CASE_QUESTIONNAIRE : "cases/questionnaire",
  CASE_QUESTIONNAIRE_LIST : "cases/questionnaire-list",
  SEND_QUESTIONNAIRE : "cases/questionnaire-send",
  REVIEW_QUESTIONNAIRE:"cases/questionnaire-review",
  COMPLETED_QUESTIONNAIRE:"cases/questionnaire-complete",
  DELETE_QUESTIONNAIRE:"cases/questionnaire-delete",
  GET_ALL_DERIVATIVE : "/cases/derivatives-list",
  ADD_DERIVATIVE : "/cases/add-derivatives",
  DELETE_DERIVATIVE : "/cases/derivatives",
  QUESTIONNAIRE_VIEW : "/cases/questionnaire-view",
  SUBMIT_QUESTIONNAIRE : "/cases/questionnaire-submit",
  NOTAPPLICABLE_QUESTIONNAIRE : "/cases/mark-questionnaire-section-notapplicable",
  ADD_DOC_CHK_LIST: "/cases/add-doc-chk-list",
  VIEW_DOC_CHK_LIST: "/cases/document-check-list",
  VIEW_PARTICULAR_DOC_CHK_LIST:"/cases/particular-document-check-list",
  GET_ALL_PETITIONERS : "/cases/petitioners-list",
  ADD_PETITIONERS : "/cases/add-petitioners",
  DELETE_PETITIONERS : "/cases/petitioners",
  DOC_CHK_LIST_SEND: "/cases/doc-chk-list-status-sent",
  DOC_CHK_LIST_ADD: "/cases/add-case-checklist-document",
  CASE_NOTIFICATIONS: "/cases/notification-settings",
  CASE_MANAGEMENT_MULTIPLE_DELETE: "/cases/delete-a-case-and-all-data",
  CASE_MANAGEMENT_SINGLE_DELETE: "/cases/delete-a-single-case-and-all-data",

  // CASE DOCUMENTS
  GET_CASE_DOCUMENT: "/cases/get-document-list",
  DOCUMENT_REQUESTED: "cases/doc-status-requested",
  DOCUMENT_RECIEVED: "cases/doc-status-received",
  DOCUMENT_APPROVE: "cases/doc-status-approved",
  DOCUMENT_SIGNED: "cases/doc-status-signed",
  DOCUMENT_RE_REQUESTED: "cases/doc-status-re-requested",
  DOCUMENT_RE_REQUESTED_ALL: "cases/multiple-doc-status-requested",
  DELETE_CASE_DOCUMENT: "/cases/document",
  VIEW_CASE_DOCUMENT: "/cases/document",
  DOCUMENT_VIEW_DATA: 'cases/document-view',
  DOCUMENT_SAMPLE_VIEW_DATA: 'cases/document-view-sample',
  UPLOAD_DOCUMENT:"cases/upload-request-file-in-documents",
  EDIT_UPLOAD_DOCUMENT: "cases/upload-request-file-in-documents-edit",
  MOVE_DOCUMENT_FOLDER: 'cases/move-request-file-in-documents',
  GET_CASE_FORMS: "/case-forms/list",
  DELETE_CASE_FORM: "/case-forms/delete-form",
  FORM_SENT: "/case-forms/case-forms-status-sent",
  FORM_APPROVE: "/case-forms/case-forms-status-approved-by-client",
  FORM_SIGNED: "/case-forms/case-forms-status-signed-by-client",
  FORM_VIEW_DATA: '/case-forms/form-view',  
  GET_ALL_SIGNING_AUTH: '/case-forms/get-signing-authority-user-list',
  SET_SIGNING_AUTH: '/case-forms/update-signing-authority-particular-form',
  GET_ALL_FORM_COMPARE: "/case-forms/get-all-form-compare",
  GET_ALL_DOCUMENT_COMPARE: "/case-forms/get-all-document-compare",
  INCLUDING_FILE_IN_BUNDLE: '/case-document-preview/include-file-in-bundle-of-documents',
  GET_LINK_TO_OPEN_CASE_FOLDER: "/case-document-preview/link-to-open-case-folder",
  // Case Managment Receipts 
  GET_FORMS_RECEIPT_DATA: "/case-forms/get-receipt-all-forms-list",
  ADD_NEW_RECEIPT: "/case-receipts/add",
  GET_RECEIPT_LIST: "/case-receipts/list",
  DELETE_RECEIPT: "/case-receipts",
  DELETE_CHILD_RECEIPT: "/case-receipts/child_receipt",
  PRIMARY_RECEIPT: "/case-receipts/make-default",
  CHANGE_STATUS_RECEIPT: "/case-receipts/status-update",
  GET_OCR_DETAILS_RECEIPT: "/case-receipts/detail",
  OCR_COMPARE_SUBMIT_RECEIPT: "/case-receipts/update",
  EDIT_PROCESS_DETAILS_FOR_SPONSORED_VISA: "/cases/edit-case-process-detail-for-sponsored-visa",
  CASE_BILLING_STATUS: "client-billing/account-list",
  GET_BILLING_LIST: "client-billing/case-overdue-list",
  ADD_NEW_FORM_CASE: "case-forms/check-kb-forms-mapping",
  VISA_PRIORITY_UPDATE: "cases/edit-case-visa-priority-detail",
  //Organization
  GET_SETTING_LIST: "/organization-info/organization",
  EDIT_SETTING_LIST: "/organization-info/edit-organization",
  GET_ALL_DOCS_PREVIEW : "/case-document-preview/all-docs-in-preview-list",
  SAVE_ORDER_DOCUMENT : "/case-document-preview/save-order-of-documents",
  DOWNLOAD_BUNDLE_DOCUEMNT : "/case-document-preview/download-bundle-of-documents",
  //Case Managment Reminder 
  GET_CASE_REMINDER_LIST: "/case-reminder-and-events/case-reminder-list",
  ADD_NEW_REMINDER: "/case-reminder-and-events/add-reminder",
  GET_ALL_USERS_LIST: "/case-reminder-and-events/all-users-list",
  REMINDER_MARK_COMPLETED: "/case-reminder-and-events/reminder-update-status",
  REMINDER_DELETE: `/case-reminder-and-events/delete-case-reminder`,
  REMINDER_VIEW: `/case-reminder-and-events/view-a-case-reminder`,
  REMINDER_EDIT: `/case-reminder-and-events/edit-case-reminder`,
  // Reminder Section 
  REMINDER_LIST_API: `/case-reminder-and-events/my-case-reminder-list`,
  //Case Managment Events 
  GET_CASE_EVENTS_LIST: "/case-reminder-and-events/case-event-list",
  ADD_NEW_EVENTS: "/case-reminder-and-events/add-event",
  EVENTS_MARK_COMPLETED: "/case-reminder-and-events/event-update-status",
  EVENTS_DELETE: `/case-reminder-and-events/delete-case-event`,
  EVENTS_VIEW: `/case-reminder-and-events/view-a-case-event`,
  EVENTS_EDIT: `/case-reminder-and-events/edit-case-event`,
  CASE_FORM_FILL_FORM: `/case-forms/fill-form-with-questionnaire`,
  GET_NOTIFICATION_LIST: "/notification/list",
  GET_NOTIFICATION_MARK_READ_LIST: "/notification/mark-all-as-read",
  GET_LATEST_NOTIFICATION_LIST: "/notification/recent-list",
  NOTIFICATION_DELETE: "/notification",
  // Reorts
  REPORT_LIST_API: `/reports-section/report-listing`, 
  REPORT_SAVE: `/reports-section/save-report`,
  REPORT_UPDATE: `/reports-section/edit-saved-report`,
  SAVED_REPORT_LIST_API: `/reports-section/saved-report-listing`,
  DELETE_REPORT_API: `/reports-section/saved-report`,
  ALL_DESIGNATION_LIST: `/reports-section/get-all-users-with-designation`,
  DOWNLOAD_SINGLE_REPROT_API: `/reports-section/report-view`,
  GET_EDIT_DATA_SAVED_REPORTS_API: `/reports-section/report-get`,
  REPORTS_EXPORT: `/reports-section/export-csv-unsaved`,
  //Calendar
  CALENDAR_API: `/calendar/combine-calendar-api`,
  // Global Search 
  GLOBAL_SEARCH_ALL: `/global-search/search-records`,
  GLOBAL_SEARCH_CLIENT: `/global-search/search-global-clients`,
  GLOBAL_SEARCH_CASES: `/global-search/search-global-cases`,
  GLOBAL_SEARCH_RELATIVES: `/global-search/search-global-relatives`,
  // Dashboard
  DASHBOARD_COMBINED_API: `/admin-dashboard/dashboard-combine-list`,
  DASHBOARD_RECENTLY_VISITED_API: `/admin-dashboard/dashboard-recent-visited-list`,
  DASHBOARD_TO_DO_API: `/admin-dashboard/dashboard-to-do-list`,
  DASHBOARD_CASE_PROGRESS_API: `/admin-dashboard/dashboard-case-progress-list`,
  DASHBOARD_QUICK_GLANCE_API: `/admin-dashboard/quick-glance-list`,
  DASHBOARD_STAGE_CASE_PROGRESS_API: `/admin-dashboard/case-stage-wise`,
  DASHBOARD_PERFORMANCE_API: `/admin-dashboard/case-performance-open-filled`,
  DASHBOARD_RFE_CASE_STATUS_API: `/admin-dashboard/rfe-case-open-filled-withdrawn`,
};

export default EndPoint;
