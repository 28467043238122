
import React from 'react';
import {useSelector} from "react-redux";

// "User Management", "Knowledge Base"
const CheckSideMenuPermissionToPerformAction = () => {
    const authState = useSelector((state:any) => state.authSlice);
    const keyNameArr = [
        {keyName: "Stages", role_name: "Knowledge Base Manage Stages"},
        {keyName: "Steps", role_name: "Knowledge Base Manage Steps"},
        {keyName: "Doc Checklist Items", role_name: "Knowledge Base Manage Document Checklist Items"},
        {keyName: "Document Checklist", role_name: "Knowledge Base Manage Document Checklist"},
        {keyName: "Document Types", role_name: "Knowledge Base Manage Document Type"},
        {keyName: "Documents", role_name: "Knowledge Base Manage Document"},
        {keyName: "Forms", role_name:"Knowledge Base Manage Forms"},
        {keyName: "Email Templates", role_name: "Knowledge Base Manage Email Templates"},
        {keyName: "Questions", role_name: "Knowledge Base Manage Questions"},
        {keyName: "Questionnaires", role_name: "Knowledge Base Manage Questionnaires"},
        {keyName: "Manage Teams", role_name: "Manage Team"},
        {keyName: "Users List", role_name: "Manage User"},
        {keyName: "Manage Designations", role_name:"Manage Designations"},
        {keyName: "Settings", role_name:"Settings"},
        {keyName: "Roles & Permissions", role_name:"Manage Roles and Permissions"},
        {keyName: "Generate New Report", role_name:"Generate New Report"},
        {keyName: "Saved Reports", role_name:"Saved Reports"},
    ];
    const keyNameKnowledgeBaseArr = [
        {keyName: "Stages", role_name: "Knowledge Base Manage Stages"},
        {keyName: "Steps", role_name: "Knowledge Base Manage Steps"},
        {keyName: "Doc Checklist Items", role_name: "Knowledge Base Manage Document Checklist Items"},
        {keyName: "Document Checklist", role_name: "Knowledge Base Manage Document Checklist"},
        {keyName: "Document Types", role_name: "Knowledge Base Manage Document Type"},
        {keyName: "Documents", role_name: "Knowledge Base Manage Document"},
        {keyName: "Forms", role_name:"Knowledge Base Manage Forms"},
        {keyName: "Email Templates", role_name: "Knowledge Base Manage Email Templates"},
        {keyName: "Questions", role_name: "Knowledge Base Manage Questions"},
        {keyName: "Questionnaires", role_name: "Knowledge Base Manage Questionnaires"},
    ];
    const keyNameUserManagementArr = [
        {keyName: "Manage Teams", role_name: "Manage Team"},
        {keyName: "Users List", role_name: "Manage User"},
        {keyName: "Manage Designations", role_name:"Manage Designations"},
        {keyName: "Roles & Permissions", role_name:"Manage Roles and Permissions"}
    ];
    const keyNameReportsArr = [
        {keyName: "Generate New Report", role_name:"Generate New Report"},
        {keyName: "Saved Reports", role_name:"Saved Reports"},
    ];
    const hasPermission = (props:any ) =>{
        const keyName = keyNameArr.find(ele=> ele.keyName == props?.menuName);
        const heading = keyName?.role_name ? keyName?.role_name :"";
        const masterPermission = authState?.masterPermissionObj ? authState?.masterPermissionObj: [];
        const rolesPermissionUser = authState?.resObjRolesPermissionUser ? authState?.resObjRolesPermissionUser : {};
        let masterPermissionObj = masterPermission?.find((ele:any)=> ele.heading == heading);
        const parentId = masterPermissionObj?.heading_id;
        // role_name
        const rightsOfUser = rolesPermissionUser?.rights;
        let roleUserPermissionObj = rightsOfUser?.find((ele:any)=> ele.role_name == "List" && ele.parent_id == parentId);
    
        // Bypass all the conditions for the super admin user.
        if(rolesPermissionUser?.id === 1 || rolesPermissionUser?.id === '1'){
            return true;
        }    
        // Bypass all the conditions for the super admin user.
        if(rolesPermissionUser?.id === 1 || rolesPermissionUser?.id === '1'){
            return true;
        }        
        if(!roleUserPermissionObj) {
            return false;
        }else{
            return true;
        }
    }
    const hasPermissionParent = (props:any ) =>{
        const masterPermission = authState?.masterPermissionObj ? authState?.masterPermissionObj: [];
        const rolesPermissionUser = authState?.resObjRolesPermissionUser ? authState?.resObjRolesPermissionUser : {};
        // Bypass all the conditions for the super admin user.
        if(rolesPermissionUser?.id === 1 || rolesPermissionUser?.id === '1'){
            return true;
        }
        let listPermissionOnChild = false; 

        if(props.menuName == "Client Management"){
            let masterPermissionObj = masterPermission.find((ele:any)=> ele.heading == "Client Management Listing");
            const parentId = masterPermissionObj?.heading_id;
            const rightsOfUser = rolesPermissionUser?.rights;
            let roleUserPermissionObj = rightsOfUser?.find((ele:any)=> ele.role_name == "List" && ele.parent_id == parentId);
            if(roleUserPermissionObj) {
                listPermissionOnChild = true;
            }
            return listPermissionOnChild;
        }
        
        if(props.menuName == "Case Management"){
            let masterPermissionObj = masterPermission.find((ele:any)=> ele.heading == "Case Management Listing");
            const parentId = masterPermissionObj?.heading_id;
            const rightsOfUser = rolesPermissionUser?.rights;
            let roleUserPermissionObj = rightsOfUser?.find((ele:any)=> ele.role_name == "List" && ele.parent_id == parentId);
            if(roleUserPermissionObj) {
                listPermissionOnChild = true;
            }
            return listPermissionOnChild;
        }

        if(props.menuName == "Manage Case Types"){
            let masterPermissionObj = masterPermission.find((ele:any)=> ele.heading == "Case Type Management Manage Case Type");
            const parentId = masterPermissionObj?.heading_id;
            const rightsOfUser = rolesPermissionUser?.rights;
            let roleUserPermissionObj = rightsOfUser?.find((ele:any)=> ele.role_name == "List" && ele.parent_id == parentId);
            if(roleUserPermissionObj) {
                listPermissionOnChild = true;
            }
            return listPermissionOnChild;
        }
        
        // if(props.menuName == "Settings"){
        //     if(rolesPermissionUser?.id !== 1 || rolesPermissionUser?.id !== '1'){
        //         listPermissionOnChild = false
        //     }
        //     return listPermissionOnChild;
        // }
        

        if(props.menuName == "Manage Designations"){
            let masterPermissionObj = masterPermission.find((ele:any)=> ele.heading == "Manage Designations");
            const parentId = masterPermissionObj?.heading_id;
            const rightsOfUser = rolesPermissionUser?.rights;
            let roleUserPermissionObj = rightsOfUser?.find((ele:any)=> ele.role_name == "List" && ele.parent_id == parentId);
            if(roleUserPermissionObj) {
                listPermissionOnChild = true;
            }
            return listPermissionOnChild;
        }

        if(props.menuName == "Settings"){
            let masterPermissionObj = masterPermission.find((ele:any)=> ele.heading == "Settings");
            const parentId = masterPermissionObj?.heading_id;
            const rightsOfUser = rolesPermissionUser?.rights;
            let roleUserPermissionObj = rightsOfUser?.find((ele:any)=> ele.role_name == "View" && ele.parent_id == parentId);
            if(roleUserPermissionObj) {
                listPermissionOnChild = true;
            }
            return listPermissionOnChild;
        }

        if(props.menuName == "Generate New Report"){
            let masterPermissionObj = masterPermission.find((ele:any)=> ele.heading == "Generate New Report");
            const parentId = masterPermissionObj?.heading_id;
            const rightsOfUser = rolesPermissionUser?.rights;
            let roleUserPermissionObj = rightsOfUser?.find((ele:any)=> ele.role_name == "List" && ele.parent_id == parentId);
            if(roleUserPermissionObj) {
                listPermissionOnChild = true;
            }
            return listPermissionOnChild;
        }

        if(props.menuName == "Saved Reports"){
            let masterPermissionObj = masterPermission.find((ele:any)=> ele.heading == "Saved Reports");
            const parentId = masterPermissionObj?.heading_id;
            const rightsOfUser = rolesPermissionUser?.rights;
            let roleUserPermissionObj = rightsOfUser?.find((ele:any)=> ele.role_name == "List" && ele.parent_id == parentId);
            if(roleUserPermissionObj) {
                listPermissionOnChild = true;
            }
            return listPermissionOnChild;
        }
        
        if(props.menuName == "Knowledge Base"){
            keyNameKnowledgeBaseArr.forEach(elementArr => {
                let masterPermissionObj = masterPermission.find((ele:any)=> ele.heading == elementArr.role_name);
                const parentId = masterPermissionObj?.heading_id;
                const rightsOfUser = rolesPermissionUser?.rights;
                let roleUserPermissionObj = rightsOfUser?.find((ele:any)=> ele.role_name == "List" && ele.parent_id == parentId);
                if(roleUserPermissionObj) {
                    listPermissionOnChild = true;
                }
            });
            return listPermissionOnChild; 
            // role_name
        }else if(props.menuName == "User Management"){
            keyNameUserManagementArr.forEach(elementArr => {
                let masterPermissionObj = masterPermission.find((ele:any)=> ele.heading == elementArr.role_name);
                const parentId = masterPermissionObj?.heading_id;
                const rightsOfUser = rolesPermissionUser?.rights;
                let roleUserPermissionObj = rightsOfUser?.find((ele:any)=> ele.role_name == "List" && ele.parent_id == parentId);
                if(roleUserPermissionObj) {
                    listPermissionOnChild = true;
                }
            });
            return listPermissionOnChild; 
        }else if(props.menuName == "Reports"){
            keyNameReportsArr.forEach(elementArr => {
                let masterPermissionObj = masterPermission.find((ele:any)=> ele.heading == elementArr.role_name);
                const parentId = masterPermissionObj?.heading_id;
                const rightsOfUser = rolesPermissionUser?.rights;
                let roleUserPermissionObj = rightsOfUser?.find((ele:any)=> ele.role_name == "List" && ele.parent_id == parentId);
                if(roleUserPermissionObj) {
                    listPermissionOnChild = true;
                }
            });
            return listPermissionOnChild; 
        }else{
            return true;    
        }
    }
    return { hasPermission, hasPermissionParent };
};

export default CheckSideMenuPermissionToPerformAction;

